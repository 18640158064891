import React from "react";

// reactstrap components
import { Card, CardBody, CardHeader, Row, Button, Modal } from "reactstrap";
import { Link, Redirect } from "react-router-dom";

import keys from "configs/constants";

import { TooltipButton } from "../components/Tooltip";

import Reports from "../views/Reports";

import { withTranslation } from "react-i18next";

import "./plant.css";
import Loader from "./Loader";
import utils from "../utils";

class Plant extends React.Component {
  state = {
    showModal: false,
    isReport: true,
  };

  getTotalConnectedControls() {
    var total = 0;
    var unknown = 0;
    if (this.props.stillLoading) return [-1, -1];

    for (const gw of Object.values(this.props.gatewayList)) {
      if (gw.site.siteGuid === this.props.siteGuid) {
        total += gw.devices.length;
        for (const d of gw.devices) {
          if (utils.isModelUnknown(d.model)) unknown++;
        }
      }
    }
    return [total, unknown];
  }

  toggleReportModal = (isReport = true) => {
    this.setState({
      showModal: !this.state.showModal,
      isReport: isReport,
    });
  };

  renderMapButton = () => {
    return (
      <>
        <Button
          id={`map${this.props.siteGuid}`}
          className="btn-icon d-md-none"
          type="button"
          color="info"
          size="sm"
        >
          <span className="mr-0 btn-inner--icon">{keys.ICON_SITE_MAP}</span>
        </Button>
        <Button
          id={`map${this.props.siteGuid}`}
          className="btn-icon d-none d-md-block"
          type="button"
          color="info"
        >
          <span className="mr-0 btn-inner--icon">{keys.ICON_SITE_MAP}</span>
        </Button>
        <TooltipButton
          target={`map${this.props.siteGuid}`}
          text={this.props.t("plant.map")}
        />
      </>
    );
  };

  renderControllerButton = (controllerButtonDisabled) => {
    return (
      <>
        <Button
          id={`control${this.props.siteGuid}`}
          className="btn-icon d-md-none"
          color={`${!controllerButtonDisabled ? "info" : "transparent"} `}
          type="button"
          size="sm"
          disabled={controllerButtonDisabled}
        >
          <span className="mr-0 btn-inner--icon plant-controller-icon">
            {keys.ICON_SITE_CONTROLLER}
          </span>
        </Button>
        <Button
          id={`control${this.props.siteGuid}`}
          className="btn-icon d-none d-md-block"
          color={`${!controllerButtonDisabled ? "info" : "transparent"} `}
          type="button"
          disabled={controllerButtonDisabled}
        >
          <span className="mr-0 btn-inner--icon plant-controller-icon">
            {keys.ICON_SITE_CONTROLLER}
          </span>
        </Button>
        <TooltipButton
          target={`control${this.props.siteGuid}`}
          text={this.props.t("plant.controllers")}
        />
      </>
    );
  };

  renderSiteSettingsButton = () => {
    return (
      <>
        <Button
          id={`settings${this.props.siteGuid}`}
          className="btn-icon d-md-none"
          type="button"
          color="info"
          size="sm"
        >
          <span className="mr-0 btn-inner--icon">{keys.ICON_SETTINGS}</span>
        </Button>
        <Button
          id={`settings${this.props.siteGuid}`}
          className="btn-icon d-none d-md-block"
          type="button"
          color="info"
        >
          <span className="mr-0 btn-inner--icon">{keys.ICON_SETTINGS}</span>
        </Button>
        <TooltipButton
          target={`settings${this.props.siteGuid}`}
          text={this.props.t("plant.settings")}
        />
      </>
    );
  };

  render() {
    const siteInfo = {
      siteGuid: this.props.siteGuid,
      name: this.props.name,
      city: this.props.city,
      country: this.props.country,
      connectivitytype: this.props.connectivitytype,
      latitude: this.props.latitude,
      longitude: this.props.longitude,
    };

    let [totalConnectedControls, totalConnectedUnknownControls] =
      this.getTotalConnectedControls();

    const gatewaysList = Object.values(this.props.gatewayList);

    const gatewaysOffline = gatewaysList.filter((gw) => gw.status !== "ON");

    const deviceByGw = gatewaysList.reduce((acc, gw) => {
      acc[gw.name] = gw.devices;
      return acc;
    }, {});

    if (this.props.autoRedirect) {
      return (
        <Redirect
          to={{
            pathname: "/std/dvcs",
            site: siteInfo,
          }}
        />
      );
    }

    const controllerButtonDisabled =
      this.props.location.pathname.includes("dvcs") ||
      totalConnectedControls < 1;

    return (
      <>
        <Card
          className={
            (this.props.fullHeight
              ? "card-profile shadow h-100"
              : "card-profile shadow") +
            (this.props.alertStatus === "alarm" ? " border border-danger" : "")
          }
        >
          <CardHeader className="text-center pt-2 pb-2 pb-xs-4 h-50">
            <div className="d-block d-flex flex-column justify-content-between text-center h-100">
              <div className="h3">
                {siteInfo.name}
                {this.props.alertStatus === "alarm" ? (
                  <span className="icon icon-shape icon-sm bg-danger text-white rounded-circle shadow ml-2">
                    {keys.ICON_CONTROLLER_ALARM}
                  </span>
                ) : null}
              </div>

              <div className="h5 font-weight-300 text-truncate">
                {siteInfo.city}, {siteInfo.country}
              </div>
              <div className="d-flex align-items-end justify-content-around">
                <Link
                  to={{
                    pathname: "/std/index",
                    site: siteInfo,
                  }}
                >
                  {this.renderMapButton()}
                </Link>
                {controllerButtonDisabled ? (
                  <div>
                    {this.renderControllerButton(controllerButtonDisabled)}
                  </div>
                ) : (
                  <Link
                    to={{
                      pathname: "/std/dvcs",
                      site: siteInfo,
                    }}
                    disabled={controllerButtonDisabled}
                  >
                    {this.renderControllerButton(controllerButtonDisabled)}
                  </Link>
                )}
                <Link
                  to={{
                    pathname: "/std/sstgs",
                    site: siteInfo,
                  }}
                >
                  {this.renderSiteSettingsButton()}
                </Link>
              </div>
            </div>

            {gatewaysOffline.filter((gw) => gw.status === "OFF").length > 0 ? (
              <div className="text-warning position-absolute fixed-top plant-head text-right m-2 mr-3 h2">
                <span id={`warning${this.props.siteGuid}`}>
                  {keys.ICON_WARNING}
                </span>
                <TooltipButton
                  target={`warning${this.props.siteGuid}`}
                  text={
                    <div>
                      <h5>
                        {gatewaysOffline.filter((gw) => gw.status === "ON")
                          .length +
                          ` gateway${
                            gatewaysOffline.filter((gw) => gw.status === "ON")
                              .length !== 1
                              ? "s"
                              : ""
                          } ${this.props.t("generic.of")} ` +
                          gatewaysOffline.length +
                          ` ${
                            gatewaysOffline.filter((gw) => gw.status === "ON")
                              .length !== 1
                              ? this.props.t("generic.are")
                              : this.props.t("generic.is")
                          }` +
                          " ONLINE"}
                      </h5>
                      {gatewaysOffline.map((gw, i) => {
                        return gw.status === "OFF" ? (
                          <div key={i}>
                            {gw.name +
                              " " +
                              this.props.t("generic.is") +
                              " OFFLINE"}
                          </div>
                        ) : null;
                      })}
                    </div>
                  }
                />
              </div>
            ) : null}
          </CardHeader>
          <CardBody className="shadow pt-0 pt-md-0">
            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center">
                  <div>
                    <span className="heading">
                      {totalConnectedControls < 0 ? (
                        <>
                          <Loader
                            custom
                            size={50}
                            className="position-absolute ml-3"
                          />
                          <span className="text-white">-</span>
                        </>
                      ) : totalConnectedControls > 0 ? (
                        totalConnectedControls
                      ) : (
                        "-"
                      )}
                    </span>
                    <span
                      className="description text-truncate"
                      style={{ whiteSpace: "normal" }}
                    >
                      {`${this.props.t("plant.controllers")}`}{" "}
                      {totalConnectedUnknownControls > 0 &&
                        `(${totalConnectedUnknownControls} ${this.props.t(
                          "generic.unknowns"
                        )})`}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <span className="col">
                    <Button
                      id={`report${this.props.siteGuid}`}
                      size="sm"
                      onClick={() => this.toggleReportModal(true)}
                      className="btn-block"
                      disabled={totalConnectedControls <= 0}
                    >
                      <span className="mr-1">{keys.ICON_REPORTS}</span>
                      <span>{this.props.t("plant.report")}</span>
                    </Button>
                    <TooltipButton
                      target={`report${this.props.siteGuid}`}
                      text={this.props.t("plant.exportFoodQualityReport")}
                    />
                  </span>

                  <span className="col">
                    <Button
                      id={`export${this.props.siteGuid}`}
                      size="sm"
                      onClick={() => this.toggleReportModal(false)}
                      className="btn-block"
                      disabled={totalConnectedControls <= 0}
                    >
                      <span className="mr-1">{keys.ICON_REPORTS}</span>
                      <span>{this.props.t("plant.data")}</span>
                    </Button>
                    <TooltipButton
                      target={`export${this.props.siteGuid}`}
                      text={this.props.t("plant.exportDeviceMeasures")}
                    />
                  </span>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
        <Modal
          centered
          isOpen={this.state.showModal}
          toggle={() => this.toggleReportModal(this.state.isReport)}
        >
          <Reports
            site={siteInfo}
            toggle={() => this.toggleReportModal(this.state.isReport)}
            isReport={this.state.isReport}
            deviceByGw={deviceByGw}
            gateways={gatewaysList}
            t={this.props.t}
          />
        </Modal>
      </>
    );
  }
}

export default withTranslation("common")(Plant);
