import { store } from "./index";
import api from "./services/backendService";
import { store as reduxStore } from "./index";
import { t } from "i18next";
import keys from "./configs/constants";
import { cloneElement } from "react";

const MSG_TYPE_LOG = 0;
const MSG_TYPE_WARN = 1;
const MSG_TYPE_ERR = 2;

const isChrome = () => {
  return window.chrome &&
    window.navigator.vendor === "Google Inc." &&
    !(typeof window.opr !== "undefined") &&
    !(window.navigator.userAgent.indexOf("Edge") !== -1)
    ? true
    : false;
};

const debug = (msg, msgType = 0) => {
  if (process.env.NODE_ENV !== "production") {
    var stack = new Error().stack;
    // N.B. stack === "Error\n  at Hello ...\n  at main ... \n...."
    var m = stack.match(/.*?Hello.*?\n(.*?)\n/);
    var caller_name = "[noname] ";
    if (m) {
      caller_name = "[" + m[1] + "] ";
    }
    switch (msgType) {
      case MSG_TYPE_WARN:
        console.warn(caller_name + msg);
        break;
      case MSG_TYPE_ERR:
        console.error(caller_name + msg);
        break;
      case MSG_TYPE_LOG:
      default:
        console.log(caller_name + msg);
        break;
    }
  }
};

const isDevEnvironment = () => {
  return process.env.NODE_ENV !== "production";
};

const isUserAdmin = () => {
  return reduxStore.getState().user.role === "Admin";
};

const int2hex = (number, padding = 4) => {
  let num = number;
  let string;

  if (num < 0) {
    num = 0xffffffff + num + 1;
  }

  string = num.toString(16).toUpperCase();

  if (string.length < padding) {
    string = string.padStart(padding, "0").toUpperCase();
  } else if (string.length > padding) {
    string = string.substring(string.length - padding);
  }

  return string;
};

const str2hex = function (str, padding = 4) {
  if (str.toUpperCase().includes("0X")) {
    return parseInt(str, 16).toString(16).padStart(padding, 0).toUpperCase();
  } else
    return parseInt(str, 10).toString(16).padStart(padding, 0).toUpperCase();
};

const getGuidsInString = function (str) {
  const regex =
    /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/g;
  return str.match(regex);
};

/**
 *
 * @param {*} date
 * @param {*} utc
 */
const formatDate = (date) => {
  return date.toISOString().split(".")[0];
};

const formatDay = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

/**
 *
 * @param {*} date1
 * @param {*} offset
 * @returns
 */
const setOffsetDate = (date1 = new Date(), offset = 60 * 24) => {
  let date2 = new Date(date1);
  return new Date(date2.setMinutes(date1.getMinutes() - offset));
};

const NONE = 0;
const BEFORE = 1;
const AFTER = 2;
const INSIDE = 3;
const APEX = 4;
const NO_ZERO = 5;

const formatTime = (sec, unit = NONE, addZero = true) => {
  let minutes = secondsToTime(sec)[0];
  let seconds = secondsToTime(sec)[1];

  if (addZero) {
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
  }

  switch (unit) {
    case BEFORE:
      return "Min: " + minutes + " Sec: " + seconds;
    case AFTER:
      return minutes + ":" + seconds + " (min:sec)";
    case INSIDE:
      return minutes + " min " + seconds + " sec";
    case APEX:
      return minutes + "'" + seconds + "''";
    case NO_ZERO:
      return (
        (minutes > 0 ? minutes + " min" + (seconds > 0 ? " and " : "") : "") +
        (seconds > 0 ? seconds + " sec" : "")
      );
    case NONE:
    default:
      return minutes + ":" + seconds;
  }
};

/**
 * Converts a total number of seconds into a time string in the format of "HH:MM:SS".
 *
 * @param {number} totalSeconds - The total number of seconds to be converted.
 * @return {string} The time string in the format of "HH:MM:SS".
 *
 * @example
 * // returns "01:05:30"
 * secondsToTimeString(3930);
 */
const secondsToTimeString = (totalSeconds) => {
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

/**
 * Return an array with minutes and seconds
 * @param {*} sec
 * @returns
 */
const secondsToTime = (sec) => {
  let minutes = Math.floor(sec / 60);
  let seconds = sec % 60;
  return [minutes, seconds];
};

const isLeap = (year) => {
  if (
    year > 1584 &&
    (year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0))
  ) {
    return true;
  } else {
    return false;
  }
};

const getAge = (date1, date2, langKey = "license", split = false) => {
  //convert to UTC
  let date1_UTC = new Date(
    Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate())
  );

  let date2_UTC = new Date(
    Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate())
  );

  if (isLeap(date1_UTC.getFullYear()) || isLeap(date2_UTC.getFullYear())) {
    date2_UTC.setDate(date2_UTC.getDate() + 1);
  }

  let yText, mText, dText;

  //--------------------------------------------------------------
  let days = date2_UTC.getDate() - date1_UTC.getDate();
  if (days < 0) {
    date2_UTC.setMonth(date2_UTC.getMonth() - 1);
    days += daysInMonth(date2_UTC);
  }
  //--------------------------------------------------------------
  let months = date2_UTC.getMonth() - date1_UTC.getMonth();
  if (months < 0) {
    date2_UTC.setFullYear(date2_UTC.getFullYear() - 1);
    months += 12;
  }
  //--------------------------------------------------------------
  let years = date2_UTC.getFullYear() - date1_UTC.getFullYear();

  yText =
    years === 0
      ? null
      : years.toFixed(0) +
        " " +
        t("common:" + langKey + ".year" + (years !== 1 ? "s" : ""));
  mText =
    months === 0
      ? null
      : months.toFixed(0) +
        " " +
        t("common:" + langKey + ".month" + (months !== 1 ? "s" : ""));
  dText =
    days === 0
      ? null
      : days.toFixed(0) +
        " " +
        t("common:" + langKey + ".day" + (days !== 1 ? "s" : ""));

  if (split) {
    return [yText, mText, dText].filter((x) => x !== null);
  }

  let duration =
    (yText || "") +
    (mText
      ? yText && dText
        ? ", " + mText
        : yText && !dText
        ? " " + t("common:generic.and") + " " + mText
        : mText
      : "") +
    (dText
      ? yText || mText
        ? " " + t("common:generic.and") + " " + dText
        : dText
      : "");

  return duration;
};

const daysInMonth = (date2_UTC) => {
  let monthStart = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth(), 1);
  let monthEnd = new Date(date2_UTC.getFullYear(), date2_UTC.getMonth() + 1, 1);
  let monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
  return monthLength;
};

const convertCamelCase = (str) => {
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, (firstChar) => {
        return firstChar.toUpperCase();
      })
  );
};

const getLangText = (id) => {
  let pointName = t("common:points." + id);

  if (pointName === "" || pointName.includes("points.")) {
    pointName = convertCamelCase(id);
  }

  return pointName;
};

const pointValue = (val, type, vtype) => {
  let { temp_unit, press_unit } = reduxStore.getState().user;

  if (val === null || val === undefined) return "NA";

  if (val === 3276.7 || val === 32767) return "ERROR";
  if ((val >= 3276.8 || val <= -3276.8) && type !== "bit") return "NO PROBE";

  let v2show = val;

  switch (vtype) {
    case "temperature":
      switch (temp_unit) {
        case "Fahrenheit":
          v2show = CToF(v2show);
          break;
        default:
          v2show = v2show.toFixed(1);
          break;
      }
      break;
    case "pressure":
      switch (press_unit) {
        case "PSI":
          v2show = barToPsi(v2show);
          break;
        default:
          v2show = v2show.toFixed(1);
          break;
      }
      break;
    default:
      break;
  }

  if (type === "bit") {
    if (val === 1) v2show = "ON";
    else if (val === 0) v2show = "OFF";
    else v2show = "ERROR";
  }
  return v2show;
};

const getUnitByType = (val, type = "") => {
  let { temp_unit, press_unit } = reduxStore.getState().user;
  if (
    val === "NA" ||
    val === "ERROR" ||
    val === "NO PROBE" ||
    val === "BAD LINK"
  )
    return "";
  switch (type) {
    case "temperature":
      switch (temp_unit) {
        case "Fahrenheit":
          return " °F";
        case "Celsius":
        default:
          return " °C";
      }
    case "percentage":
      return "%";
    case "pressure":
      switch (press_unit) {
        case "PSI":
          return " PSI";
        case "bar":
        default:
          return " bar";
      }
    case "power":
      return "W";
    case "voltage":
      return "V";
    default:
      return "";
  }
};

const getSelectedUnit = (vtype) => {
  let { temp_unit, press_unit } = reduxStore.getState().user;

  switch (vtype.toLowerCase()) {
    case "temperature":
      return temp_unit || "Celsius";
    case "pressure":
      return press_unit || "bar";
    default:
      return "";
  }
};

const convertValueForThresholds = (val, vtype) => {
  switch (vtype.toLowerCase()) {
    case "temperature":
      if (getSelectedUnit(vtype.toLowerCase()) !== "Celsius") return FToC(val);
      else return val;

    case "pressure":
      if (getSelectedUnit(vtype.toLowerCase()) !== "bar") return PsiToBar(val);
      else return val;
    default:
      return val;
  }
};

const getModelFromMFVE = (mfve) => {
  return mfve.split("_")[0];
};

const checkMfve = (mfve) => {
  const regex =
    /^[0-9a-zA-Z_-]+[_][0-9a-fA-F]{4}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{4}$/;
  return regex.test(mfve);
};

const getDeviceInfoFromMFVE = (mfve) => {
  let device = {
    mfve,
    unknown: isModelUnknown(mfve),
    model: null,
    family: null,
    firmwareRelease: null,
    mapLayout: null,
  };

  if (device.unknown) return device;

  const parseIntOrDash = (value, base) => {
    const parsed = parseInt(value, base);
    return isNaN(parsed) ? "-" : parsed;
  };

  try {
    device.model = getModelFromMFVE(mfve);
    device.family = parseIntOrDash(mfve.split("_")[1].split("-")[0], 16);
    let rel = parseIntOrDash(
      mfve.split("_")[1].split("-")[1].split("-")[0],
      16
    );
    device.firmwareRelease =
      rel === "-" ? rel : rel / 10 + (rel % 10 === 0 ? ".0" : "");
    device.mapLayout = parseIntOrDash(mfve.split("_")[1].split("-")[2], 16);
  } catch (e) {
    debug(e);
  }
  return device;
};

const isModelUnknown = (model) => {
  return model.toLowerCase().startsWith("unknown");
};

const getAlertReceiversArray = (receivers) => {
  // Split string after removing all whitespaces and leading/trailing ";" if any
  return receivers !== ""
    ? receivers.replace(/^;+|;+$|\s+/gm, "").split(";")
    : [];
};

const getArgonColors = (property) => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue("--" + property)
    .trim();
};

const geocodeReverseAddress = (r) => {
  return {
    address:
      (r.road || "-") +
      ", " +
      (r.city || r.town || r.suburb || r.village || "-") +
      ", " +
      (r.county || "-") +
      ", " +
      (r.state || "-") +
      ", " +
      (r.country || "-"),
    country: r.country,
    city: r.city || r.town || r.suburb || r.village || "-",
  };
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/*******************************
 * addToObject
 * returns the object with a
 * new key and value, placed
 * in a specific position
 *******************************/
const addToObject = (obj, key, value, index) => {
  // Create a new object and index variable
  let newObj = {};
  let i = 0;

  // Loop through the original object
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // If the indexes match, add the new item
      if (i === index && key && value) {
        newObj[key] = value;
      }

      // Add the current item in the loop to newObj
      newObj[prop] = obj[prop];

      // Increase the count
      i++;
    }
  }

  // If no index, add to the end
  if (!index && key && value) {
    newObj[key] = value;
  }

  return newObj;
};

/**
 * getThresholdType: get the Alert type -> 0: Analog - 1: Rising Edge - 2: Falling Edge
 * @param {*} maxValue
 * @param {*} minValue
 * @param {*} rangePercentage
 */
const getThresholdType = (maxValue, minValue, rangePercentage) => {
  let threshold = 0; // 0: Analog; 1: Rising Edge; 2: Falling Edge

  if (
    maxValue === 0.5 &&
    rangePercentage === 1 &&
    (minValue < 0 || minValue === null)
  )
    threshold = 1;
  else if (
    minValue === 0.5 &&
    rangePercentage === 1 &&
    (maxValue > 1 || maxValue === null)
  )
    threshold = 2;

  return threshold;
};

/**
 * getGatewayRelease
 *
 * @param {*} gatewayGuid
 * @param {boolean} [isAdmin=false]
 * @return {*}
 */
const getGatewayRelease = async (gatewayGuid, isAdmin = false) => {
  const state = store.getState();

  if (
    isAdmin ||
    !state.gateways.byId[gatewayGuid] ||
    !state.gateways.byId[gatewayGuid].releaseFW
  ) {
    return getGatewayReleaseAsync(gatewayGuid, isAdmin, state);
  } else {
    if (
      state.gateways.byId[gatewayGuid] &&
      state.gateways.byId[gatewayGuid].releaseFW
    ) {
      if (state.user.role === "Admin")
        return state.gateways.byId[gatewayGuid].releaseFW;
      return state.gateways.byId[gatewayGuid].releaseFW.split("-")[0];
    }
  }

  return null;
};

const getGatewayReleaseAsync = async (gatewayGuid, isAdmin = false, state) => {
  let response = await api.getGatewayTwin(gatewayGuid);

  if (response !== null) {
    let { device } = response.data.properties.reported;

    if (device && device.version) {
      if (state.user.role === "Admin" || isAdmin) return device.version[1];
      else return device.version[1].split("-")[0];
    }
  }
  return null;
};

const checkDifferencesOnThreshold = (data, originalData, analog) => {
  let sameReceivers = true;
  let sameAttributes = true;

  const receivers = utils.getAlertReceiversArray(data.receivers);
  const prevReceivers = utils.getAlertReceiversArray(originalData.receivers);

  if (receivers.length !== prevReceivers.length) {
    sameReceivers = false;
  } else {
    for (let i = 0; i < receivers.length; i++) {
      if (receivers[i] !== prevReceivers[i]) {
        sameReceivers = false;
        break;
      }
    }
  }

  if (analog) {
    // Check analog
    sameAttributes =
      parseFloat(data.maxValue) === parseFloat(originalData.maxValue) &&
      parseFloat(data.minValue) === parseFloat(originalData.minValue) &&
      parseFloat(data.rangePercentage) ===
        parseFloat(originalData.rangePercentage);
  } else {
    // Check digital
    Object.keys(data).forEach((item) => {
      if (item !== "receivers") {
        if (data[item] !== originalData[item]) sameAttributes = false;
      }
    });
  }

  return { receivers: sameReceivers, attributes: sameAttributes };
};

// Data conversion
/////////////////////////////////////////////////
const CToF = (value) => {
  return ((value * 9) / 5 + 32).toFixed(1);
};

const FToC = (value) => {
  return (((value - 32) * 5) / 9).toFixed(2);
};

const BAR_PSI_FACTOR = 14.504;

const barToPsi = (value) => {
  return (value * BAR_PSI_FACTOR).toFixed(1);
};

const PsiToBar = (value) => {
  return (value / BAR_PSI_FACTOR).toFixed(2);
};
/////////////////////////////////////////////////

const setLicensesInfo = (licenses = []) => {
  let newLicenses = JSON.parse(JSON.stringify(licenses));

  newLicenses.forEach((license) => {
    const content = JSON.parse(license.content);
    const days = license.duration;
    const startDate = license.activeFrom
      ? new Date(license.activeFrom + "Z")
      : null;
    const expirationDate = startDate
      ? utils.setOffsetDate(startDate, -(days * 24 * 60))
      : null;

    const timeLeft =
      startDate && expirationDate
        ? utils.getAge(new Date(), expirationDate).toLowerCase()
        : null;
    const timeLeftInt =
      startDate && expirationDate
        ? expirationDate.getTime() - new Date().getTime()
        : null;
    const isExpired =
      license.isActive === 2 ||
      (expirationDate !== null && new Date() - expirationDate >= 0);
    const difference = Math.ceil(
      (expirationDate - new Date()) / (1000 * 60 * 60 * 24)
    );
    const isExpiring =
      license.isActive === 1 &&
      !isExpired &&
      difference > 0 &&
      difference <= 28 * 2;

    license.name = content.name;
    license.expirationDate = expirationDate;
    license.licenseTime = convertLicenseDaysToHumanReadable(days);
    license.timeLeft = timeLeft;
    license.timeLeftInt = timeLeftInt;
    license.isExpired = isExpired;
    license.isExpiring = isExpiring;
    license.assigned =
      license.isActive !== 0 ||
      license.activeFrom !== null ||
      license.gateway !== null;
    license.isActive = license.isActive === 1; // pay attention, isActive is a boolean after this statement, not a number anymore
  });

  return newLicenses;
};

const getLicenseIconAndColor = (license) => {
  if (license.isActive && !license.isExpiring && !license.isExpired) {
    return [keys.ICON_OK, "bg-success"];
  } else if (license.isExpired) {
    return [keys.ICON_ERROR, "bg-danger"];
  } else if (license.isExpiring) {
    return [keys.ICON_OK, "bg-yellow"];
  } else if (license.activeFrom !== null) {
    return [keys.ICON_LICENSE_DEFERRED, "bg-primary"];
  } else return null;
};

const getSortIcon = (n) => {
  if (n === 0) return keys.ICON_SORT;
  else if (n === 1) return keys.ICON_SORT_ASC;
  else if (n === 2) return keys.ICON_SORT_DESC;
  return null;
};

const addClassToExistingElement = (element, clsname) => {
  return cloneElement(element, {
    className: element.props.className + clsname,
  });
};

const VALID_MAC = 1;
const INVALID_LENGTH = -1;
const INVALID_CHARS = -2;
const INVALID_OUI = -3;

export const checkMac = {
  VALID_MAC,
  INVALID_LENGTH,
  INVALID_CHARS,
  INVALID_OUI,
};

/**
 * Check MAC Address:
 * Verify:
 *  - Length (12)
 *  - Characters
 *  - OUI (00:0A:F6:XX:XX:XX)
 * @param {*} mac
 * @returns {*} int -> 1: OK, -1: Length
 */
const isMACAddressValid = (mac) => {
  // Check MAC Address length
  if (mac.length !== 12) {
    return INVALID_LENGTH;
  }

  let splitMac = mac.match(/.{1,2}/g);

  for (let byte of splitMac) {
    if (Number.isNaN(Number("0x" + byte))) {
      return INVALID_CHARS;
    }
  }

  if (
    splitMac[0].toUpperCase() !== "00" ||
    splitMac[1].toUpperCase() !== "0A" ||
    splitMac[2].toUpperCase() !== "F6"
  ) {
    return INVALID_OUI;
  }

  return VALID_MAC;
};

const getGatewaysForSite = (siteGuid, allGateways) => {
  return Object.keys(allGateways).reduce((acc, key) => {
    if (allGateways[key].site.siteGuid === siteGuid) {
      acc[key] = allGateways[key];
    }
    return acc;
  }, {});
};

/**
 * Sort array of objects by provided key
 * @param {*} list
 * @param {*} key
 * @returns {array}
 */
const sortArrayOfObjects = (list, key) => {
  return list.sort((a, b) => (a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0));
};

const convertLicenseDaysToHumanReadable = (totalDays) => {
  let years = 0,
    months = 0,
    days = 0;

  // 365 days as a year
  if (totalDays >= 365) {
    years = Math.floor(totalDays / 365);
    totalDays %= 365;
  }

  // 30 days as a month
  if (totalDays >= 30) {
    months = Math.floor(totalDays / 30);
    totalDays %= 30;
  }

  days = totalDays;

  let result = "";

  if (years > 0) {
    let yearLabel = t(
      `common:license.year${years > 1 ? "s" : ""}`
    ).toLowerCase();
    result += `${years} ${yearLabel}`;
    if (months > 0 || days > 0) {
      result += ", ";
    }
  }
  if (months > 0) {
    let monthLabel = t(
      `common:license.month${months > 1 ? "s" : ""}`
    ).toLowerCase();
    result += `${months} ${monthLabel}`;
    if (days > 0) {
      result += ` ${t("common:generic.and").toLowerCase()} `;
    }
  }
  if (days > 0) {
    let dayLabel = t(`common:license.day${days > 1 ? "s" : ""}`).toLowerCase();
    result += `${days} ${dayLabel}`;
  }

  return result;
};

const getGatewayLastStatus = (status) => {
  if (!status) return "unknown";
  const { cloud, system, modbus } = status;
  switch (cloud) {
    case 1:
      return "licenseNotValid";
    case 3:
      switch (system) {
        case 0:
          switch (modbus) {
            case 0:
              return "discoveryInProgress";
            case 1:
              return "discoveryCompleted";
            case 2:
              return "loadingConfiguration";
            case 3:
              return "configurationLoaded";
            case 4:
              return "normalRun";
            default:
              return "unknown";
          }
        case 1:
          return "firmwareUpdateInProgress";
        case 2:
        case 3:
        case 4:
          return "rebooting";
        default:
          return "unknown";
      }
    default:
      return "unknown";
  }
};

const utils = {
  debug,
  isChrome,
  isDevEnvironment,
  isUserAdmin,
  int2hex,
  str2hex,
  getGuidsInString,
  MSG_TYPE_LOG,
  MSG_TYPE_WARN,
  MSG_TYPE_ERR,
  formatDate,
  formatDay,
  setOffsetDate,
  formatTime,
  secondsToTime,
  secondsToTimeString,
  getAge,
  NONE,
  BEFORE,
  AFTER,
  INSIDE,
  APEX,
  NO_ZERO,
  getLangText,
  pointValue,
  getUnitByType,
  getSelectedUnit,
  convertValueForThresholds,
  getModelFromMFVE,
  isModelUnknown,
  checkMfve,
  getDeviceInfoFromMFVE,
  getAlertReceiversArray,
  getArgonColors,
  geocodeReverseAddress,
  capitalize,
  addToObject,
  getThresholdType,
  getGatewayRelease,
  getGatewayReleaseAsync,
  checkDifferencesOnThreshold,
  CToF,
  FToC,
  barToPsi,
  PsiToBar,
  setLicensesInfo,
  getLicenseIconAndColor,
  getSortIcon,
  addClassToExistingElement,
  isMACAddressValid,
  sortArrayOfObjects,
  getGatewaysForSite,
  convertLicenseDaysToHumanReadable,
  getGatewayLastStatus,
};

export default utils;
