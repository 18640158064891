import React from "react";

// reactstrap components
import { Container } from "reactstrap";

import Tabs from "../components/Tabs/Tabs";
import DeviceCards from "../components/DeviceCards";
import Parameters from "../components/Parameters";
import Chart from "../components/Chart/index";
import DeviceInfo from "../components/DeviceInfo/DeviceInfo";
import { updateCurrentPage } from "../redux/actions/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import utils from "../utils";
import api from "../services/backendService";

class SingleView extends React.Component {
  constructor(props) {
    super(props);

    this.props.updateCurrentPage(this.props.location);

    if (this.props.gatewayInfo !== null && this.props.gatewayInfo !== undefined)
      api.refreshVars(this.props.gatewayInfo.gatewayGuid);
  }

  render() {
    const title =
      this.props.controllerName === null || this.props.controllerName === ""
        ? utils.getModelFromMFVE(this.props.location.site.name)
        : this.props.controllerName;

    const { deviceGuid, address, siteInfo, gatewayInfo } = this.props;

    return (
      <>
        <Container fluid className="mt--9 mb-4">
          <Tabs
            title={title}
            address={address}
            siteInfo={siteInfo}
            gatewayInfo={gatewayInfo}
          >
            <DeviceCards
              key={1}
              name="variables"
              deviceGuid={deviceGuid}
              gatewayInfo={gatewayInfo}
            />

            <Parameters
              key={2}
              name="parameters"
              address={address}
              deviceGuid={deviceGuid}
              gatewayInfo={gatewayInfo}
            />

            <Chart
              key={3}
              name="chart"
              deviceGuid={deviceGuid}
              gatewayInfo={gatewayInfo}
            />

            <DeviceInfo
              key={4}
              name="details"
              deviceGuid={deviceGuid}
              gatewayInfo={gatewayInfo}
            />
          </Tabs>
        </Container>
      </>
    );
  }
}

const getCachedDevice = () => {
  return JSON.parse(window.sessionStorage.getItem("li"));
};

const getGatewayInfo = (gatewayStore, deviceGuid) => {
  for (let gGuid of gatewayStore.allIds) {
    for (let device of gatewayStore.byId[gGuid].devices) {
      if (device.deviceGuid === deviceGuid) {
        return gatewayStore.byId[gGuid];
      }
    }
  }
  return undefined;
};

const mapStateToProps = (state, ownProps) => {
  const lastDevice = getCachedDevice();
  const deviceGuid = ownProps.location.site.deviceGuid ?? lastDevice.deviceGuid;
  const address = ownProps.location.site.address ?? lastDevice.address;
  const siteInfo = ownProps.location.site.siteInfo ?? lastDevice.siteInfo;
  return {
    deviceGuid,
    address,
    siteInfo,
    gatewayInfo: getGatewayInfo(state.gateways, deviceGuid),
    controllerName:
      state.controllers.byId[deviceGuid] === undefined
        ? ""
        : state.controllers.byId[deviceGuid].friendlyName,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCurrentPage,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleView);
