import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Loader from "components/Loader";
import utils from "utils";
import api from "services/backendService";
import keys from "configs/constants";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../components/Notify";
import { withTranslation } from "react-i18next";
import "./point.css";

const IDLE = 0;
const OK = 1;
const ERR = 2;

class PointCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editedValue: "",
      disableInput: false,
      error: false,
      sent: IDLE, // 1: ok, 2: err
      checkboxStatus: props.card.value === "ON",
      singleCommand: false,
      updateReceived: IDLE, // 1: ok, 2: err
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.card.value !== this.props.card.value)
      this.setState({
        loading: false,
        checkboxStatus: this.props.card.value === "ON",
        updateReceived: OK,
      });
  }

  onCommandButtonClicked = (value) => {
    const data = value.split("|");
    const gatewayGuid = data[0];
    const dvcAddr = data[1];
    const cmd = data[2];
    const t = this;

    this.setState({
      loading: true,
      checkboxStatus: !this.state.checkboxStatus,
      singleCommand: true,
    });

    setTimeout(() => {
      this.setState({ singleCommand: false });
    }, 5000);

    const tr = this.props.t;

    api
      .sendMessageToGateway(gatewayGuid, {
        type: "dcmd",
        dvc: "" + dvcAddr,
        cmd: cmd,
      })
      .then(function (r) {
        if (r.status === 200)
          notifyStore.addNotification({
            ...tempOptions,
            content: NotifyContent(
              "success",
              null,
              tr("pointCard.commandSent")
            ),
          });
        setTimeout(() => {
          if (t.state.updateReceived !== OK) {
            t.setState({
              loading: false,
              updateReceived: ERR,
              checkboxStatus: t.props.card.value === "ON",
            });
          }
        }, 60000);
      })
      .catch(function () {
        t.setState({ loading: false });
        notifyStore.addNotification({
          ...tempOptions,
          content: NotifyContent("danger", null, tr("pointCard.unableToSend")),
        });
      });
  };

  onInputChange(evt) {
    const target = evt.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value,
      error: value < 0 && !this.props.card.signed ? true : false,
    });
  }

  onKeyPress(e) {
    if (e.key === "Enter" && !this.state.error) {
      const t = this;

      const intValue = Math.floor(
        e.target.value *
          (this.props.card.conversion !== null
            ? 1 / this.props.card.conversion
            : 10)
      );

      const tr = this.props.t;

      /* We are sending analog value, thus the modbus function code is 0x10 to write multiple registers */
      const hexvalue = utils.int2hex(intValue);
      const registersNumber = utils.int2hex(hexvalue.length / 4);
      const bytesNumber = utils.int2hex(hexvalue.length / 2, 2);
      const command =
        "10" +
        utils.str2hex(this.props.card.waddr) +
        registersNumber +
        bytesNumber +
        hexvalue;
      this.setState({ disableInput: true, loading: true });
      api
        .sendMessageToGateway(this.props.card.gatewayGuid, {
          type: "dcmd",
          dvc: "" + this.props.card.deviceAddress,
          cmd: command,
        })
        .then(function () {
          t.setState({ disableInput: false, loading: false, sent: OK });
          notifyStore.addNotification({
            ...tempOptions,
            content: NotifyContent(
              "success",
              null,
              tr("pointCard.newValueSent")
            ),
          });
          setTimeout(() => t.setState({ sent: IDLE }), 5000);
        })
        .catch(function () {
          t.setState({ disableInput: false, loading: false, sent: ERR });
          notifyStore.addNotification({
            ...tempOptions,
            content: NotifyContent(
              "danger",
              null,
              tr("pointCard.unableToSend")
            ),
          });
        });
    }
  }

  renderAnalogEdit = () => {
    if (!this.props.isOwner) return null;

    if (this.props.card.waddr) {
      return (
        <InputGroup
          className={`mb-4 analog-new-value ${
            this.state.editedValue === ""
              ? ""
              : this.state.error || this.state.sent === ERR
              ? "has-danger"
              : this.state.sent === OK
              ? "has-success"
              : ""
          }`}
        >
          <InputGroupText>{keys.ICON_EDIT}</InputGroupText>
          <Input
            name="editedValue"
            value={this.state.editedValue}
            placeholder="New Value"
            type="number"
            onChange={this.onInputChange.bind(this)}
            onKeyPress={this.onKeyPress.bind(this)}
            disabled={this.state.disableInput || this.props.disabled}
          />
        </InputGroup>
      );
    }
  };

  renderCommands = () => {
    if (!this.props.isOwner) return null;

    const commands = Object.values(this.props.card.cmds);
    if (commands.length === 0) return null;
    if (this.props.card.value === "BAD LINK") return null;

    const baseCmd =
      this.props.card.gatewayGuid + "|" + this.props.card.deviceAddress + "|";

    const { cmds } = this.props.card;

    return (
      <div className={"card-commands"}>
        {commands.length === 2 ? (
          <label className="custom-toggle">
            <input
              type="checkbox"
              checked={this.state.checkboxStatus}
              disabled={this.state.loading || this.props.disabled}
              onChange={() =>
                this.onCommandButtonClicked(
                  baseCmd +
                    (this.props.card.value === "ON" ? cmds.off : cmds.on)
                )
              }
            />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="No"
              data-label-on="Yes"
            ></span>
          </label>
        ) : commands.length === 1 ? (
          <RoundedButton
            onClick={() => this.onCommandButtonClicked(baseCmd + commands)}
            disabled={
              this.props.card.value === "ON" ||
              this.state.loading ||
              this.props.disabled
            }
          />
        ) : null}
      </div>
    );
  };

  render() {
    const showActive = this.props.active && window.innerWidth > 1550;

    return (
      <Card
        className={`card-point card-profile shadow h-100 w-100 mb-2 ${
          this.props.alertStatus === "alarm"
            ? "border border-danger"
            : this.state.updateReceived === ERR
            ? "border border-warning"
            : ""
        }`}
        color={
          showActive ||
          (window.innerWidth <= 992 &&
            this.props.card.value === "ON" &&
            this.props.alertStatus !== "alarm")
            ? "success"
            : ""
        }
        onClick={this.props.selectPoint}
      >
        <CardHeader className="card-name-sm text-center pt-2 pb-2 pb-xs-4 h-25">
          <div className="d-block d-flex flex-column justify-content-between text-center">
            <div className="h5 font-weight-500 text-truncate">
              <span
                className="icon-details-xs"
                onClick={this.props.requestChart}
              >
                {keys.ICON_CHART}
              </span>
              {this.props.card.name}
            </div>
          </div>
        </CardHeader>
        <CardBody className="h-100 d-flex card-value-container">
          <span className="icon-details" onClick={this.props.requestChart}>
            {keys.ICON_CHART}
          </span>
          <div
            className={`card-name h4 font-weight-500 text-truncate ${
              showActive ? "text-white" : ""
            }`}
          >
            {this.props.card.name}
          </div>
          {!this.state.loading ? (
            <h3
              className={`card-value ${
                this.props.card.value === "ERROR" ||
                this.props.card.value === "BAD LINK"
                  ? "text-warning"
                  : showActive
                  ? "text-white"
                  : this.props.digital &&
                    this.props.card.value === "ON" &&
                    this.props.alertStatus !== "alarm"
                  ? "card-value-on"
                  : ""
              }`}
            >
              <span>{this.props.card.value + this.props.card.unit}</span>
              {this.props.alertStatus === "alarm" ? (
                <span
                  className={`alarm-icon icon ${
                    window.innerWidth < 992 ? "icon-shape" : ""
                  } icon-sm bg-danger text-white ${
                    window.innerWidth < 992 ? "rounded-circle" : ""
                  } shadow text-center`}
                >
                  {keys.ICON_CONTROLLER_ALARM}
                </span>
              ) : this.state.updateReceived === ERR ? (
                <span
                  className={`alarm-icon icon ${
                    window.innerWidth < 992 ? "icon-shape" : ""
                  } icon-sm bg-warning text-white ${
                    window.innerWidth < 992 ? "rounded-circle" : ""
                  } shadow text-center`}
                >
                  {keys.ICON_WARNING}
                </span>
              ) : null}
            </h3>
          ) : null}

          {this.renderAnalogEdit()}
          {this.state.loading ? (
            <Loader custom className="send-value-loader" size={54} />
          ) : null}
          {this.renderCommands()}
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation("common")(PointCard);

const RoundedButton = (props) => {
  return (
    <span
      className={`outer-circle ${
        props.disabled ? "outer-circle-disabled" : "border-primary"
      }`}
      onClick={() => {
        if (!props.disabled) props.onClick();
      }}
    >
      <span
        className={`inner-circle ${
          props.disabled ? "inner-circle-disabled" : "bg-primary"
        }`}
      ></span>
    </span>
  );
};
