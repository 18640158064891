import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import utils from "../../utils";
import keys from "configs/constants";
import "./style.css";
import { useTranslation } from "react-i18next";

const IntervalChange = {
  DateFrom: 0,
  TimeFrom: 1,
  DateTo: 2,
  TimeTo: 3,
}

const ChartProperties = (props) => {
  const [rangeSelect, setRangeSelect] = useState("last24Hours");
  const [show, setShow] = useState(false);
  const [t] = useTranslation("common");
  const { setDateFrom, setDateTo } = props;

  let dateFrom = utils.formatDay(props.dateFrom);
  let timeFrom = props.dateFrom.toLocaleTimeString();
  let dateTo = utils.formatDay(props.dateTo);
  let timeTo = props.dateTo.toLocaleTimeString();

  const setChartInterval = (sel, dateTime) => {
    let newDateFrom = new Date((sel === 0 ? dateTime : dateFrom) + "T" + timeFrom);
    let newDateTo = new Date((sel === 2 ? dateTime : dateTo) + "T" + timeTo);
    const isMaxIntervalExceeded = getIntervalInDays(newDateFrom, newDateTo) > keys.MAX_MEASURE_INTERVAL_IN_DAYS;

    switch (sel) {
      case IntervalChange.DateFrom:
        setDateFrom(new Date(dateTime + "T" + timeFrom));
        if (isMaxIntervalExceeded) {
          newDateFrom.setDate(newDateFrom.getDate() + keys.MAX_MEASURE_INTERVAL_IN_DAYS);
          setDateTo(newDateFrom);
        }
        break;
      case IntervalChange.TimeFrom:
        setDateFrom(new Date(dateFrom + "T" + dateTime));
        break;
      case IntervalChange.DateTo:
        setDateTo(new Date(dateTime + "T" + timeTo));
        if (isMaxIntervalExceeded) {
          newDateTo.setDate(newDateTo.getDate() - keys.MAX_MEASURE_INTERVAL_IN_DAYS);
          setDateFrom(newDateTo);
        }
        break;
      case IntervalChange.TimeTo:
        setDateTo(new Date(dateTo + "T" + dateTime));
        break;
      default:
        break;
    }
  };

  const getIntervalInDays = (dateFrom, dateTo) => {
    return Math.trunc(Math.abs(dateTo - dateFrom) / 1000 / 60 / 60 / 24);
  };

  const setMidnight = (date) => {
    let newDate = new Date(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);

    return newDate;
  };

  const handleRangeType = (e) => {
    setRangeSelect(e.target.value);

    let selection = e.target.value;

    let dateFrom = new Date();
    let dateTo = new Date();

    switch (selection) {
      case "last24Hours":
        dateFrom.setDate(dateFrom.getDate() - 1);

        setDateFrom(dateFrom);
        setDateTo(dateTo);
        props.onPeriodChange();
        break;
      case "today":
        dateFrom = setMidnight(dateFrom);

        setDateFrom(dateFrom);
        setDateTo(dateTo);
        props.onPeriodChange();
        break;
      case "yesterday":
        dateFrom.setDate(dateFrom.getDate() - 1);
        dateFrom = setMidnight(dateFrom);
        dateTo = setMidnight(dateTo);

        setDateFrom(dateFrom);
        setDateTo(dateTo);
        props.onPeriodChange();
        break;
      case "last3Days":
        dateFrom.setDate(dateFrom.getDate() - 3);
        dateFrom = setMidnight(dateFrom);
        dateTo = setMidnight(dateTo);

        setDateFrom(dateFrom);
        setDateTo(dateTo);
        props.onPeriodChange();
        break;
      case "last7Days":
        dateFrom.setDate(dateFrom.getDate() - 7);
        dateFrom = setMidnight(dateFrom);
        dateTo = setMidnight(dateTo);

        setDateFrom(dateFrom);
        setDateTo(dateTo);
        props.onPeriodChange();
        break;
      case "last31Days":
        dateFrom.setDate(dateFrom.getDate() - 31);
        dateFrom = setMidnight(dateFrom);
        dateTo = setMidnight(dateTo);

        setDateFrom(dateFrom);
        setDateTo(dateTo);
        props.onPeriodChange();
        break;
      case "thisMonth":
        dateFrom.setDate(1);
        dateFrom = setMidnight(dateFrom);
        dateTo = setMidnight(dateTo);

        setDateFrom(dateFrom);
        setDateTo(dateTo);
        props.onPeriodChange();
        break;
      case "lastMonth":
        dateFrom.setMonth(dateFrom.getMonth() - 1);
        dateFrom.setDate(1);
        dateTo.setDate(1);
        dateFrom = setMidnight(dateFrom);
        dateTo = setMidnight(dateTo);

        setDateFrom(dateFrom);
        setDateTo(dateTo);
        props.onPeriodChange();
        break;
      case "Custom":
      default:
        break;
    }
  };

  const toggle = () => {
    setShow(!show);
  };

  const Properties = (boxProps) => {
    return (
      <div className={`chart-type${boxProps.wide === true ? "-wide" : ""}`}>
        
{
  /*  FUTURE FEATURE: Right now the realtime option is not completely implemented. We will add it back when it is ready.
        
          <ListDropdown
          className={`${
            !utils.isUserAdmin() && !boxProps.realtimeEnabled ? "d-none" : ""
          }`} // DamaLicense
          title={props.realtime ? t("chart.realTime") : t("chart.interval")}
          listItems={[
            {
              text: t("chart.realTime"),
              command: () => props.setRealTime(true),
            },
            {
              text: t("chart.interval"),
              command: () => props.setRealTime(false),
            },
          ]}
        />
  */
}

        <select
          className="m-2 bg-white"
          disabled={props.realtime}
          value={rangeSelect}
          onChange={(e) => handleRangeType(e)}
        >
          <option value="last24Hours">{t("chart.last24h")}</option>
          <option value="today">{t("chart.today")}</option>
          <option value="yesterday">{t("chart.yesterday")}</option>
          <option value="last3Days">{t("chart.last3d")}</option>
          <option value="last7Days">{t("chart.last7d")}</option>
          <option value="last31Days">{t("chart.last31d")}</option>
          <option value="thisMonth">{t("chart.currentMonth")}</option>
          <option value="lastMonth">{t("chart.previousMonth")}</option>
          <option value="custom">{t("chart.custom")}</option>
        </select>

        <span>
          <input
            className="input-date bg-white"
            type="date"
            disabled={props.realtime || rangeSelect !== "custom"}
            value={dateFrom}
            max={dateTo}
            onChange={(e) => {
              setChartInterval(IntervalChange.DateFrom, e.target.value);
            }}
          />
          <input
            className="input-time bg-white"
            type="time"
            disabled={props.realtime || rangeSelect !== "custom"}
            value={timeFrom}
            onChange={(e) => {
              setChartInterval(IntervalChange.TimeFrom, e.target.value);
            }}
          />
        </span>
        <span id="separator">▶︎</span>
        <span>
          <input
            className="input-date bg-white"
            type="date"
            disabled={props.realtime || rangeSelect !== "custom"}
            value={dateTo}
            min={dateFrom}
            onChange={(e) => {
              setChartInterval(IntervalChange.DateTo, e.target.value);
            }}
          />
          <input
            className="input-time bg-white"
            type="time"
            disabled={props.realtime || rangeSelect !== "custom"}
            value={timeTo}
            onChange={(e) => {
              setChartInterval(IntervalChange.TimeTo, e.target.value);
            }}
          />
        </span>

        <Button
          color="primary"
          type="button"
          disabled={props.realtime}
          onClick={(e) => {
            e.preventDefault();
            props.getDeviceMeasures();
            setShow(false);
          }}
          style={{ margin: "5px" }}
        >
          <div className="load">
            <div className="load-text text-uppercase">
              {t("chart.loadData")}
            </div>
            <span className="mr-0 btn-inner--icon">
              <div className="load-icon">{keys.ICON_LOAD}</div>
            </span>
          </div>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Button className="button-show" color="secondary" onClick={toggle}>
        <span style={{ marginRight: "5px" }}>{t("chart.settings")}</span>
        <span className="mr-0 btn-inner--icon">{keys.ICON_SETTINGS}</span>
      </Button>
      <Modal centered isOpen={show} toggle={toggle}>
        <ModalBody>
          <Properties realtimeEnabled={props.realtimeEnabled} />
        </ModalBody>
      </Modal>
      <div style={{ marginTop: "20px" }}>
        <Properties wide realtimeEnabled={props.realtimeEnabled} />
      </div>
    </>
  );
};

export default ChartProperties;
