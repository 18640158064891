import React, { Component } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Button,
  Table,
  Collapse,
  Badge,
} from "reactstrap";
import { TooltipWarning, TooltipButton } from "../../components/Tooltip";
import Loader from "../../components/Loader";
import keys from "configs/constants";
import api from "../../services/backendService";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateGateway,
  apiAction,
  setControllers,
  setGateways,
  setSites,
} from "redux/actions/index";
import { bindActionCreators } from "redux";
import { createSelector } from "reselect";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../Notify";
import utils from "../../utils";
import { withTranslation } from "react-i18next";
import SelectModelModal from "./SelectModel/SelectModelModal";

class GatewayProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.location.gateway.name,
      telemetry: this.props.store.gateway.telemetryPushInterval,
      nameChanged: false,
      telemetryChanged: false,
      isLoading: true,
      inputError: {
        nameError: false,
        telemetryError: false,
      },
      isOpen: true,
      redirect: false,
      releaseFW: null,
      isModalOpen: false,
      address: null,
      thirdPartyDevices: [],
    };
    this.name = this.state.name;
    this.telemetry = this.state.telemetry.toString();

    this.siteInfo = {
      siteGuid: null,
      gatewayGuid: null,
      deviceGuid: null,
      name: null,
      /* city: this.props.city,
      country: this.props.country,
      connectivitytype: this.props.connectivitytype,
      latitude: this.props.latitude,
      longitude: this.props.longitude,
      address: this.props.address, */
    };
  }

  /* getGatewayInfo = async () => {
    let gateway = (
      await api.getSingleGatewayDetail(this.props.location.gateway.gatewayGuid)
    ).data;

    this.setState({
      telemetry: gateway.telemetryPushInterval,
      isLoading: false,
    });
    this.telemetry = this.state.telemetry.toString();
  };*/

  componentDidMount = async () => {
    await this.getThirdPartyDevices();
    await this.setGatewayRelease();
  };

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.store.gateway.releaseFW !== this.props.store.gateway.releaseFW
    ) {
      await this.setGatewayRelease();
    }
  };

  async getThirdPartyDevices() {
    try {
      const response = await api.getThirdPartyDevices();
      const devices = response.data
        .filter((device) => device.mfve && utils.checkMfve(device.mfve))
        .map((device) => utils.getDeviceInfoFromMFVE(device.mfve));
      this.setState({ thirdPartyDevices: devices });
    } catch (e) {
      this.setState({ thirdPartyDevices: [] });
    }
  }

  setGatewayRelease = async () => {
    let releaseFW = await utils.getGatewayRelease(
      this.props.location.gateway.gatewayGuid
    );

    this.setState({
      isLoading: false,
      releaseFW: releaseFW,
    });
  };

  onInputChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    if (name === "name") {
      if (value !== this.name)
        this.setState({ [name]: value, nameChanged: true });
      else this.setState({ [name]: value, nameChanged: false });
      let { inputError } = this.state;
      if (value === "") inputError.nameError = true;
      else inputError.nameError = false;
      this.setState({ inputError });
    } /* else if (name === "telemetry") {
      if (value !== this.telemetry)
        this.setState({ [name]: value, telemetryChanged: true });
      else this.setState({ [name]: value, telemetryChanged: false });

      let { inputError } = this.state;

      if (value === "") inputError.telemetryError = true;
      else inputError.telemetryError = !isFinite(value);
      this.setState({ inputError });
    } */
    this.setState({ [name]: value });
  };

  onTelemetryChange = (event, otherValue) => {
    const { target } = event;
    const { name, value } = target;
    let seconds;

    if (name === "minutes") {
      seconds = parseInt(value) * 60 + parseInt(otherValue);
    } else if (name === "seconds") {
      seconds = parseInt(otherValue) * 60 + parseInt(value);
    }

    seconds = seconds.toString();

    this.setState({
      telemetry: seconds === "0" ? "1" : seconds,
      telemetryChanged: seconds !== this.telemetry,
    });
  };

  onSaveChanges = async () => {
    const gwGUID = this.props.location.gateway.gatewayGuid;
    const gwName = this.state.name.trim();
    const gwTelemetryInterval = this.state.telemetry;
    const sGUID = this.props.location.gateway.siteGuid;

    this.setState({ isLoading: true });

    this.props.apiAction(
      api.updateGateway,
      [gwGUID, gwName, gwTelemetryInterval, sGUID],
      updateGateway
    );
    this.props.setControllers(
      await api.getDevicesOnSite(this.props.location.site.siteGuid)
    );
    this.props.setSites(await api.getSiteInitialState());
    this.props.setGateways(await api.getGatewayInitialState());

    this.name = this.state.name;
    this.telemetry = this.state.telemetry;

    this.setState({
      nameChanged: false,
      telemetryChanged: false,
      isLoading: false,
    });
  };

  onForgetChanges = () => {
    this.setState({
      name: this.name,
      telemetry: this.telemetry,
      inputError: {
        nameError: false,
        telemetryError: false,
      },
      nameChanged: false,
      telemetryChanged: false,
    });
  };

  deviceNumber = () => {
    const deviceIds = this.props.location.gateway.devices.allIds;
    const devices = this.props.location.gateway.devices.byId;
    const gatewayGuid = this.props.location.gateway.gatewayGuid;

    return deviceIds.filter(
      (deviceGuid) => devices[deviceGuid].gateway.gatewayGuid === gatewayGuid
    ).length;
  };

  getMinSec = () => {
    let list = [];

    for (let i = 0; i < 60; i++) {
      list.push(i < 10 ? "0" + i.toString() : i.toString());
    }

    return list;
  };

  editDeviceModel = (e, address) => {
    e.stopPropagation();
    this.toggleModal(address);
  };

  toggleModal = (address) => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      address: address,
    }));
  };

  ///////////////////////////////////////
  // GATEWAY COMMANDS
  ///////////////////////////////////////

  async rebootClicked() {
    const stateKey = "rebooting";
    const cmdObject = {
      type: "gcmd",
      cmd: "reboot",
    };
    await this.handleGatewayCommand(
      stateKey,
      cmdObject,
      "gatewayProperties.commandSent",
      "gatewayProperties.unableToSend"
    );
  }

  async fwUpdateClicked() {
    const stateKey = "updatingfw";
    const cmdObject = {
      type: "gcmd",
      cmd: "fwupdate",
    };
    await this.handleGatewayCommand(
      stateKey,
      cmdObject,
      "gatewayProperties.commandSent",
      "gatewayProperties.unableToSend"
    );
  }

  async rescanClicked() {
    const stateKey = "rescan";
    const cmdObject = {
      type: "gcmd",
      cmd: "rescan",
    };
    await this.handleGatewayCommand(
      stateKey,
      cmdObject,
      "gatewayProperties.commandSent",
      "gatewayProperties.unableToSend"
    );
  }

  async refreshLibClicked() {
    const stateKey = "refreshLib";
    const cmdObject = {
      type: "gcmd",
      cmd: "dsc_update",
    };
    await this.handleGatewayCommand(
      stateKey,
      cmdObject,
      "gatewayProperties.commandSent",
      "gatewayProperties.unableToSend"
    );
  }

  async setThirdPartyDeviceClicked(mfve) {
    const stateKey = "setThirdPartyDevice";
    const cmdObject = {
      type: "gcmd",
      cmd: "setmfve",
      dvcs: [{ id: this.state.address, mfve }],
    };
    await this.handleGatewayCommand(
      stateKey,
      cmdObject,
      "gatewayProperties.setMfveSent",
      "gatewayProperties.setMfveUnableToSend"
    );
  }

  async handleGatewayCommand(
    stateKey,
    cmdObject,
    successMessage,
    failureMessage
  ) {
    this.setState({ [stateKey]: true });

    const gatewayGuid = this.props.location.gateway.gatewayGuid;
    const r = await api.sendMessageToGateway(gatewayGuid, cmdObject);

    this.setState({ [stateKey]: false });

    if (r.status === 200) {
      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent("success", null, this.props.t(successMessage)),
      });
    } else {
      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent("danger", null, this.props.t(failureMessage)),
      });
    }
  }

  ///////////////////////////////////////
  // RENDER
  ///////////////////////////////////////

  render() {
    const { thirdPartyDevices, isModalOpen } = this.state;
    if (this.props.store.apiFetching) return <Loader />;
    if (this.state.redirect)
      return (
        <Redirect
          to={{
            pathname: "/std/sv",
            site: this.siteInfo,
          }}
        />
      );

    const somethingChanged =
      this.state.nameChanged || this.state.telemetryChanged;
    const inputError =
      this.state.inputError.nameError || this.state.inputError.telemetryError;

    const gatewayOffline = this.props.store.gateway.status === "OFF";

    const isViewer =
      this.props.store.permissionGateway.toLowerCase() === "viewer";

    const minutes = this.getMinSec()[Math.floor(this.state.telemetry / 60)];
    const seconds = this.getMinSec()[this.state.telemetry % 60];

    const license = this.props.store.gateway.licenses.filter(
      (l) => l.isActive
    )[0];

    const gtwLastStatus = utils.getGatewayLastStatus(
      this.props.store.gateway.lastStatus
    );

    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <Container fluid>
              <Card
                className={`card-profile shadow card-gateway ${
                  gatewayOffline ? "border border-warning" : ""
                }`}
              >
                <CardHeader className="text-center">
                  <div
                    className={`text-warning position-absolute fixed-top text-left m-3 ml-4 h1 ${
                      gatewayOffline ? "" : "d-none"
                    }`}
                  >
                    <span id={`warning${this.props.siteGuid}`}>
                      {keys.ICON_WARNING}
                    </span>
                  </div>
                  <div className="h2">{this.name}</div>
                  {gtwLastStatus !== "unknown" && !gatewayOffline && (
                    <Badge
                      color={
                        gtwLastStatus === "licenseNotValid" ? "warning" : "info"
                      }
                        className="text-white"
                    >
                      <span className="font-weight-bold">
                        {" "}
                        {this.props.t(`gatewayStatus.${gtwLastStatus}`)}
                      </span>
                    </Badge>
                  )}
                  {this.props.store.gateway.status === "OFF" && (
                      <Badge className="text-white mx-2" color="warning">
                      {new Date(
                        this.props.store.gateway.lastDataSentOn + "Z"
                      ).getYear() > 0
                        ? this.props.t("gatewayProperties.lastUpdate") +
                          ": " +
                          new Date(
                            this.props.store.gateway.lastDataSentOn + "Z"
                          ).toLocaleString()
                        : this.props.t("gatewayProperties.gatewayOffline")}
                    </Badge>
                  )}
                </CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      {/* Gateway Name */}
                      <InputGroup className="mb-3">
                        <InputGroupText
                          id="group-name"
                          className={
                            this.state.inputError.nameError
                              ? "bg-danger text-white"
                              : ""
                          }
                        >
                          {keys.ICON_GATEWAY}
                          <TooltipWarning
                            target="group-name"
                            text={this.props.t("gatewayProperties.nameVoid")}
                            warning={this.state.inputError.nameError}
                          />
                          <TooltipButton
                            target="group-name"
                            text="Gateway Name"
                          />
                        </InputGroupText>
                        <Input
                          placeholder={this.props.t("gatewayProperties.name")}
                          id="name"
                          name="name"
                          type="text"
                          onChange={(e) => this.onInputChange(e)}
                          value={this.state.name}
                        />
                      </InputGroup>
                      {/* Gateway MAC Address */}
                      <InputGroup className="mb-3">
                        <InputGroupText id="mac-address">
                          {keys.ICON_SEARCH}
                        </InputGroupText>
                        <Input
                          placeholder={this.props.t(
                            "gatewayProperties.macAddress"
                          )}
                          id="address"
                          name="address"
                          type="text"
                          value={this.props.location.gateway.macAddress}
                          readOnly
                        />
                        <TooltipButton
                          target="mac-address"
                          text={this.props.t("gatewayProperties.macAddress")}
                        />
                      </InputGroup>
                      {/* Release FW */}
                      <InputGroup className="mb-3">
                        <InputGroupText id="fw-release">
                          {keys.ICON_MAP}
                        </InputGroupText>
                        <Input
                          placeholder={this.props.t(
                            "gatewayProperties.release"
                          )}
                          id="releaseFW"
                          name="releaseFW"
                          type="text"
                          value={this.state.releaseFW || "Not Available"}
                          readOnly
                        />
                        <TooltipButton
                          target="fw-release"
                          text={this.props.t("gatewayProperties.release")}
                        />
                      </InputGroup>
                      {/* Site name */}
                      <InputGroup className="mb-3">
                        <InputGroupText id="site-name">
                          {keys.ICON_SITE}
                        </InputGroupText>
                        <Input
                          placeholder={this.props.t("gatewayProperties.site")}
                          id="site"
                          name="site"
                          type="text"
                          value={this.props.location.gateway.site}
                          readOnly
                        />
                        <TooltipButton
                          target="site-name"
                          text={this.props.t("gatewayProperties.site")}
                        />
                      </InputGroup>
                      {/* License */}
                      <div className="d-flex">
                        <InputGroup className="mb-3 mr-2">
                          <InputGroupText id="license-1">
                            {keys.ICON_LICENSE}
                          </InputGroupText>
                          <Input
                            placeholder={this.props.t(
                              "gatewayProperties.noActiveLicense"
                            )}
                            id="license-name"
                            name="license-name"
                            type="text"
                            value={
                              license &&
                              license.name + " - " + license.licenseTime
                            }
                            readOnly
                          />

                          <TooltipButton
                            target="license-1"
                            text={this.props.t("gatewayProperties.license")}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroupText id="license-2">
                            {keys.ICON_LICENSE}
                          </InputGroupText>
                          <Input
                            placeholder={this.props.t(
                              "gatewayProperties.timeLeft"
                            )}
                            id="license-duration"
                            name="license-duration"
                            type="text"
                            value={license && license.timeLeft}
                            readOnly
                          />

                          <TooltipButton
                            target="license-2"
                            text={this.props.t("gatewayProperties.timeLeft")}
                          />
                        </InputGroup>
                      </div>

                      {/* Telemetry */}
                      {this.props.store.user.role === "Admin" ? (
                        <InputGroup>
                          <InputGroupText
                            id="group-telemetry"
                            className={
                              this.state.inputError.telemetryError
                                ? "bg-danger text-white"
                                : ""
                            }
                          >
                            {keys.ICON_LOAD}
                            <TooltipWarning
                              target="group-telemetry"
                              text={this.props.t(
                                "gatewayProperties.invalidInterval"
                              )}
                              warning={this.state.inputError.telemetryError}
                            />
                            <div className="input-telemetry">
                              <span className="input-telemetry-label">
                                {this.props.t("gatewayProperties.minutes") +
                                  ":"}
                              </span>
                              <select
                                className="input-telemetry-value"
                                name="minutes"
                                value={minutes}
                                onChange={(e) => {
                                  this.onTelemetryChange(e, seconds);
                                }}
                              >
                                {this.getMinSec().map((item) => {
                                  return <option key={item}>{item}</option>;
                                })}
                              </select>

                              <span className="input-telemetry-label">
                                {this.props.t("gatewayProperties.seconds") +
                                  ":"}
                              </span>
                              <select
                                className="input-telemetry-value"
                                name="seconds"
                                value={seconds}
                                onChange={(e) => {
                                  this.onTelemetryChange(e, minutes);
                                }}
                              >
                                {this.getMinSec().map((item) => {
                                  if (
                                    item === "00" &&
                                    this.state.telemetry < 60
                                  )
                                    return null;
                                  return <option key={item}>{item}</option>;
                                })}
                              </select>
                            </div>
                          </InputGroupText>
                        </InputGroup>
                      ) : (
                        <InputGroup className="mb-3">
                          <InputGroupText id="push-interval">
                            {keys.ICON_LOAD}
                          </InputGroupText>
                          <Input
                            placeholder={this.props.t(
                              "gatewayProperties.pushInterval"
                            )}
                            id="push-telemetry"
                            name="push-telemetry"
                            type="text"
                            value={utils.formatTime(
                              this.state.telemetry,
                              utils.NO_ZERO,
                              false
                            )}
                            readOnly
                          />
                          <TooltipButton
                            target="push-interval"
                            text={this.props.t(
                              "gatewayProperties.pushInterval"
                            )}
                          />
                        </InputGroup>
                      )}
                    </FormGroup>
                  </Form>

                  <Button
                    className="ml-xs-2 btn-icon"
                    color="primary"
                    type="button"
                    onClick={() => this.onSaveChanges()}
                    disabled={!somethingChanged || inputError || isViewer}
                  >
                    <span className="btn-inner--icon">{keys.ICON_SAVE}</span>
                    <span className="btn-inner--text">
                      {this.props.t("gatewayProperties.save")}
                    </span>
                  </Button>
                  <Button
                    className="ml-xs-2 btn-icon"
                    color="primary"
                    type="button"
                    onClick={() => this.onForgetChanges()}
                    disabled={!somethingChanged || isViewer}
                  >
                    <span className="mr-0 btn-inner--icon">
                      {keys.ICON_UNDO}
                    </span>
                    <span className="btn-inner--text">
                      {this.props.t("gatewayProperties.forget")}
                    </span>
                  </Button>
                  <Button
                    className="my-2"
                    color="primary"
                    onClick={this.rescanClicked.bind(this)}
                    disabled={isViewer || gatewayOffline}
                  >
                    <span>{this.props.t("gatewayProperties.rescan")}</span>
                  </Button>
                  <Button
                    className="my-2"
                    color="primary"
                    onClick={this.refreshLibClicked.bind(this)}
                    disabled={isViewer || gatewayOffline}
                  >
                    <span>{this.props.t("gatewayProperties.refresh")}</span>
                  </Button>
                  <Button
                    className="my-2"
                    color="primary"
                    onClick={this.rebootClicked.bind(this)}
                    disabled={isViewer || gatewayOffline}
                  >
                    <span>{this.props.t("gatewayProperties.reboot")}</span>
                  </Button>
                  <Button
                    className="my-2"
                    color="primary"
                    onClick={this.fwUpdateClicked.bind(this)}
                    disabled={isViewer || gatewayOffline}
                  >
                    {this.props.t("gatewayProperties.update")}
                  </Button>
                  {/* Devices */}
                  <div className="border mt-2 card-devices" color="primary">
                    <Button
                      color="primary"
                      onClick={() =>
                        this.setState({ isOpen: !this.state.isOpen })
                      }
                      style={{ marginBottom: "1rem" }}
                      className="ml-2 mt-3"
                    >
                      {(this.state.isOpen
                        ? this.props.t("gatewayProperties.collapse")
                        : this.props.t("gatewayProperties.expand")) +
                        " (" +
                        this.deviceNumber() + //this.props.location.gateway.devices.allIds.length +
                        ")"}
                    </Button>
                    <Collapse isOpen={this.state.isOpen}>
                      {this.renderDeviceTable()}
                    </Collapse>
                  </div>
                </CardBody>
              </Card>
            </Container>
            <SelectModelModal
              isOpen={isModalOpen}
              onClose={this.toggleModal}
              data={thirdPartyDevices}
              onConfirm={this.setThirdPartyDeviceClicked.bind(this)}
            />
          </>
        )}
      </>
    );
  }

  renderDeviceTable = () => {
    return (
      <Table responsive hover>
        <thead className="thead-light">
          <tr>
            <th scope="col">
              {this.props.t("gatewayProperties.friendlyName")}
            </th>
            <th scope="col">{this.props.t("gatewayProperties.model")}</th>
            <th scope="col">{this.props.t("gatewayProperties.family")}</th>
            <th scope="col">{this.props.t("gatewayProperties.release")}</th>
            <th scope="col">{this.props.t("gatewayProperties.mapLayout")}</th>
            <th scope="col">{this.props.t("gatewayProperties.address")}</th>
            <th scope="col">{this.props.t("gatewayProperties.createdAt")}</th>
            <th scope="col">{this.props.t("gatewayProperties.modifiedAt")}</th>
          </tr>
        </thead>
        <tbody className="h-65">{this.renderDeviceTableRows()}</tbody>
      </Table>
    );
  };

  renderDeviceTableRows = () => {
    const devicesIds = this.props.location.gateway.devices.allIds;
    const devices = this.props.location.gateway.devices.byId;

    let rows = [];

    devicesIds.forEach((deviceGuid) => {
      const device = devices[deviceGuid];
      const {
        friendlyName,
        model: mfve,
        address,
        createdAt,
        modifiedAt,
      } = device;

      if (
        device.gateway.gatewayGuid !== this.props.location.gateway.gatewayGuid
      ) {
        return;
      }

      const deviceInfo = utils.getDeviceInfoFromMFVE(mfve);

      const gatewayOffline = this.props.store.gateway.status === "OFF";

      const redirect = () => {
        this.siteInfo = {
          siteGuid: device.site.siteGuid,
          gatewayGuid: device.gateway.gatewayGuid,
          deviceGuid: deviceGuid,
          name: device.model,
          address: address,
          siteInfo: this.props.location.site,
        };

        this.setState({ redirect: true });
      };

      const isModelThirdParty = this.state.thirdPartyDevices.some(
        (d) => d.mfve === deviceInfo.mfve
      );

      const isDeviceEditable = deviceInfo.unknown || isModelThirdParty;

      const isEditButtonDisabled =
        this.state.thirdPartyDevices < 1 && gatewayOffline;

      const unknownDeviceModel = this.props.t("generic.unknown");

      const editButton = (
        <Button
          disabled={isEditButtonDisabled}
          size="sm"
          className="ml-xs-2 btn-icon px-1 py-0"
          color="primary"
          type="button"
          onClick={(e) => this.editDeviceModel(e, address)}
        >
          <span className="btn-inner--text">
            {deviceInfo.model || unknownDeviceModel}
          </span>
          <span>{keys.ICON_EDIT}</span>
        </Button>
      );

      rows.push(
        <tr
          key={deviceGuid}
          onClick={deviceInfo.unknown ? null : () => redirect()}
        >
          <td>{friendlyName || "- - -"}</td>
          <td>
            {isDeviceEditable
              ? editButton
              : deviceInfo.model || unknownDeviceModel}
          </td>
          <td>{deviceInfo.family || "-"}</td>
          <td>{deviceInfo.firmwareRelease || "-"}</td>
          <td>{deviceInfo.mapLayout || "-"}</td>
          <td>{address}</td>
          <td>{new Date(createdAt).toLocaleString()}</td>
          <td>{new Date(modifiedAt).toLocaleString()}</td>
        </tr>
      );
    });

    return rows;
  };
}

const getGateway = createSelector(
  (state, gatewayGuid) => state.gateways.byId[gatewayGuid],
  (gateway) => {
    return gateway;
  }
);

const getPermissionGateway = createSelector(
  (state, gatewayGuid) => state.permissions.gateways.byId[gatewayGuid],
  (permission) => (permission ? permission.level : "Viewer")
);

const mapStateToProps = (state, ownProps) => {
  return {
    store: {
      apiFetching: state.api.isFetching,
      apiError: state.api.error,
      gateway: getGateway(state, ownProps.location.gateway.gatewayGuid),
      user: state.user,
      permissionGateway:
        state.user.role === "Admin"
          ? "Owner"
          : getPermissionGateway(state, ownProps.location.gateway.gatewayGuid),
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      apiAction,
      setControllers,
      setGateways,
      setSites,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(GatewayProperties)
);
