import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Nav, NavLink, TabContent, TabPane, Card, Button, Badge } from "reactstrap";
import keys from "../../configs/constants";
import "./style.css";

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab:
        this.props.children.length === undefined
          ? this.props.children.key
          : this.props.children[0].key,
    };

    this.children = [];

    if (this.props.children.length === undefined) {
      this.children.push(this.props.children);
    } else {
      this.props.children.forEach((child) => {
        this.children.push(child);
      });
    }
  }

  render() {
    const NavList = () => {
      return this.children.map((child) => {
        let isActive = this.state.activeTab === child.key;
        return (
          <span key={child.key} className="tab-item">
            <NavLink
              active
              onClick={() => {
                if (this.state.activeTab !== child.key)
                  this.setState({ activeTab: child.key });
              }}
              style={{
                display: "grid",
                fontWeight: isActive ? "bold" : "unset",
                background: "#FAFAFA",
                color: isActive ? "#000" : "#888",
                borderBottom: isActive ? "2px solid #BBB" : null,
                cursor: "pointer",
              }}
            >
              <span className={"text-item" + (isActive ? "-active" : "")}>
                {this.props.t("singleView." + child.props.name)}
              </span>
            </NavLink>
          </span>
        );
      });
    };
    const TabList = () => {
      return this.children.map((child) => {
        return this.state.activeTab === child.key ? (
          <TabPane key={child.key} tabId={child.key}>
            {child}
          </TabPane>
        ) : null;
      });
    };

    return (
      <Card
        className={`p-4 ${
          this.props.gatewayInfo.status === "OFF" ? "border border-warning" : ""
        }`}
      >
        <Link
          to={{
            pathname: "/std/dvcs",
            site: this.props.siteInfo,
          }}
        >
          <Button className="btn-back">{keys.ICON_ARROW_LEFT}</Button>
        </Link>

        <div className="text-center p-1 mb-3">
          <div>
            <span className="h3">{this.props.title}</span>
            <span className="text-center font-weight-light mb-3 ml-2">
              {this.props.t("connectedDevice.reference") +
                ": " +
                this.props.address}
            </span>
          </div>

          <Badge
            className={`text-white ${this.props.gatewayInfo.status !== "OFF" && "d-none"}`}
            color="warning"
          >
            {new Date(this.props.gatewayInfo.lastDataSentOn + "Z").getYear() > 0
              ? `${this.props.t("cardHeader.lastUpdate")}: 
                ${new Date(
                  this.props.gatewayInfo.lastDataSentOn + "Z"
              ).toLocaleString()}`
              : "Gateway Offline"}
          </Badge>
        </div>

        <div
          className={`${
            this.props.gatewayInfo.status === "OFF" ? "" : "d-none"
          } text-warning position-absolute right-2 mr-4 h1`}
        >
          <span id={`warning${this.props.siteGuid}`}>{keys.ICON_WARNING}</span>
        </div>

        <Nav pills fill className="tabs-container">
          <NavList />
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabList />
        </TabContent>
      </Card>
    );
  }
}

export default withTranslation("common")(Tabs);
