import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Button,
} from "reactstrap";

import keys from "configs/constants";
import api from "services/backendService";
import Loader from "components/Loader";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../../components/Notify";
import { withTranslation } from "react-i18next";

export class ResultContainer extends React.Component {
  state = {
    hover: false,
  };

  onResultClicked = (item) => {
    this.props.onSelect(item);
  };

  render() {
    return (
      <>
        {this.props.items.length === 0 ? (
          <div></div>
        ) : (
          <div className="mt-2">
            {this.props.items.map((item) => (
              <div
                key={item.customerGuid}
                className="resitem border border-info rounded shadow bg-secondary w-50 p-2 mb-1"
                onClick={() => this.onResultClicked(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}

class SubscriptionAdmin extends React.Component {
  state = {
    searchTerm: "",
    data: [],
    total: 0,
    showLoader: false,
    selectedSubscription: null,
    subscriptionPermission: null,
    createNew: false,
    email: "",
    subscriptionName: "",
    error: null,
  };

  onCreateNewClicked = () => {
    this.setState({
      searchTerm: "",
      selectedSubscription: null,
      subscriptionPermission: null,
      showLoader: false,
      createNew: true,
    });
  };

  async componentDidMount() {
    this.setState({ showLoader: true });
    const pload = await api.getCustomersDetails();
    this.setState({
      data: pload.data.data,
      total: pload.data.total,
      showLoader: false,
    });
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  onSelectedItem = async (item) => {
    this.setState({ showLoader: true });
    try {
      const p = (await api.getPermissionCustomer(item.customerGuid)).data.data;
      this.setState({
        searchTerm: "",
        selectedSubscription: item,
        subscriptionPermission: p,
        showLoader: false,
      });
    } catch (ex) {
      this.setState({
        showLoader: false,
      });
    }
  };

  dynamicSearch = () => {
    if (this.state.searchTerm === "") return [];
    const s = this.state.data.filter((item) => {
      return item.name
        .toLowerCase()
        .includes(this.state.searchTerm.toLowerCase());
    });
    return s;
  };

  createNew = async () => {
    this.setState({ showLoader: true });
    const p = await api.createNewSubscriptionAdminOnly(
      this.state.email,
      this.state.subscriptionName
    );
    if (p.status === 201) {
      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent(
          "success",
          null,
          this.props.t("subscriptionAdmin.done") + "!"
        ),
      });
      this.setState({
        searchTerm: "",
        data: [],
        total: 0,
        showLoader: false,
        selectedSubscription: null,
        subscriptionPermission: null,
        createNew: false,
        email: "",
        subscriptionName: "",
        error: null,
      });
    } else {
      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent(
          "danger",
          null,
          this.props.t("subscriptionAdmin.issueInstructions")
        ),
      });

      let parsedResponse = p;

      p.config.data = JSON.parse(p.config.data);

      this.setState({
        searchTerm: "",
        data: [],
        total: 0,
        showLoader: false,
        selectedSubscription: null,
        subscriptionPermission: null,
        error: parsedResponse,
      });
    }
  };

  renderSubscription = () => {
    let owners = [];
    let notOwners = [];
    for (const info of Object.values(this.state.subscriptionPermission)) {
      if (info.level === "Owner") {
        owners.push(info.user);
      } else {
        notOwners.push(info.user);
      }
    }
    return (
      <>
        <div className="mt-3">
          <b>{this.props.t("subscriptionAdmin.admins") + ":"}</b>
          {owners.map((item) => (
            <div key={item.userGuid}>{item.email}</div>
          ))}
        </div>
        <div className="mt-3">
          <b>{this.props.t("subscriptionAdmin.otherAccesses") + ":"}</b>
          {notOwners.map((item) => (
            <div key={item.userGuid}>{item.email}</div>
          ))}
        </div>
      </>
    );
  };

  renderCreateNew = () => {
    return (
      <>
        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_CUSTOMER}</InputGroupText>
            <Input
              placeholder={this.props.t("subscriptionAdmin.customerName")}
              name="subscriptionName"
              type="text"
              onChange={this.onInputChange.bind(this)}
              value={this.state.subscriptionName}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_CUSTOMER}</InputGroupText>
            <Input
              placeholder={this.props.t("subscriptionAdmin.adminEmail")}
              name="email"
              type="email"
              onChange={this.onInputChange.bind(this)}
              value={this.state.email}
            />
          </InputGroup>
        </FormGroup>
        <Button
          className="ml-5 btn-icon"
          color="primary"
          type="button"
          onClick={this.createNew}
          disabled={
            this.state.email === "" || this.state.subscriptionName === ""
          }
        >
          <span className="btn-inner--icon">{keys.ICON_SAVE}</span>
          <span className="btn-inner--text">
            {this.props.t("subscriptionAdmin.create") + "!"}
          </span>
        </Button>
      </>
    );
  };

  render() {
    const itemsFound = this.dynamicSearch();

    return (
      <>
        {this.state.showLoader && <Loader />}
        <Container fluid className="mt--9">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader>
                  {this.props.t("subscriptionAdmin.title")}
                </CardHeader>
                <CardBody className="d-flex-column">
                  <Form className="navbar-search form-inline d-none d-md-flex mr-lg-auto">
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>{keys.ICON_SEARCH}</InputGroupText>
                        <Input
                          name="searchTerm"
                          placeholder={
                            this.props.t("subscriptionAdmin.search") + "..."
                          }
                          value={this.state.searchTerm}
                          onChange={this.onInputChange.bind(this)}
                          type="text"
                        />
                      </InputGroup>
                    </FormGroup>
                    <Button
                      className="ml-5 btn-icon"
                      color="primary"
                      type="button"
                      onClick={this.onCreateNewClicked}
                      disabled={this.state.createNew}
                    >
                      <span className="btn-inner--icon">
                        {keys.ICON_CUSTOMER}
                      </span>
                      <span className="btn-inner--text">
                        {this.props.t("subscriptionAdmin.createNew")}
                      </span>
                    </Button>
                  </Form>
                  <ResultContainer
                    items={itemsFound}
                    onSelect={this.onSelectedItem}
                  />
                  <div className="mt-4">
                    {this.state.selectedSubscription != null
                      ? this.renderSubscription()
                      : null}
                  </div>
                  <div className="mt-4">
                    {this.state.createNew ? this.renderCreateNew() : null}
                  </div>
                  {this.state.error && (
                    <Col className="mt-3 mb-5 mb-xl-0" xl="12">
                      <span className="mr-1">
                        C'è stato un problema, per favore salva questi dati e
                        inviali al team di supporto tecnico.
                      </span>
                      <br />
                      <br />
                      {JSON.stringify(this.state.error)}
                    </Col>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation("common")(SubscriptionAdmin);
