import React from "react";

// reactstrap components
import { Card, Container, Col, Row } from "reactstrap";

import Plant from "../components/Plant";
import Loader from "../components/Loader";
import ConnectedDevice from "../components/ConnectedDevice";
import { apiAction, setControllers } from "../redux/actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import api from "services/backendService";
import utils from "utils";

class Devices extends React.Component {
  componentDidMount() {
    const newSiteGuid = this.props.location.site.siteGuid;
    if (
      this.props.store.controllers.length === 0 ||
      this.props.store.controllers[0].site.siteGuid !== newSiteGuid
    ) {
      this.props.apiAction(
        api.getDevicesOnSite,
        [this.props.location.site.siteGuid],
        setControllers,
        true
      );
    }

    const { gateways } = this.props.store;

    for (let gatewayGuid of gateways.allIds) {
      if (
        gateways.byId[gatewayGuid].status !== "OFF" &&
        gateways.byId[gatewayGuid].site.siteGuid === newSiteGuid
      ) {
        api.refreshVars(gatewayGuid);
      }
    }
  }

  render() {
    if (this.props.store.apiFetching) return <Loader />;
    const siteInfo = this.props.location.site;
    if (!siteInfo) {
      return "";
    }
    const controllers = this.props.store.controllers;

    const gatewayList = utils.getGatewaysForSite(
      this.props.location.site.siteGuid,
      this.props.store.gateways.byId
    );

    return (
      <>
        <Container fluid className="mt--9">
          <Row>
            <Col className="mb-5" lg="9">
              <Card className="pt-0 pb-0 pl-4 pr-4">
                {controllers.map((k) => {
                  let controllerStatus = this.props.store.alerts.byId[
                    k.gateway.gatewayGuid
                  ]
                    ? this.props.store.alerts.byId[k.gateway.gatewayGuid][
                        k.deviceGuid
                      ]
                      ? this.props.store.alerts.byId[k.gateway.gatewayGuid][
                          k.deviceGuid
                        ].length
                        ? "alarm"
                        : "ok"
                      : "ok"
                    : "ok";
                  return (
                    <ConnectedDevice
                      key={k.deviceGuid}
                      guid={k.deviceGuid}
                      name={k.model}
                      siteGuid={k.site.siteGuid}
                      address={k.address}
                      points={k.pointsById || []}
                      status={controllerStatus}
                      siteInfo={this.props.location.site}
                      gatewayInfo={
                        this.props.store.gateways.byId[k.gateway.gatewayGuid]
                      }
                      controllers={this.props.store.devices}
                      friendlyName={
                        /* k.friendlyName === undefined || */ k.friendlyName ===
                          null || k.friendlyName === ""
                          ? null
                          : k.friendlyName
                      }
                      //description={k.description}
                    />
                  );
                })}
              </Card>
            </Col>
            <Col lg="3">
              <Plant
                siteGuid={siteInfo.siteGuid}
                gatewayList={gatewayList}
                name={siteInfo.name}
                city={siteInfo.city}
                country={siteInfo.country}
                connectivitytype={siteInfo.connectivitytype}
                latitude={siteInfo.latitude}
                longitude={siteInfo.longitude}
                location={this.props.location}
                stillLoading={this.props.store.gateways.fromDashboard}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: {
      controllers: Object.values(state.controllers.byId),
      apiFetching: state.api.isFetching,
      apiError: state.api.error,
      alerts: state.alerts,
      gateways: state.gateways,
      devices: state.controllers,
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      apiAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
