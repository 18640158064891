import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { renderToStaticMarkup } from "react-dom/server";
import {
  MapContainer as LeafletMap,
  TileLayer,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import { divIcon } from "leaflet";
import utils from "utils";

import keys from "configs/constants";

import "./site.css";

function SensitiveMarker(props) {
  const popupContent = props.popupInfo;
  const pos = props.position;
  const k = props.id;

  let alarms = [];
  let offlineGateways = [];

  if (props.alarms !== undefined) {
    alarms = props.alarms;
  }

  if (props.offlineGateways !== undefined) {
    offlineGateways = props.offlineGateways;
  }

  let icn =
    props.totalGateways.length === 0
      ? keys.ICON_MAP_MARKER_EMPTY_SITE
      : alarms.length === 0
      ? offlineGateways.length === 0
        ? utils.addClassToExistingElement(
            keys.ICON_MAP_MARKER_DEFAULT,
            "site-ok"
          )
        : offlineGateways.length === props.totalGateways.length
        ? utils.addClassToExistingElement(
            keys.ICON_MAP_MARKER_DEFAULT,
            "site-offline"
          )
        : utils.addClassToExistingElement(
            keys.ICON_MAP_MARKER_DEFAULT,
            "site-warn"
          )
      : utils.addClassToExistingElement(
          keys.ICON_MAP_MARKER_DEFAULT,
          "site-alarm"
        );

  const markerIcon = divIcon({
    html: renderToStaticMarkup(icn),
  });

  if (popupContent) {
    return (
      <Marker
        key={k}
        position={pos}
        icon={markerIcon}
        zIndexOffset={alarms.length > 0 ? 1000 : 0}
        opacity={
          props.totalGateways &&
          offlineGateways.length === props.totalGateways.length &&
          alarms.length === 0
            ? 0.3
            : 1
        }
      >
        <Popup>{popupContent}</Popup>
      </Marker>
    );
  } else {
    return <Marker key={k} position={pos} icon={markerIcon} />;
  }
}

function MarkerMove({ lat, lng }) {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng], map.getZoom());
  }, [lat, lng]);
  return null
}

class OpenStreetMap extends React.Component {
  state = {
    markers: this.props.markers,
  };

  refmarker = React.createRef();

  handleDragEnd = () => {
    if (this.props.onDragEnd) {
      const userMarker = this.refmarker.current;
      if (userMarker != null) {
        const { lat, lng } = userMarker.getLatLng();
        this.props.onDragEnd({ lat, lng });
      }
    }
  }

  render() {
    const dixellPos = [46.144956, 12.324872]; // Dixell Position as default center if not provided
    const pos = this.props.center || dixellPos;

    return (
      <LeafletMap
        center={pos}
        zoom={this.props.zoom || 8}
        className="leaflet-dashboard-map"
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {this.props.noHQ ? (
          <Marker
            key={this.props.markers[0].siteGuid}
            position={[
              this.props.markers[0].latitude,
              this.props.markers[0].longitude,
            ]}
            draggable={true}
            ref={this.refmarker}
            eventHandlers={{
              dragend: this.handleDragEnd
            }}
          />
        ) : null}
        {this.props.noHQ
          ? ""
          : this.props.markers.map((storeInfo) => {
              if (storeInfo.longitude && storeInfo.latitude) {
                const pop =
                  storeInfo.gateways.length > 0 ? (
                    <Link
                      to={{
                        pathname: "/std/dvcs",
                        site: storeInfo,
                      }}
                    >
                      <div>{storeInfo.name}</div>
                    </Link>
                  ) : (
                    <div>{storeInfo.name}</div>
                  );
                return (
                  <SensitiveMarker
                    key={storeInfo.siteGuid}
                    id={storeInfo.siteGuid}
                    position={[storeInfo.latitude, storeInfo.longitude]}
                    icontType={storeInfo.connectivitytype}
                    popupInfo={pop}
                    alarms={storeInfo.alarms}
                    totalGateways={storeInfo.gateways}
                    offlineGateways={storeInfo.offlineGateways}
                  />
                );
              } else return "";
            })}
        <MarkerMove lat={this.props.center[0]} lng={this.props.center[1]} />
      </LeafletMap>
    );
  }
}

OpenStreetMap.defaultProps = {
  center: null,
};

export default OpenStreetMap;
