import React from "react";
import { Collapse, Table, Button } from "reactstrap";
import XVOButtonGroup from "components/admin/XVOButtonGroup";
import keys from "../../configs/constants";
import api from "../../services/backendService";
import { withTranslation } from "react-i18next";

class AdminSitesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  onPermLevelChange(level) {
    this.props.onSitePermLevelChange(level);
  }

  onUpdateSitePerm() {
    this.props.onUpdatePerm();
  }

  onForgetChanges() {
    this.props.onForgetChanges();
  }

  renderRows() {
    const t = this;
    if (this.props.userSites.length > 0) {
      return this.props.userSites.map((s, idx) => {
        const site = s.site;
        const sGuid = site.siteGuid;
        return (
          <tr key={idx}>
            <td>
              <XVOButtonGroup
                level={
                  t.props.levels[sGuid]
                    ? t.props.levels[sGuid]
                    : s.user !== undefined
                    ? s.level
                    : "None"
                }
                guid={sGuid}
                onPermLevelChange={this.onPermLevelChange.bind(this)}
              />
            </td>
            <td>{site.name}</td>
            <td>{site.city}</td>
            <td>{site.country}</td>
            <td>{site.gateways.length}</td>
            <td>
              {site.customer.name}
              <span
                className={`${
                  s.user !== undefined ? "icon-delete" : "icon-delete-disabled"
                } float-right`}
                onClick={() => {
                  if (s.user !== undefined) {
                    api
                      .deletePermissionSite(sGuid, s.user.userGuid)
                      .then(() => {
                        this.props.onUpdatePerm();
                      });
                  }
                }}
              >
                {keys.ICON_TRASH}
              </span>
            </td>
          </tr>
        );
      });
    } else return;
  }

  toggle() {
    const newOpenState = !this.state.isOpen;
    this.setState({ isOpen: newOpenState });
  }

  render() {
    return (
      <div className="border" color="primary">
        <Button
          color="primary"
          onClick={this.toggle.bind(this)}
          style={{ marginBottom: "1rem" }}
          className="ml-2 mt-3"
        >
          {(this.state.isOpen
            ? this.props.t("adminSitesTable.collapse")
            : this.props.t("adminSitesTable.expand")) +
            " (" +
            this.props.userSites.length +
            ")"}
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: "1rem" }}
          className="ml-2 mt-3"
          onClick={this.onUpdateSitePerm.bind(this)}
          disabled={Object.keys(this.props.levels).length === 0}
        >
          {this.props.t("adminSitesTable.updateSitePermission")}
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: "1rem" }}
          className="ml-2 mt-3"
          onClick={this.onForgetChanges.bind(this)}
          disabled={Object.keys(this.props.levels).length === 0}
        >
          {this.props.t("adminSitesTable.forgetChanges")}
        </Button>

        <Collapse isOpen={this.state.isOpen}>
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  {this.props.t("adminSitesTable.permission")}
                </th>
                <th scope="col">{this.props.t("adminSitesTable.name")}</th>
                <th scope="col">{this.props.t("adminSitesTable.city")}</th>
                <th scope="col">{this.props.t("adminSitesTable.country")}</th>
                <th scope="col">
                  {this.props.t("adminSitesTable.gatewaysNumber")}
                </th>
                <th scope="col">{this.props.t("adminSitesTable.customer")}</th>
              </tr>
            </thead>
            <tbody>{this.renderRows()}</tbody>
          </Table>
        </Collapse>
      </div>
    );
  }
}

export default withTranslation("common")(AdminSitesTable);
