import { SET_PERMISSIONS, EDIT_PERMISSION } from "../actions/index";

import utils from "../../utils";

const initialData = {
  customers: { byId: {}, allIds: [] },
  sites: { byId: {}, allIds: [] },
  gateways: { byId: {}, allIds: [] },
  isLoading: true
};

const permissions = (state = initialData, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return { ...action.payload, isLoading: false };
    case EDIT_PERMISSION:
      const { entity, entityGuid, userGuid, level } = action.payload;

      const permissionEntity = "permission" + utils.capitalize(entity);

      let newState = state;

      for (let user of newState[entity].byId[entityGuid][permissionEntity]) {
        if (user.user.userGuid === userGuid) {
          user.level = level;
        }
      }

      return newState;

    default:
      return state;
  }
};

export default permissions;
