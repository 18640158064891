import { RESET_SITES, SET_SITES, SITES_LOADED, SITE_UPDATE } from "../actions/index";

const initialData = { byId: {}, allIds: [] };

const sites = (state = initialData, action) => {
  switch (action.type) {
    case SET_SITES:
      return action.payload;
    case RESET_SITES:
      return initialData;
    case SITE_UPDATE:
      let update = {
        [action.payload.siteGuid]: {
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
          name: action.payload.name,
          city: action.payload.city,
          country: action.payload.country,
          createdAt: action.payload.createdAt,
          modifiedAt: action.payload.modifiedAt,
          customer: action.payload.customer,
          deleteAt: action.payload.deleteAt,
          gateways: action.payload.gateways,
          siteGuid: action.payload.siteGuid,
        },
      };
      return { ...state, byId: update };
    default:
      return state;
  }
};

export const sitesLoaded = (state = false, action) => {
  switch (action.type) {
    case SITES_LOADED:
      return action.payload;
    default:
      return state;
  }
};

export default sites;
