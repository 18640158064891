import { combineReducers } from "redux";
import signalR from "./signalR";
import api from "./api";
import sites, { sitesLoaded } from "./sites";
import gateways from "./gateways";
import controllers, { controllersLoaded } from "./controllers";
import alerts from "./alerts";
import wna from "./website";
import user from "./user";
import permissions from "./permissions";
import parametersInfo from "./parametersInfo";
import translations from "./translations";

export default combineReducers({
  signalR,
  api,
  sites,
  gateways,
  controllers,
  alerts,
  sitesLoaded,
  controllersLoaded,
  wna,
  user,
  permissions,
  parametersInfo,
  translations,
});
