import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";

import { withTranslation } from "react-i18next";

import Loader from "components/Loader";
import utils from "../../utils";
import backendService from "../../services/backendService";
import keys from "../../configs/constants";

const InvalidParams = (props) => {
    const { title, from, status, email } = props;
    document.title = title !== undefined ? 'Connected App -- ' + title : 'Connected App -- Link not valid';
    // -1: Nothing in progress, 0: loading, 1: success, 2: error
    const [actionState, setActionState] = useState(-1);

    const showResendVerification = from === "verifyemail" && (status === 403 || status === 500);

    const resendLink = () => {
        setActionState(0);
        backendService.callMappAPI("POST", `users/verify`, { email }).then((response) => { utils.debug(response.responseString); setActionState(1) }).catch((error) => { utils.debug(error.responseString); setActionState(error.status) });
    };

    return (
        <>
            <div className="pt-4 justify-content-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl="6">
                            <Row className="justify-content-center">
                                <h3 className="justify-content-center">
                                    {props.t("generic.ouch")}!
                                </h3>
                            </Row>
                            <Row className="text-center mt-4 p-2">{props.t("generic.somethingWentWrongWithLink")}<br /><br />{!showResendVerification ? props.t("generic.askForNewLink") : null}</Row>
                            <Row className="justify-content-center text-center">{showResendVerification ?
                                <>
                                    <Button
                                        className="btn-icon"
                                        color="primary"
                                        type="button"
                                        onClick={resendLink}
                                    >
                                        <span className="btn-inner--icon">
                                            {keys.ICON_PAPER_PLANE}
                                        </span>
                                        <span className="btn-inner--text">
                                            {props.t('generic.resendVerification')}
                                        </span>
                                    </Button>
                                    {actionState === 1 ? <div className="pt-2 pl-2 text-success">
                                        {keys.ICON_OK}
                                        <span className="pl-2">{props.t('generic.emailSent')}</span>
                                    </div> : null}
                                </>
                                : null}
                            </Row>
                            <Row className="justify-content-center mt-4 p-2">
                                {actionState === 0 ? <Loader custom={true} size={64} /> : null}
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    );
};

export default withTranslation("common")(InvalidParams);