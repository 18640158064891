/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import Standard from "layouts/Standard";
import ThingAuth from "layouts/ThingAuth";
import Admin from "layouts/Admin";
import ConnectedApp from "layouts/ConnectedApp/ConnectedApp.jsx";

import { withADB2CAuth } from "services/azureADB2C";

import { Provider } from "react-redux";
import { legacy_createStore as createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./redux/reducers/index.js";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { selectLanguage } from "translation/index.js";
import Banner from "../src/components/Banner/banner";
import { AuthorizationContextProvider } from "./contexts/AuthorizationContext";

class RootApp extends Component {
  render() {
    var t = this;

    selectLanguage();
    return (
      <>
        <ReactNotifications />
        <I18nextProvider i18n={i18next}>
          <Provider store={store}>
            <BrowserRouter>
              <Switch>
                <Route
                  path="/std"
                  render={(props) => (
                    <Standard
                      {...props}
                      {...t.props}
                      selectLanguage={selectLanguage}
                    />
                  )}
                />
                <Route
                  path="/ad"
                  render={(props) => (
                    <Admin
                      {...props}
                      {...t.props}
                      selectLanguage={selectLanguage}
                    />
                  )}
                />
                <Route
                  path="/tr"
                  render={(props) => (
                    <ThingAuth
                      {...props}
                      {...t.props}
                      selectLanguage={selectLanguage}
                    />
                  )}
                />
                <Route
                  path="/capp"
                  render={(props) => (
                    <ConnectedApp
                      {...props}
                      {...t.props}
                      selectLanguage={selectLanguage}
                    />
                  )}
                />
                <Redirect from="/" to="/std/index" />
              </Switch>
            </BrowserRouter>
            <Banner />
          </Provider>
        </I18nextProvider>
      </>
    );
  }
}

const middlewareEnhancer = applyMiddleware(thunk);

const composeWithDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancers = composeWithDevTools(middlewareEnhancer);

export const store = createStore(rootReducer, composedEnhancers);

export const App = withADB2CAuth(RootApp);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <AuthorizationContextProvider>
    <App />
  </AuthorizationContextProvider>
);
