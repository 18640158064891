import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";

import Loader from "../../components/Loader";
import OperationSuccess from "./OperationSuccess";
import InvalidParams from "./InvalidParams";

import backendService from "../../services/backendService";

const VerifyEmail = (props) => {

    document.title = 'Connected App -- Email Verification';

    const { token, user, email } = props;
    // 0: loading, 1: success, 2: error
    const [actionState, setActionState] = useState(0);

    useEffect(() => {
        backendService.callMappAPI("POST", `users/${user}/verify`, { token }).then(() => { setActionState(1) }).catch((error) => { setActionState(error.status) });
    }, [token, user]);

    if (actionState === 1) {
        // All good!!!
        return (
            <><OperationSuccess /></>
        )
    }

    if (actionState > 1) {
        // There is an error
        return (
            <><InvalidParams title="Verification Error" from="verifyemail" status={actionState} email={email} /></>
        )
    }

    return (
        <>
            <div className="pt-4 justify-content-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl="6">
                            <Row className="justify-content-center">
                                <h3 className="justify-content-center">
                                    {props.t("generic.hello")}!
                                </h3>
                            </Row>
                            <Row className="justify-content-center text-center mt-4 p-2">{props.t("generic.verifyingEmail")}</Row>
                            <Row className="justify-content-center mt-4 p-2">{actionState === 0 ? <Loader custom={true} size={64} /> : null}</Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    );
};

export default withTranslation("common")(VerifyEmail);