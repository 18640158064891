import React, { useState } from "react";
import { Container, Row, Col, Button, Input, Modal } from "reactstrap";

import { withTranslation } from "react-i18next";

import Loader from "../../components/Loader";
import OperationSuccess from "./OperationSuccess";
import keys from "../../configs/constants";

import backendService from "../../services/backendService";
import InvalidParams from "./InvalidParams";

const policies = {
    OWNER: { key: "policyOwner", value: "Owner", hint: "policyOwnerHint" },
    SERVICE: { key: "policyService", value: "Service", hint: "policyServiceHint" },
    USER: { key: "policyUser", value: "User", hint: "policyUserHint" }
}

const UpdatePolicy = (props) => {

    document.title = 'Connected App -- Access Policy Update';

    const { token, email, policy } = props;
    const [showModal, setShowModal] = useState(false);
    const [permission, setPermission] = useState(policies["OWNER"].value); // Default Value to be shown
    // -1: Nothing in progress, 0: loading, 1: success, 2: error
    const [actionState, setActionState] = useState(-1);

    const renderPolicy = () => {
        return Object.keys(policies).map((key, index) => {
            return (
                <option key={index} value={policies[key].value}>
                    {props.t("policies." + policies[key].key) + " - " + props.t("policies." + policies[key].hint)}
                </option>
            );
        });
    };

    const denyAccess = async () => {
        setActionState(0);
        try {
            await backendService.callMappAPI("DELETE", `policies/${policy}`, { token });
            setActionState(1);
        } catch (error) {
            setActionState(2);
        }
    };

    const updatePolicy = async () => {
        setActionState(0);
        const permissionLevel = permission;
        try {
            await backendService.callMappAPI("PATCH", `policies/${policy}`, { token, permissionLevel });

            setActionState(1);
        } catch (error) {
            setActionState(2);
        }
    };


    if (actionState === 1) {
        return (<OperationSuccess />);
    };

    if (actionState === 2) {
        return (<InvalidParams />);
    };

    return (
        <>
            <div className="pt-4 justify-content-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl="6">
                            <Row className="justify-content-center">
                                <h3 className="justify-content-center">
                                    {props.t("registerThing.welcome")}!
                                </h3>
                            </Row>
                            <Row className="justify-content-center mt-4">{props.t("generic.policyApplyMessage")}</Row>
                            {email ? (<Row className="justify-content-center mt-4"><h4>{email}</h4></Row>) : null}
                            <Row className="justify-content-center mt-3">{props.t("generic.allowAccessAs")}:</Row>
                            <Row className="mt-1 pl-4 pr-4">
                                <Input
                                    type="select"
                                    defaultValue={permission}
                                    onChange={(e) => setPermission(e.target.value)}
                                >
                                    {renderPolicy()}
                                </Input>
                            </Row>
                            <Row className="justify-content-center mt-4 p-2">{actionState === 0 ? <Loader custom={true} size={64} /> : null}</Row>
                            <Row className="justify-content-around">
                                <Button
                                    className="btn-icon mt-4"
                                    color="danger"
                                    type="button"
                                    onClick={() => setShowModal(true)}
                                >
                                    <span className="btn-inner--icon">
                                        {keys.ICON_TRASH}
                                    </span>
                                    <span className="btn-inner--text">
                                        {props.t('generic.deny')}
                                    </span>
                                </Button>
                                <Button
                                    className="btn-icon mt-4"
                                    color="primary"
                                    type="button"
                                    onClick={updatePolicy}
                                >
                                    <span className="btn-inner--icon">
                                        {keys.ICON_OK}
                                    </span>
                                    <span className="btn-inner--text">
                                        {props.t('generic.apply')}
                                    </span>
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Container >
                <Modal
                    isOpen={showModal}
                    className="modal-alerts"
                >
                    <div className="modal-header bg-danger">
                        <h3 className="modal-title text-secondary">{props.t("generic.warning")} !</h3>
                    </div>
                    <div className="modal-body">{props.t("generic.areYouSure")}<br /><br />{props.t("generic.denyAccessConfirmationMessage")}</div>
                    <div className="mt--5 modal-footer">
                        <Button
                            className="btn-icon mt-4"
                            type="button"
                            onClick={() => setShowModal(false)}
                        >
                            <span className="btn-inner--icon">
                                {keys.ICON_OK}
                            </span>
                            <span className="btn-inner--text">
                                {props.t('generic.cancel')}
                            </span>
                        </Button>
                        <Button
                            className="btn-icon mt-4"
                            color="danger"
                            type="button"
                            onClick={denyAccess}
                        >
                            <span className="btn-inner--icon">
                                {keys.ICON_TRASH}
                            </span>
                            <span className="btn-inner--text">
                                {props.t('generic.deny')}
                            </span>
                        </Button>
                    </div>
                </Modal>
            </div >
        </>
    );
};

export default withTranslation("common")(UpdatePolicy);