import React from "react";
import { Container, Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";

const OperationSuccess = (props) => {
    const { title } = props;
    document.title = title !== undefined ? 'Connected App -- ' + title : 'Connected App -- Success!';

    return (
        <>
            <div className="pt-4 justify-content-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl="6">
                            <Row className="justify-content-center">
                                <h3 className="justify-content-center">
                                    {props.t('generic.completedSuccess')} !
                                </h3>
                            </Row>
                            <Row className="justify-content-center text-center mt-4 p-2">{props.t("generic.safelyClosePage")}</Row>
                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    );
};

export default withTranslation("common")(OperationSuccess);