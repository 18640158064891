import React from "react";

import { withTranslation } from "react-i18next";
import queryString from "query-string";
import InvalidParams from "./InvalidParams";
import UpdatePolicy from "./UpdatePolicy";
import VerifyEmail from "./VerifyEmail";
import PasswordReset from "./PasswordReset";

const action = {
  VERIFY_EMAIL: "verifyemail",
  RESET_PASSWORD: "resetpassword",
  UPDATE_POLICY: "updatepolicy"
}

const ConnectedApp = (props) => {
  document.title = 'Connected App';

  const queryParams = queryString.parse(props.location.search);

  if (queryParams.action !== undefined && queryParams.token !== undefined) {
    switch (queryParams.action) {
      case action.VERIFY_EMAIL:
        // Verify Email
        return (
          <>
            <VerifyEmail {...queryParams} />
          </>
        );
      case action.RESET_PASSWORD:
        // Reset Password
        return (
          <>
            <PasswordReset {...queryParams} />
          </>
        );
      case action.UPDATE_POLICY:
        if (queryParams.policy) {
          // Update Policy
          return (
            <>
              <UpdatePolicy {...queryParams} />
            </>
          );
        } else {
          // Missing required parameters, should redirect to main login page
          return (
            <InvalidParams />
          );
        }
      default:
        // Missing required parameters, should redirect to main login page
        return (
          <InvalidParams />
        );
    }
  }
  else {
    // Missing required parameters, should redirect to main login page
    return (
      <InvalidParams />
    );
  }

};

export default withTranslation("common")(ConnectedApp);