import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  Form,
  Input,
  Button,
  Modal,
  ModalBody,
  Collapse,
} from "reactstrap";

import SelectSearch, { fuzzySearch } from "react-select-search";

import utils from "utils";
import api from "services/backendService";
import AdminSitesTable from "components/admin/AdminSitesTable";
import AdminGatewayTable from "components/admin/GatewayTable";
import { PuffLoader } from "react-spinners";
import Loader from "components/Loader";
import AdminCustomerTable from "components/admin/AdminCustomerTable";
import { withTranslation } from "react-i18next";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "components/Notify";

class UsersAdmin extends React.Component {
  state = {
    selectedGuid: "",
    dataOptions: [],
    response: null,
    errorState: "",
    total: "",
    hardDelete: true,
    userSites: [],
    siteLevels: {},
    gwLevels: {},
    userGateways: [],
    userRole: "",
    updateUserLoader: false,
    showFullLoader: false,
    customerLevels: {},
    userCustomers: [],
    userAttribute: [],
    showAttributes: false,
    enableConfirmAttributeChange: false,
    showConfirmAttributeModal: false,
  };

  async componentDidMount() {
    const pload = await api.getUsersDetails(null, true);
    this.setState({
      dataOptions: pload.data.data,
      total: pload.data.total,
    });
  }

  onInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  onCustomerPermLevelChange(level) {
    utils.debug("State Updated for Customer level: " + JSON.stringify(level));
    const id = level.id;
    const value = level.value;
    this.setState((prev) => ({
      customerLevels: {
        ...prev.customerLevels,
        [id]: value,
      },
    }));
  }

  onSitePermLevelChange(level) {
    utils.debug("State Updated for Site level: " + JSON.stringify(level));
    const id = level.id;
    const value = level.value;
    this.setState((prev) => ({
      siteLevels: {
        ...prev.siteLevels,
        [id]: value,
      },
    }));
  }

  onGWPermLevelChange(level) {
    utils.debug("State Updated for Gateway level: " + JSON.stringify(level));
    const id = level.id;
    const value = level.value;
    this.setState((prev) => ({
      gwLevels: {
        ...prev.gwLevels,
        [id]: value,
      },
    }));
  }

  onCustomerForgetChanges() {
    this.setState({ customerLevels: {} });
  }

  onSiteForgetChanges() {
    this.setState({ siteLevels: {} });
  }

  onGWForgetChanges() {
    this.setState({ gwLevels: {} });
  }

  async reloadData(pl) {
    if (!pl.message && (pl.status === 200 || pl.status === 201)) {
      const pload = await api.getUsersDetails(null, true);
      if (pload.message === 500) {
        this.setState({
          dataOptions: pl.data.data,
          response: "Internal Server Error",
          errorState: "Error",
        });
        return;
      }
      this.setState({
        dataOptions: pload.data.data,
        total: pload.data.total,
        response: pl,
        errorState: "OK",
        siteLevels: {},
        gwLevels: {},
        customerLevels: {},
      });
    } else this.setState({ response: pl.message, errorState: "Error" });
  }

  async onCustomerUpdateData() {
    const t = this;
    this.setState({ showFullLoader: true });
    let permArray = [];
    for (const lvl of Object.entries(this.state.customerLevels)) {
      const cGUID = lvl[0];
      const levelValue = lvl[1];
      permArray.push({
        customerGuid: cGUID,
        userGuid: t.state.selectedGuid,
        level: levelValue,
      });
    }
    await api.updatePermissionCustomer(permArray);
    await this.onDataSelected(this.state.idDataOptionsArray);
    this.setState({ showFullLoader: false, customerLevels: {} });
  }

  async onSiteUpdateData() {
    const t = this;
    this.setState({ showFullLoader: true });
    let permArray = [];
    for (const lvl of Object.entries(this.state.siteLevels)) {
      const sGUID = lvl[0];
      const levelValue = lvl[1];
      permArray.push({
        siteGuid: sGUID,
        userGuid: t.state.selectedGuid,
        level: levelValue,
      });
    }
    await api.updatePermissionSite(permArray);
    await this.onDataSelected(this.state.idDataOptionsArray);
    this.setState({ showFullLoader: false, siteLevels: {} });
  }

  async onGWUpdateData() {
    const t = this;
    this.setState({ showFullLoader: true });
    let permArray = [];
    for (const lvl of Object.entries(this.state.gwLevels)) {
      const gwGUID = lvl[0];
      const levelValue = lvl[1];
      permArray.push({
        gatewayGuid: gwGUID,
        userGuid: t.state.selectedGuid,
        level: levelValue,
      });
    }
    await api.updatePermissionGateway(permArray);
    await this.onDataSelected(this.state.idDataOptionsArray);
    this.setState({ showFullLoader: false, gwLevels: {} });
  }

  async deleteData() {
    const pload = await api.deleteUser(
      this.state.selectedGuid,
      this.state.hardDelete
    );
    await this.reloadData(pload);
  }

  async onRoleSelected(role) {
    this.setState({ updateUserLoader: true });
    await api.updateUserRole(this.state.selectedGuid, role);
    this.setState({ updateUserLoader: false });
  }

  async onDataSelected(idx) {
    this.setState({ showFullLoader: true });
    const userInfo = this.state.dataOptions[idx];
    const selectedUserGuid = userInfo.userGuid;
    const allCustomers = (await api.getCustomersDetails()).data.data;
    const allSites = (await api.getSitesDetails()).data.data;
    const allGateways = (await api.getGatewayDetails()).data.data;

    utils.debug(
      "Selected user: " + userInfo.userGuid + " role: " + userInfo.role
    );

    let fullPermCustomers = (
      await api.getPermissionCustomer(null, selectedUserGuid)
    ).data.data;

    for (const c of allCustomers) {
      let found = false;
      for (const cp of fullPermCustomers) {
        if (cp.customer.customerGuid === c.customerGuid) {
          found = true;
          break;
        }
      }
      if (!found) {
        let emptyPerm = {
          customer: {
            customerGuid: c.customerGuid,
            name: c.name,
            sites: c.sites,
          },
          level: "Unauthorized",
        };
        fullPermCustomers.push(emptyPerm);
      }
    }

    let fullPermSites = (await api.getPermissionSite(null, selectedUserGuid))
      .data.data;

    for (const s of allSites) {
      let found = false;
      for (const sp of fullPermSites) {
        if (sp.site.siteGuid === s.siteGuid) {
          found = true;
          break;
        }
      }
      if (!found) {
        let emptyPerm = {
          site: {
            siteGuid: s.siteGuid,
            name: s.name,
            city: s.city,
            country: s.country,
            customer: { name: s.customer.name },
            gateways: s.gateways,
          },
          level: "Unauthorized",
        };
        fullPermSites.push(emptyPerm);
      }
    }

    let fullPermGateways = (
      await api.getPermissionGateway(null, selectedUserGuid)
    ).data.data;

    for (const g of allGateways) {
      let found = false;
      for (let gp of fullPermGateways) {
        if (gp.gateway.gatewayGuid === g.gatewayGuid) {
          found = true;
          gp.gateway.status = g.status;
          break;
        }
      }

      if (!found) {
        let emptyPerm = {
          gateway: {
            gatewayGuid: g.gatewayGuid,
            name: g.name,
            site: { name: g.site.name },
            customer: { name: g.customer.name },
            macAddress: g.macAddress,
            telemetryPushInterval: g.telemetryPushInterval,
            devices: g.devices,
            status: g.status,
          },
          level: "Unauthorized",
        };
        fullPermGateways.push(emptyPerm);
      }
    }

    this.setState({
      selectedGuid: selectedUserGuid,
      email: userInfo.email,
      username: userInfo.username,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      lastLoginAt: userInfo.lastLoginAt,
      createdAt: userInfo.createdAt,
      modifiedAt: userInfo.modifiedAt,
      deletedAt: userInfo.deletedAt,
      userSites: fullPermSites,
      userGateways: fullPermGateways,
      idDataOptionsArray: idx,
      siteLevels: {},
      gwLevels: {},
      userRole: userInfo.role,
      showFullLoader: false,
      userCustomers: fullPermCustomers,
      userAttribute: JSON.parse(JSON.stringify(userInfo.userAttribute)),
    });
  }

  applyChangesOnAttributes = async () => {
    this.setState({ updateUserLoader: true });
    let { userAttribute, idDataOptionsArray } = this.state;
    const { userGuid } = this.state.dataOptions[idDataOptionsArray];
    let data = null;

    for (let attribute of userAttribute) {
      data = await api.editUserAttribute(
        userGuid,
        attribute.key,
        attribute.remove ? null : attribute.value
      );
    }

    this.setState({ updateUserLoader: false });

    notifyStore.addNotification({
      ...tempOptions,
      container: "center",
      content: NotifyContent(
        data.status && data.status === 201 ? "success" : "danger",
        null,
        this.props.t(
          data.status && data.status === 201
            ? "sidebar.changesApplied"
            : "sidebar.changesNotApplied"
        )
      ),
    });

    return data.status && data.status === 201;
  };

  formToRender() {
    if (this.state.selectedGuid) {
      const roleOptions = [
        { label: "Guest", value: "Guest", name: "Guest" },
        { label: "User", value: "User", name: "User" },
        { label: "Admin", value: "Admin", name: "Admin" },
      ];
      return (
        <>
          <Form role="form">
            <div className="text-center">
              <strong>{this.props.t("usersAdmin.userInfo")}</strong>
              <br />
              <br />
            </div>
            <Container fluid className="mt-4">
              <Row className="justify-content-md-center">
                <Col>
                  <b>{this.props.t("usersAdmin.lastLogin") + ": "}</b>
                  {this.state.lastLoginAt
                    ? new Date(this.state.lastLoginAt).toLocaleString()
                    : this.props.t("usersAdmin.never")}
                </Col>
                <Col>
                  <b>{this.props.t("usersAdmin.deletedAt") + ": "}</b>
                  {this.state.deletedAt
                    ? new Date(this.state.deletedAt).toLocaleString()
                    : this.props.t("usersAdmin.currentlyActive")}
                </Col>
              </Row>
              <br />
              <Row className="justify-content-md-center">
                <Col>
                  <b>{this.props.t("usersAdmin.createdAt") + ": "}</b>
                  {new Date(this.state.createdAt).toLocaleString()}
                </Col>
                <Col>
                  <b>{this.props.t("usersAdmin.modifiedAt") + ": "}</b>
                  {new Date(this.state.modifiedAt).toLocaleString()}
                </Col>
              </Row>
              <br />
              <Row className="justify-content-md-center">
                <Col>
                  <b>{this.props.t("usersAdmin.userId") + ": "}</b>
                  {this.state.lastLoginAt ? this.state.selectedGuid : " - "}
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <b>{this.props.t("usersAdmin.currentRole") + ": "}</b>
                    </Col>
                    <Col>
                      <div className="mt--1 ml--2">
                        <SelectSearch
                          filterOptions={fuzzySearch}
                          options={roleOptions}
                          name="role"
                          placeholder={
                            this.props.t("usersAdmin.selectRole") + "..."
                          }
                          onChange={this.onRoleSelected.bind(this)}
                          value={this.state.userRole}
                        />
                      </div>
                    </Col>
                    <Col>
                      {this.state.updateUserLoader ? (
                        <PuffLoader color="#be97e8" size={40} />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <div className="mt-4 ml-4">
              <Button
                className="mb-2"
                color="primary"
                onClick={() => {
                  this.setState({ showAttributes: !this.state.showAttributes });
                }}
              >
                {this.props.t("usersAdmin.userAttributes")}
              </Button>
              <Collapse isOpen={this.state.showAttributes}>
                <table className="ml-5">
                  <tbody>
                    {this.state.userAttribute.map((attr, i) => {
                      return (
                        <tr key={i}>
                          <th className="font-weight-bold pr-2">
                            <Input
                              className={`${
                                attr.remove
                                  ? "border-danger"
                                  : !attr.new
                                  ? "border-success"
                                  : "border-primary"
                              }`}
                              value={attr.key || ""}
                              placeholder={this.props.t("usersAdmin.attribute")}
                              onChange={(e) => {
                                let { userAttribute } = this.state;
                                userAttribute[i].key = e.target.value;
                                userAttribute[i].new = true;
                                this.setState({
                                  userAttribute,
                                  enableConfirmAttributeChange: true,
                                });
                              }}
                            />
                          </th>
                          <td>
                            <Input
                              value={attr.value || ""}
                              placeholder={this.props.t("usersAdmin.value")}
                              onChange={(e) => {
                                let { userAttribute } = this.state;
                                userAttribute[i].value = e.target.value;
                                userAttribute[i].new = true;

                                if (
                                  !e.target.value ||
                                  e.target.value.length === 0
                                ) {
                                  userAttribute[i].remove = true;
                                } else {
                                  userAttribute[i].remove = undefined;
                                }

                                this.setState({
                                  userAttribute,
                                  enableConfirmAttributeChange: true,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => {
                                let { userAttribute } = this.state;

                                userAttribute[i].remove =
                                  userAttribute[i].remove === undefined
                                    ? true
                                    : undefined;

                                this.setState({
                                  userAttribute,
                                  enableConfirmAttributeChange: true,
                                });
                              }}
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="mt-2">
                  <Button
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      let { userAttribute } = this.state;
                      userAttribute.push({ key: "", value: "", new: true });
                      this.setState({
                        userAttribute,
                        enableConfirmAttributeChange: true,
                      });
                    }}
                  >
                    {this.props.t("usersAdmin.addNewAttribute")}
                  </Button>
                  <Button
                    color="primary"
                    disabled={!this.state.enableConfirmAttributeChange}
                    onClick={() => {
                      this.setState({ showConfirmAttributeModal: true });
                    }}
                  >
                    {this.props.t("usersAdmin.saveChanges")}
                  </Button>
                  <Button
                    color="primary"
                    disabled={!this.state.enableConfirmAttributeChange}
                    onClick={async () => {
                      let { userAttribute } = JSON.parse(
                        JSON.stringify(
                          this.state.dataOptions[this.state.idDataOptionsArray]
                        )
                      );
                      this.setState({
                        enableConfirmAttributeChange: false,
                        userAttribute,
                      });
                    }}
                  >
                    {this.props.t("usersAdmin.forgetChanges")}
                  </Button>
                </div>
              </Collapse>
            </div>
            <br />
            <br />
            <AdminCustomerTable
              userData={this.state.userCustomers}
              levels={this.state.customerLevels}
              onPermLevelChange={this.onCustomerPermLevelChange.bind(this)}
              onUpdatePerm={this.onCustomerUpdateData.bind(this)}
              onForgetChanges={this.onCustomerForgetChanges.bind(this)}
              name="customers"
            />
            <AdminSitesTable
              userSites={this.state.userSites}
              levels={this.state.siteLevels}
              onSitePermLevelChange={this.onSitePermLevelChange.bind(this)}
              onUpdatePerm={this.onSiteUpdateData.bind(this)}
              onForgetChanges={this.onSiteForgetChanges.bind(this)}
              name="sites"
            />
            <AdminGatewayTable
              userData={this.state.userGateways}
              levels={this.state.gwLevels}
              onPermLevelChange={this.onGWPermLevelChange.bind(this)}
              onUpdatePerm={this.onGWUpdateData.bind(this)}
              onForgetChanges={this.onGWForgetChanges.bind(this)}
              name="gateways"
              isAdminPage
            />
            <br />
            <br />
            {this.state.response !== ""
              ? ""
              : this.props.t("usersAdmin.thisIsTheResponse") +
                " (" +
                this.state.errorState +
                ")"}
            <br />
            <br />
            <div className="border">
              {this.state.response !== null
                ? JSON.stringify(this.state.response)
                : ""}
            </div>
          </Form>
          <Modal
            centered
            isOpen={this.state.showConfirmAttributeModal}
            toggle={() => {
              this.setState({ showConfirmAttributeModal: false });
            }}
          >
            <ModalBody>
              <div> {this.props.t("usersAdmin.confirmChangesMessage")}</div>
              <div className="float-right mt-4">
                <Button
                  onClick={() => {
                    this.setState({ showConfirmAttributeModal: false });
                  }}
                >
                  {this.props.t("generic.no")}
                </Button>
                <Button
                  onClick={async () => {
                    this.setState({ showConfirmAttributeModal: false });
                    let result = await this.applyChangesOnAttributes();
                    if (result) {
                      // Update user attributes
                      let { dataOptions, userAttribute, idDataOptionsArray } =
                        this.state;

                      userAttribute.forEach((a) => {
                        if (a.new) delete a.new;
                      });

                      userAttribute = userAttribute.filter((a) => {
                        return !a.remove;
                      });

                      dataOptions[idDataOptionsArray].userAttribute =
                        JSON.parse(JSON.stringify(userAttribute));

                      this.setState({
                        dataOptions,
                        userAttribute,
                        enableConfirmAttributeChange: false,
                      });
                    }
                  }}
                >
                  {this.props.t("generic.yes")}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      );
    } else return;
  }

  render() {
    const selectOptions = this.state.dataOptions.map((data, key) => ({
      label: data.email,
      value: key,
      name:
        data.firstName +
        " " +
        data.lastName +
        " ( " +
        data.email +
        " ) " +
        (data.deletedAt ? "[ " + data.deletedAt + " ]" : ""),
    }));

    return (
      <>
        {this.state.showFullLoader ? <Loader /> : null}
        <Container fluid className="mt--9">
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader>
                  <div className="w-50">
                    <SelectSearch
                      filterOptions={fuzzySearch}
                      options={selectOptions}
                      name="user"
                      placeholder={
                        this.props.t("usersAdmin.selectUser") +
                        "... (" +
                        this.props.t("usersAdmin.total") +
                        ": " +
                        this.state.total +
                        ")"
                      }
                      search={true}
                      onChange={this.onDataSelected.bind(this)}
                      autoComplete="on"
                      value={this.state.idDataOptionsArray}
                    />
                  </div>
                </CardHeader>
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {this.formToRender()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withTranslation("common")(UsersAdmin);
