/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim

=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

// reactstrap components
import { Card, CardBody, Container, Row, Col } from "reactstrap";

import CardHeader from "components/Headers/CardHeader.js";
import OpenStreetMap from "components/OpenStreetMap";

import { connect } from "react-redux";

import "./dashboard.css";

class Dashboard extends React.Component {
  evaluateMapProps = () => {
    let minLat = 1000;
    let maxLat = -1000;

    let minLon = 1000;
    let maxLon = -1000;

    for (let site of this.props.store.sites) {
      // Find the southernmost site
      if (site.latitude < minLat) {
        minLat = site.latitude;
      }

      // Find the northernmost site
      if (site.latitude > maxLat) {
        maxLat = site.latitude;
      }

      // Find the westernmost site
      if (site.longitude < minLon) {
        minLon = site.longitude;
      }

      // Find the easternmost site
      if (site.longitude > maxLon) {
        maxLon = site.longitude;
      }
    }

    // Find the center
    let lat = (maxLat + minLat) / 2;
    let lon = (maxLon + minLon) / 2;

    // Find the longest distance between latitude and longitude
    let maxDistance = Math.max(
      Math.sqrt(Math.pow(maxLat - minLat, 2)),
      Math.sqrt(Math.pow(maxLon - minLon, 2))
    );

    // Select which one is the longest: false=longitude, true=latitude
    let latMax =
      Math.sqrt(Math.pow(maxLat - minLat, 2)) >
      Math.sqrt(Math.pow(maxLon - minLon, 2));

    // Evaluate the proper zoom, based on maximum distance
    let zoom = 1;

    let boundLon = 256 / (((100 - (100 * window.innerWidth) / 2500) * 4) / 100);
    let boundLat =
      100 / (((100 - (100 * window.innerHeight) / 2000) * 4) / 100);

    let boundLimits = [boundLon, boundLat];
    for (let i = 0; i < 14; i++) {
      let val;
      if (!latMax) {
        val = boundLimits[0];
      } else {
        val = boundLimits[1];
      }

      if (maxDistance >= val) {
        break;
      } else {
        zoom++;
        boundLimits = [boundLimits[0] / 2, boundLimits[1] / 2];
      }
    }

    return {
      centerPosition: [lat, lon],
      zoom: zoom,
    };
  };

  render() {
    const { alerts } = this.props.store;
    const gatewayList = Object.keys(alerts.byId);
    let sites = JSON.parse(JSON.stringify(this.props.store.sites));
    let mapProps;
    if (this.props.location && this.props.location.site) {
      mapProps = {
        centerPosition: [
          this.props.location.site.latitude,
          this.props.location.site.longitude,
        ],
        zoom: 16,
      };
    } else mapProps = this.evaluateMapProps();

    sites.forEach((site) => {
      site.alarms = [];
      site.offlineGateways = [];
    });

    gatewayList.forEach((gatewayGuid) => {
      sites.forEach((site) => {
        site.gateways.forEach((gateway) => {
          if (gateway.gatewayGuid === gatewayGuid) {
            site.alarms.push(alerts.byId[gatewayGuid]);
          }
        });
      });
    });

    for (var site of sites) {
      let i = 0;
      for (var gw of site.gateways) {
        if (this.props.store.gateways.byId[gw.gatewayGuid] === undefined)
          site.gateways.splice(i);
        i += 1;
      }
    }

    this.props.store.gateways.allIds.forEach((gatewayGuid) => {
      if (this.props.store.gateways.byId[gatewayGuid].status === "OFF") {
        sites.forEach((site) => {
          site.gateways.forEach((gateway) => {
            if (gateway.gatewayGuid === gatewayGuid) {
              site.offlineGateways.push(
                this.props.store.gateways.byId[gatewayGuid]
              );
            }
          });
        });
      }
    });

    return (
      <>
        {/* Page content  */}
        <Container fluid className="mt--9">
          <CardHeader />
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardBody className="card-leaflet-dashboard">
                  <OpenStreetMap
                    markers={sites}
                    center={mapProps && mapProps.centerPosition}
                    zoom={mapProps && mapProps.zoom}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: {
      sitesLoaded: state.sitesLoaded,
      sites: Object.values(state.sites.byId),
      alerts: state.alerts,
      gateways: state.gateways,
    },
  };
};

export default connect(mapStateToProps)(Dashboard);
