import React from "react";
import { Button, ButtonGroup } from "reactstrap";

class XVOButtonGroup extends React.Component {
  onPermClick(event) {
    const target = event.target;
    const v =
      target.name === "none"
        ? "Unauthorized"
        : target.name === "viewer"
        ? "Viewer"
        : "Owner";
    const guid = target.value;
    this.props.onPermLevelChange({ id: guid, value: v });
  }

  render() {
    const level = this.props.level;
    return (
      <ButtonGroup>
        <Button
          size="sm"
          name="none"
          color={level === "Unauthorized" ? "default" : "info"}
          active={level === "Unauthorized"}
          value={this.props.guid}
          onClick={this.onPermClick.bind(this)}
        >
          None
        </Button>
        <Button
          size="sm"
          name="viewer"
          color={level === "Viewer" ? "default" : "info"}
          active={level === "Viewer"}
          value={this.props.guid}
          onClick={this.onPermClick.bind(this)}
        >
          Viewer
        </Button>
        <Button
          size="sm"
          name="owner"
          color={level === "Owner" ? "default" : "info"}
          active={level === "Owner"}
          value={this.props.guid}
          onClick={this.onPermClick.bind(this)}
        >
          Owner
        </Button>
      </ButtonGroup>
    );
  }
}

export default XVOButtonGroup;
