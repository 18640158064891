import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import License from "../components/License";
import Loader from "components/Loader";
import { connect } from "react-redux";

class UserLicense extends Component {
  render() {
    return this.props.permissionLoading ? <Loader /> : <License t={this.props.t} />;
  }
}

const mapStateToProps = (state) => ({
  permissionLoading: state.permissions.isLoading,
});

export default withTranslation("common")(connect(mapStateToProps)(UserLicense));