/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";

// core components
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
// import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import ProductLogo from "components/ProductLogo";
class ThingAuth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-info");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-info");
  }
  getRoutes = (routes) => {
    const t = this;
    return routes.map((prop, key) => {
      if (prop.layout === "/tr") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(routeProps) => (
              <prop.component {...routeProps} {...t.props} />
            )}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  onChangeUserClick() {
    this.props.onSignOut();
  }

  render() {
    const username = this.props.auth.user.idToken.name;
    return (
      <>
        <div className="main-content bg-info">
          {/* <AuthNavbar /> */}
          <div className="header bg-gradient-white py-5 py-lg-4">
            <Container>
              <div className="header-body text-center mb-8">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <ProductLogo logoWidth="w-50" />
                    <br></br>
                    <h4 className="text-default">
                      {this.props.t("registerThing.welcome") + " " + username}!
                    </h4>
                    <h5
                      className="btn text-center border border-info p-2"
                      onClick={this.onChangeUserClick.bind(this)}
                    >
                      {this.props.t("registerThing.changeUser")}
                    </h5>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-info" points="2560 0 2560 100 0 100" />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                {/* <Redirect from="*" to="/tauth/login" /> */}
              </Switch>
            </Row>
          </Container>
        </div>
        {/* <AuthFooter /> */}
      </>
    );
  }
}

export default withTranslation("common")(ThingAuth);
