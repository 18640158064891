import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";

import { withTranslation } from "react-i18next";

import Loader from "components/Loader";
import OperationSuccess from "./OperationSuccess";
import InvalidParams from "./InvalidParams";
import keys from "../../configs/constants";
import backendService from "services/backendService";

const PasswordReset = (props) => {

    document.title = 'Connected App -- Password Reset';

    const { token, user, email } = props;
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [hasErrors, setHasErrors] = useState(1); // 0: No errors, 1: Passwords doesn't match, 2: Not matching minimum requirements
    const [actionState, setActionState] = useState(-1); // -1: Nothing in progress, 0: loading, 1: success, 2: error

    useEffect(() => {
        if (newPassword.length > 0 && newPassword.length < 8) {
            setHasErrors(2);
        } else if (newPassword !== confirmPassword) {
            setHasErrors(1);
        } else {
            setHasErrors(0);
        }
    }, [newPassword, confirmPassword]);

    const updatePassword = async () => {
        setActionState(0);
        const password = newPassword;
        try {
            await backendService.callMappAPI("POST", `users/${user}/reset`, { token, password });
            setActionState(1);
        } catch (error) {
            setActionState(2);
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    if (actionState === 1) {
        return (<OperationSuccess />);
    };

    if (actionState === 2) {
        return (<InvalidParams />);
    };

    return (
        <>
            <div className="pt-4 justify-content-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="justify-content-center xl-6">
                            <Row className="justify-content-center">
                                <h3 className="justify-content-center bg-info">
                                    {props.t("generic.hello")}{email ? " " + email + " !" : " !"}
                                </h3>
                            </Row>
                            <Row className="justify-content-center text-center mt-4 p-2">
                                {props.t("generic.updatePasswordMessage")}
                            </Row>
                            <Row className="justify-content-center">
                                <Form className="mt-4">
                                    <FormGroup row>
                                        <Col xs={11} className="d-flex flex-row justify-content-center">
                                            <Label
                                                for="newPassword"
                                                className="h4 mr-2"
                                            >
                                                {props.t("generic.newPassword")}
                                            </Label>
                                            <Input
                                                id="newPassword"
                                                name="password"
                                                placeholder={props.t("generic.newPassword")}
                                                type={passwordVisible ? "text" : "password"}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={1} className="d-flex flex-row">
                                            <div className="d-flex flex-column justify-content-center ml--2" onClick={togglePasswordVisibility}>
                                                {passwordVisible ? keys.ICON_EYE_PASSWORD_SLASH : keys.ICON_EYE_PASSWORD}
                                            </div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col xs={11} className="d-flex flex-row justify-content-center">
                                            <Label
                                                for="confirmPassword"
                                                className="h4"
                                            >
                                                {props.t("generic.confirmPassword")}
                                            </Label>
                                            <Input
                                                id="confirmPassword"
                                                name="password"
                                                placeholder={props.t("generic.confirmPassword")}
                                                type={passwordVisible ? "text" : "password"}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Form>

                            </Row>
                            {hasErrors === 2 ? <Row className="justify-content-center"><h5 className="text-danger">{props.t("generic.passwordTooShort")}</h5></Row> : null}
                            {hasErrors === 1 ? <Row className="justify-content-center"><h5 className="text-danger">{props.t("generic.passwordsDoesntMatch")}</h5></Row> : null}
                            <Row className="justify-content-center mt-4">{actionState === 0 ? <Loader custom={true} size={64} /> : null}</Row>
                            <Row className="justify-content-center mt-4">
                                <Button
                                    className="btn-icon"
                                    color="primary"
                                    type="button"
                                    disabled={hasErrors !== 0 || newPassword.length === 0}
                                    onClick={() => updatePassword()}
                                >
                                    <span className="btn-inner--icon">
                                        {keys.ICON_OK}
                                    </span>
                                    <span className="btn-inner--text">
                                        {props.t('generic.updatePassword')}
                                    </span>
                                </Button>
                            </Row>

                        </Col>
                    </Row>
                </Container >
            </div >
        </>
    );
};

export default withTranslation("common")(PasswordReset);