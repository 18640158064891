/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components

import HeaderContainer from "components/Headers/HeaderContainer.js";
import ThinHeader from "components/Headers/ThinHeader.js";

import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Loader from "components/Loader";

import routes from "routes.js";
import keys from "configs/constants";
import {
  setSites,
  setGateways,
  setSitesLoaded,
  setControllers,
  updateCurrentPage,
  setUser,
  setAlerts,
  setPermissions,
  setLanguages,
} from "../redux/actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import api from "../services/backendService";
import { store } from "index";
import { getUserAttribute } from "layouts/common";

class Standard extends React.Component {
  state = { dependenciesReady: false };

  constructor(props) {
    super(props);
    this.mContentRef = React.createRef();

    let userInfo = {
      name: props.auth.user.name,
      family_name: props.auth.user.idToken.family_name,
      given_name: props.auth.user.idToken.given_name,
      role: props.auth.user.idToken.role,
      email: props.auth.user.idToken.email,
    };

    getUserAttribute().then((attributes) => {
      userInfo = { ...userInfo, ...attributes };
      props.setUser(userInfo);
    });
  }

  checkDependency = (dep) => {
    return (
      this.props.store &&
      this.props.store[dep] &&
      this.props.store[dep].allIds.length >= 0
    );
  };

  loadDependency = async (dep) => {
    /* We can check dep here without function above */
    if (this.checkDependency(dep)) return true;
    switch (dep) {
      case "dashboard":
        let response = await api.getDashboard();
        let apiSites = [];
        let apiGateways = [];
        for (let item of response.data.data) {
          const { site } = item;
          const { gateways } = site;

          apiSites.push(site);

          for (let gw of gateways) {
            apiGateways.push(gw);
          }
        }

        /* 
        Sites and gateways come from the call of the dashboard endpoint so the 
        functions to get entities initial states do not need to call API. 
        They are used just to get the proper format of data for the redux store
        */
        let sites = await api.getSiteInitialState(apiSites, true);
        this.props.setSites(sites);

        let gateways = await api.getGatewayInitialState(
          undefined,
          apiGateways,
          true
        );
        this.props.setGateways(gateways);

        // Start with total active alerts = -1, so the card can show a loader or something...
        this.props.setAlerts({ byId: {}, allIds: [], total: -1 });
        break;
      case "controllers":
        this.props.setControllers(
          await api.getDevicesOnSite(this.props.location.site.siteGuid)
        );
        break;
      case "sites":
        api
          .getSiteInitialState()
          .then((response) => this.props.setSites(response));
        break;
      case "gateways":
        api
          .getGatewayInitialState()
          .then((response) => this.props.setGateways(response));
        break;
      case "alerts":
        api
          .getAlertInitialState()
          .then((response) => this.props.setAlerts(response));
        break;
      case "permissions":
        api
          .getPermissions()
          .then((response) => this.props.setPermissions(response));
        break;
      case "languages":
        let lang = store.getState().user.language || "English_en-GB";
        let languages = await api.getLanguagesList();

        let data = await api.getTranslations(lang);

        this.props.setLanguages({ list: languages });

        let code = lang.split("_")[1];

        this.props.selectLanguage({ [code]: { common: data.data } });

        break;
      default:
        break;
    }
    return true;
  };

  async componentDidMount() {
    let readiness = false;
    this.setState({ dependenciesReady: readiness });
    for (const route of routes) {
      if (this.props.location.pathname !== route.layout + route.path) continue;
      if (route.req || this.props.store) {
        let storeReq = [
          "dashboard",
          "languages",
          "sites",
          "gateways",
          "alerts",
          "permissions",
        ];

        for (let req of route.req) {
          storeReq.push(req);
        }

        let dOK = 0;
        const n = storeReq.length; //route.req.length;
        for (const req of storeReq /*route.req*/) {
          if (await this.loadDependency(req)) dOK++;
        }
        if (n === dOK) readiness = true;
      } else readiness = true;
    }
    this.setState({ dependenciesReady: readiness });
  }

  componentDidUpdate(_e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.mContentRef.current.scrollTop = 0;
    this.props.updateCurrentPage(this.props.location);
  }

  updateProps() {
    const pageInfo = JSON.parse(window.sessionStorage.getItem("cn"));
    if (this.props.history.action === "POP" && pageInfo) {
      Object.assign(this.props.location, pageInfo.usedProps);
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/std") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => {
              const Comp = prop.component;
              return <Comp {...props} />;
            }}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "brand";
  };

  render() {
    this.updateProps();

    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            imgAlt: "Logo",
            imgSrc: keys.LOGO_URL,
            innerLink: "/std/index",
          }}
        />
        <div className="main-content" ref={this.mContentRef}>
          <HeaderContainer>
            <ThinHeader
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
          </HeaderContainer>
          {this.state.dependenciesReady ? (
            <>
              <Switch>
                {this.getRoutes(routes)}
                <Redirect to="/" />
              </Switch>
            </>
          ) : (
            <Loader />
          )}
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSites,
      setGateways,
      setSitesLoaded,
      setControllers,
      setAlerts,
      updateCurrentPage,
      setUser,
      setPermissions,
      setLanguages,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Standard);
