import React from "react";

// reactstrap components
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
} from "reactstrap";
import Chart from "./Chart";
import utils from "../utils";
import PointCard from "components/PointCard";
import ListDropdown from "components/ListDropdown";
import Loader from "components/Loader";
import api, { sources } from "../services/backendService";
import keys from "configs/constants";
import { withTranslation } from "react-i18next";
import { createSelector } from "../../node_modules/reselect/es/index";

class DevicesCard extends React.Component {
  constructor(props) {
    super(props);

    this.details = 230;

    const points =
      this.props.controllers.byId[this.props.deviceGuid].pointsById;

    let defaultChart = Object.keys(points)[0] || "";

    let analogPoints = {};
    let digitalPoints = {};

    Object.keys(points).forEach((p) => {
      if (points[p].type === "integer")
        analogPoints = { ...analogPoints, [p]: points[p] };
    });

    Object.keys(points).forEach((p) => {
      if (points[p].type === "bits")
        digitalPoints = { ...digitalPoints, [p]: points[p] };
    });

    let found = false;

    // Search first analog available
    for (let p of Object.keys(analogPoints)) {
      let value = analogPoints[p].value || analogPoints[p].lastValue;

      if (value && value !== -32767 && value !== -32768) {
        found = true;
        defaultChart = p;
        break;
      }
    }

    // If not found, search first digital available
    if (!found) {
      for (let p of Object.keys(digitalPoints)) {
        let value = digitalPoints[p].value || digitalPoints[p].lastValue;

        if (value && value !== -32767 && value !== -32768) {
          found = true;
          defaultChart = p;
          break;
        }
      }
    }

    this.state = {
      data: {
        x: [],
        y: [],
        type: "scatter",
        mode: "lines",
        name: utils.getLangText(defaultChart),
      },
      measures: null,
      point: defaultChart,
      allPoint: false,
      isChartLoading: true,
      searchText: "",
      chartSize: {
        width: window.innerWidth - this.windowFactor(false),
        height: window.innerHeight - this.windowFactor(true),
      },
      showModal: false,
    };

    this.id = null;

    this.getInitialMeasure(!found);
  }

  componentDidMount() {
    try {
      let itemStyle = document.querySelector(".details");
      itemStyle.style.setProperty("--details-pos", `${this.details}px`);

      window.onscroll = () => {
        let itemStyle = document.querySelector(".details");

        this.details = 230 - window.pageYOffset;

        if (this.details < 0) this.details = 0;

        if (itemStyle !== null)
          itemStyle.style.setProperty("--details-pos", `${this.details}px`);
      };

      window.onresize = () => {
        let { chartSize } = this.state;

        chartSize.width = window.innerWidth - this.windowFactor(false);
        chartSize.height = window.innerHeight - this.windowFactor(true);

        this.setState({ chartSize });
      };
    } catch (e) {}
  }

  componentWillUnmount() {
    if (sources[this.id]) sources[this.id].source.cancel();
  }

  /**
   * Param is a boolean: false = width, true = height
   * @param {*} h
   */
  windowFactor = (h) => {
    if (!h) return 1200 * (window.innerWidth / 1920);
    else return 430 * (window.innerHeight / 970);
  };

  getInitialMeasure = async (digital = false) => {
    const { pointsById } = this.props.controllers.byId[this.props.deviceGuid];

    // This solution gets last 6 hours, if no data is found, the chart will be blank
    let dateTo = new Date();
    let dateFrom = utils.setOffsetDate(dateTo, 60 * 6);

    let response = await api.getAggregatedMeasuresByDate(
      [this.props.deviceGuid],
      utils.formatDate(dateFrom),
      utils.formatDate(dateTo)
    );

    // Instead this solution always fill the chart because get the last valid data
    // let response = await api.getAggregatedMeasuresByLastNValues(
    //   [this.props.deviceGuid],
    //   4 * 6
    // );

    this.id = response.id;

    response.execute
      .then((data) => {
        let measures = data.data[0].aggregatedMeasures;

        measures.forEach((measure) =>
          Object.keys(measure).forEach((m) => {
            const item = pointsById[m];
            if (item && item.type) {
              if (item.type === "integer") {
                measure[m] = parseFloat(
                  utils.pointValue(measure[m], "integer", item.vtype)
                );
              }
            }
          })
        );

        this.setState({ measures, isChartLoading: false });

        this.updateChart(this.state.point, digital, measures);
      })
      .catch(() => {});
  };

  updateChart = (point, digital, measures) => {
    try {
      let { data } = this.state;

      data.x = [];
      data.y = [];

      for (const measure of measures) {
        data.x.push(new Date(measure.dateTo + "Z"));
        if (measure[point] === -32767) data.y.push(undefined);
        else data.y.push(measure[point]);
      }

      data.name = utils.getLangText(point);

      if (digital) data.line = { shape: "hvh" };
      else data.line = { shape: "linear" };

      this.setState({ data, point });
    } catch (err) {
      utils.debug(err, utils.MSG_TYPE_WARN);
    }
  };

  filterVariables = (list, digital) => {
    const { searchText } = this.state;

    let variables = list.filter((point) => {
      if (!digital) return point.type === "integer";
      else return point.type === "bit";
    });

    variables = variables.filter((point) =>
      point.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return variables;
  };

  renderChart = (modal) => {
    return (
      <Chart
        smartChart
        layout={{
          title: this.state.data.name,
          autosize: true,
          showlegend: false,
          width: !modal ? this.state.chartSize.width : window.innerWidth - 50,
          height: this.state.chartSize.height,
          margin: {
            l: 70,
            r: 30,
            t: 50,
            b: 50,
            pad: 10,
          },
        }}
        data={[this.state.data, {}]}
      />
    );
  };

  getActiveAlarm = (gwGuid, code) => {
    if (
      this.props.alerts.byId[gwGuid] &&
      this.props.alerts.byId[gwGuid][this.props.deviceGuid]
    ) {
      for (const alert of this.props.alerts.byId[gwGuid][
        this.props.deviceGuid
      ]) {
        if (alert.fieldName.toLowerCase() === code.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const noMeasures = (
      <Col className="text-center m-5">
        <span className="font-weight-light">
          {this.props.t("connectedDevice.noMeasuresYet")}
        </span>
      </Col>
    );

    let controller = this.props.controllers.byId[this.props.deviceGuid];

    for (let gGuid of Object.keys(this.props.alerts.byId)) {
      for (let dGuid of Object.keys(this.props.alerts.byId[gGuid])) {
        if (this.props.deviceGuid === dGuid) {
          const deviceAlerts = this.props.alerts.byId[gGuid][dGuid];
          for (let alert of deviceAlerts) {
            let thresholdType = utils.getThresholdType(
              alert.maxValue,
              alert.minValue,
              alert.rangePercentage
            );
            try {
              if (thresholdType === 1)
                controller.pointsById[alert.fieldName].lastValue = 1;
              if (thresholdType === 2)
                controller.pointsById[alert.fieldName].lastValue = 0;
            } catch (e) {
              utils.debug(alert.fieldName + " - " + e);
            }
          }
        }
      }
    }

    const points = controller.pointsById || [];

    const gwGuid = controller.gateway.gatewayGuid;
    const addr = controller.address;

    let analogCardList;
    let digitalCardList;

    if (points.length === 0) return noMeasures;

    let pointCodes = Object.getOwnPropertyNames(points);

    let cardList = [];

    let badLink = true;
    for (let point of pointCodes) {
      let value = "NA";
      if (points[point].value || points[point].value === 0) {
        value = utils.pointValue(
          points[point].value,
          points[point].type,
          points[point].vtype
        );
      } else {
        if (points[point].lastValue || points[point].lastValue === 0) {
          value = utils.pointValue(
            points[point].lastValue,
            points[point].type,
            points[point].vtype
          );
        }
      }

      let p = Object.assign({}, points[point]);
      let conv = null;
      for (let ctype of controller.convTable) {
        if (ctype.id === points[point].vtype) conv = ctype.ratio;
      }
      p = {
        ...p,
        key: point,
        name: utils.getLangText(point),
        value: value,
        unit: utils.getUnitByType(value, points[point].vtype),
        cmds: points[point].commands || {},
        waddr: points[point].waddr || null,
        gatewayGuid: gwGuid,
        deviceAddress: addr,
        alarm: this.getActiveAlarm(gwGuid, point),
        conversion: conv,
      };

      cardList.push(p);

      if (p.value !== "NA" && p.value !== "ERROR" && p.value !== "NO PROBE")
        badLink = false;
    }

    if (badLink) {
      for (let card of cardList) {
        card.value = "BAD LINK";
      }
    }

    const isViewer = this.props.permissionGateway.toLowerCase() === "viewer";

    if (this.state.searchText === "") {
      analogCardList = cardList.filter((point) => {
        if (point.type === "integer") {
          if (this.state.allPoint) return true;
          else if (point.value === "NO PROBE" || point.value === "NA")
            return false;
          else return true;
        }
        return false;
      });
      digitalCardList = cardList.filter((point) => {
        if (point.type === "bit") {
          if (this.state.allPoint) return true;
          else if (
            (point.alarm ||
              point.value === "ON" ||
              (point.commands !== undefined && !isViewer)) &&
            point.value !== "NA"
          )
            return true;
        }
        return false;
      });
    } else {
      analogCardList = this.filterVariables(cardList, false);
      digitalCardList = this.filterVariables(cardList, true);
    }

    if (pointCodes.filter((p) => points[p].lastValue).length === 0)
      return noMeasures;

    return (
      <>
        <CardBody>
          <Row>
            <Col className="variable-filters w-100 mb-2">
              <ListDropdown
                className="variable-list-selection"
                title={
                  this.state.allPoint
                    ? this.props.t("deviceCards.all")
                    : this.props.t("deviceCards.onlyActive")
                }
                listItems={[
                  {
                    text: this.props.t("deviceCards.all"),
                    command: () => this.setState({ allPoint: true }),
                  },
                  {
                    text: this.props.t("deviceCards.onlyActive"),
                    command: () => this.setState({ allPoint: false }),
                  },
                ]}
              />
              <InputGroup>
                  <InputGroupText>{keys.ICON_SEARCH}</InputGroupText>
                <Input
                  name="searchText"
                  value={this.state.searchText}
                  placeholder={this.props.t("deviceCards.search")}
                  type="text"
                  onChange={(e) =>
                    this.setState({ searchText: e.target.value })
                  }
                />
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col className="text-center w-100 mb-5">
              <Card
                color="primary"
                className="text-white text-left font-weight-bold pl-4 p-2 mb-2"
              >
                {this.props.t("deviceCards.analogValues").toUpperCase()}
              </Card>
              {analogCardList.map((card) => {
                let alStatus = "ok";
                if (
                  this.props.alerts.byId[gwGuid] &&
                  this.props.alerts.byId[gwGuid][this.props.deviceGuid]
                ) {
                  for (const alert of this.props.alerts.byId[gwGuid][
                    this.props.deviceGuid
                  ]) {
                    if (
                      alert.fieldName.toLowerCase() === card.key.toLowerCase()
                    ) {
                      alStatus = "alarm";
                      break;
                    }
                  }
                }

                return (
                  <Row
                    key={card.key + "|" + card.gatewayGuid}
                    className="w-100 m-0"
                  >
                    <PointCard
                      card={card}
                      alertStatus={alStatus}
                      selectPoint={() => this.updateChart(card.code, false, this.state.measures)}
                      active={this.state.point === card.code}
                      requestChart={() => {
                        this.updateChart(card.code, false, this.state.measures);
                        this.setState({ showModal: true });
                      }}
                      disabled={this.props.gatewayInfo.status === "OFF"}
                      isOwner={!isViewer}
                    />
                  </Row>
                );
              })}

              <Card
                color="primary"
                className="text-white text-left font-weight-bold pl-4 p-2 mb-2"
              >
                {this.props.t("deviceCards.digitalValues").toUpperCase()}
              </Card>
              {digitalCardList.map((card) => {
                let alStatus = card.alarm ? "alarm" : "ok";

                return (
                  <Row
                    key={card.key + "|" + card.gatewayGuid}
                    className="w-100 m-0"
                  >
                    <PointCard
                      card={card}
                      alertStatus={alStatus}
                      selectPoint={() => this.updateChart(card.code, true, this.state.measures)}
                      active={this.state.point === card.code}
                      digital
                      requestChart={() => {
                        this.updateChart(card.code, false, this.state.measures);
                        this.setState({ showModal: true });
                      }}
                      disabled={this.props.gatewayInfo.status === "OFF"}
                      isOwner={!isViewer}
                    />
                  </Row>
                );
              })}
            </Col>
            <Col className="text-center w-100 mb-5 details-container">
              <div
                className={`${
                  this.props.gatewayInfo.status === "OFF" ? "mt-5" : ""
                } details`}
              >
                {this.state.isChartLoading ? (
                  <Loader
                    custom
                    className="justify-content-between text-center mt-5"
                  />
                ) : (
                  this.renderChart(false)
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
        <Modal
          centered
          isOpen={this.state.showModal}
          toggle={() => this.setState({ showModal: !this.state.showModal })}
          className="modal-chart"
        >
          {this.renderChart(true)}
        </Modal>
      </>
    );
  }
}

const getPermissionGateway = createSelector(
  (state, gatewayGuid) => state.permissions.gateways.byId[gatewayGuid],
  (permission) => (permission ? permission.level : "Viewer")
);

const mapStateToProps = (state, ownProps) => {
  return {
    controllers: state.controllers,
    alerts: state.alerts,
    permissionGateway:
      state.user.role === "Admin"
        ? "Owner"
        : getPermissionGateway(state, ownProps.gatewayInfo.gatewayGuid),
  };
};

export default withTranslation("common")(connect(mapStateToProps)(DevicesCard));
