import React from "react";
import { Collapse, Table, Button, Badge } from "reactstrap";
import { Redirect } from "react-router-dom";
import XVOButtonGroup from "components/admin/XVOButtonGroup";
import { apiAction, setControllers } from "../../redux/actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import api from "services/backendService";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../Notify";
import RegisterNewGateway from "components/RegisterNewGateway/index";
import keys from "configs/constants";

import utils from "utils";

import "./style.css";
import { withTranslation } from "react-i18next";

class GatewayTable extends React.Component {
  state = {
    isOpen: true,
    redirect: false,
    fwUpdateGWList: [],
    showNewGatewayModal: false,
    newMacAddress: "",
  };

  gatewayInfo = {
    customer: null,
    devices: null,
    gatewayGuid: null,
    macAddress: null,
    name: null,
    site: null,
    siteGuid: null,
  };

  componentDidMount() {
    if (!this.props.isAdminPage) {
      let isThereAnyController = false;
      for (const g of Object.values(this.props.store.gateways.byId).filter(
        (gw) => gw.site.siteGuid === this.props.site.siteGuid
      )) {
        if (g.devices.length > 0) {
          isThereAnyController = true;
          break;
        }
      }
      if (
        this.props.store.controllers.byId !== undefined &&
        Object.values(this.props.store.controllers.byId).length === 0 &&
        isThereAnyController
      ) {
        this.props.apiAction(
          api.getDevicesOnSite,
          [this.props.site.siteGuid],
          setControllers,
          true
        );
      }
    }
  }

  onInputChange(evt) {
    const target = evt.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (name === "fwupdate") {
      // id is in the format fwupdate_<gatewayGuid>
      const gatewayGuid = target.id.split("_")[1];
      let fwUpdate = this.state.fwUpdateGWList;
      if (value) fwUpdate.push(gatewayGuid);
      else {
        const idx = fwUpdate.indexOf(gatewayGuid);
        if (idx > -1) fwUpdate.splice(idx, 1);
      }
      this.setState({ fwUpdateGWList: fwUpdate, [target.id]: value });
    } else this.setState({ [name]: value });
  }

  onPermLevelChange(level) {
    this.props.onPermLevelChange(level);
  }

  onUpdatePerm() {
    this.props.onUpdatePerm();
  }

  onForgetChanges() {
    this.props.onForgetChanges();
  }

  updateGatewaysState = (success) => {
    const newFwUpdateGWList = this.state.fwUpdateGWList.filter(
      (gw) => !success.includes(gw)
    );
    let checkBoxGateways = {};
    for (const gw of success) {
      checkBoxGateways["fwupdate_" + gw] = false;
    }
    this.setState({ fwUpdateGWList: newFwUpdateGWList, ...checkBoxGateways });
  };

  async onGWFWUpdateClick() {
    let error = [];
    let success = [];
    for (const gatewayGuid of this.state.fwUpdateGWList) {
      const r = await api.sendMessageToGateway(gatewayGuid, {
        type: "gcmd",
        cmd: "fwupdate",
      });
      if (r.status === 200) {
        let gatewayGuid = utils.getGuidsInString(r.config.data)[0];
        success.push(gatewayGuid);
        success.flat();
      } else {
        error.push(utils.getGuidsInString(r.config.data));
        error.flat();
      }
    }

    this.updateGatewaysState(success);

    if (error.length > 0) {
      let msg = this.props.t("gatewayTable.unableToSend") + ":";
      for (const gatewayGuid of error) {
        msg += " " + this.props.store.gateways.byId[gatewayGuid].name;
      }
      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent("danger", null, msg),
      });
    } else {
      notifyStore.addNotification({
        ...tempOptions,
        content: NotifyContent(
          "success",
          null,
          this.props.t("gatewayTable.commandSent")
        ),
      });
    }
  }

  renderRows() {
    const t = this;
    if (this.props.userData.length > 0) {
      return this.props.userData.map((gw, idx) => {
        const g = gw.gateway;
        const gGuid = g.gatewayGuid;

        const redirect = async () => {
          let gateway = (await api.getSingleGatewayDetail(gGuid)).data;

          this.gatewayInfo = {
            customer: gateway.customer.name,
            devices: this.props.store.controllers,
            gatewayGuid: gateway.gatewayGuid,
            macAddress: gateway.macAddress,
            name: gateway.name,
            site: gateway.site.name,
            siteGuid: gateway.site.siteGuid,
          };
          this.setState({ redirect: true });
        };

        let pushTelemetry = utils.formatTime(
          g.telemetryPushInterval,
          utils.NO_ZERO,
          false
        );

        let updateDisable = true;

        if (g.status === "ON") {
          if (t.props.isAdminPage || t.props.store.userRole === "Admin")
            updateDisable = false;
          else if (g.level === "Owner") updateDisable = false;
        }

        let license = [];

        try {
          license = g.licenses.filter((l) => l.isActive);
        } catch (ex) {}

        let licenseName = "-";
        if (license.length > 0) {
          licenseName = license[0].name;
        }

        const gtwLastStatus = utils.getGatewayLastStatus(g.lastStatus);

        return (
          <tr
            key={idx}
            //className={`${g.status === "OFF" ? "bg-warning text-white" : ""}`}
          >
            {t.props.isAdminPage ? (
              <>
                <td>
                  <XVOButtonGroup
                    level={
                      t.props.levels[gGuid]
                        ? t.props.levels[gGuid]
                        : gw.user !== undefined
                        ? gw.level
                        : "None"
                    }
                    guid={gGuid}
                    onPermLevelChange={t.onPermLevelChange.bind(this)}
                  />
                </td>
              </>
            ) : null}
            <td onClick={() => redirect()}>
              <span
                className={`text-warning mr-2 h2 ${
                  g.status === "OFF" && !t.props.isAdminPage ? "" : "d-none"
                }`}
              >
                <span id={`warning${this.props.siteGuid}`}>
                  {keys.ICON_WARNING}
                </span>
              </span>
              {g.name}
            </td>
            <td onClick={() => redirect()}>
              {gtwLastStatus === "unknown" || g.status === "OFF" ? (
                <span className="text-light">
                  {" "}
                  {this.props.t(`gatewayTable.unavailable`)}
                </span>
              ) : (
                <Badge
                  color={
                    gtwLastStatus === "licenseNotValid" ? "warning" : "info"
                  }
                    className="text-white"
                >
                  <span className="font-weight-bold">
                    {" "}
                    {this.props.t(`gatewayStatus.${gtwLastStatus}`)}
                  </span>
                </Badge>
              )}
            </td>
            <td
              className={`${
                this.props.isAdminPage
                  ? "d-none"
                  : g.releaseFW
                  ? ""
                  : "text-light"
              }`}
              onClick={() => redirect()}
            >
              {g.releaseFW || this.props.t("gatewayTable.unavailable")}
            </td>
            <td
              className={`${this.props.isAdminPage ? "d-none" : ""}`}
              onClick={() => redirect()}
            >
              {pushTelemetry}
            </td>
            <td onClick={() => redirect()}>{g.devices.length}</td>
            <td onClick={() => redirect()}>{g.macAddress}</td>
            <td
              className={`${this.props.isAdminPage ? "d-none" : ""}`}
              onClick={() => redirect()}
            >
              {licenseName}
            </td>
            <td className={`${this.props.isAdminPage ? "d-none" : ""}`}>
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id={"fwupdate_" + gGuid}
                  type="checkbox"
                  name="fwupdate"
                  checked={this.state["fwupdate_" + gGuid] || false}
                  onChange={this.onInputChange.bind(this)}
                  disabled={updateDisable}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"fwupdate_" + gGuid}
                >
                  {this.props.t("gatewayTable.update")}
                </label>
              </div>
            </td>
            {t.props.isAdminPage ? (
              <>
                <td>{g.site.name}</td>
                <td className="customer-and-delete">{g.customer.name}</td>
                <td>
                  <span
                    className={`${
                      gw.user !== undefined
                        ? "icon-delete"
                        : "icon-delete-disabled"
                    } float-right`}
                    onClick={() => {
                      if (gw.user !== undefined) {
                        api
                          .deletePermissionGateway(gGuid, gw.user.userGuid)
                          .then(() => {
                            this.props.onUpdatePerm();
                          });
                      }
                    }}
                  >
                    {keys.ICON_TRASH}
                  </span>
                </td>
              </>
            ) : null}
          </tr>
        );
      });
    } else return;
  }

  toggle() {
    const newOpenState = !this.state.isOpen;
    this.setState({ isOpen: newOpenState });
  }

  closeModal = () => {
    this.setState({
      newMacAddress: "",
      showNewGatewayModal: false,
    });
  };

  render() {
    if (!this.props.isAdminPage) {
      const siteInfo = {
        city: this.props.site.city,
        connectivitytype: this.props.site.connectivitytype,
        country: this.props.site.country,
        latitude: this.props.site.latitude,
        longitude: this.props.site.longitude,
        name: this.props.site.name,
        siteGuid: this.props.site.siteGuid,
      };

      if (this.state.redirect) {
        return (
          <Redirect
            to={{
              pathname: "/std/gtw",
              gateway: this.gatewayInfo,
              site: siteInfo,
            }}
          />
        );
      }
    }

    return (
      <div className="border" color="primary">
        <Button
          color="primary"
          onClick={this.toggle.bind(this)}
          style={{ marginBottom: "1rem" }}
          className="ml-2 mt-3"
        >
          {(this.state.isOpen
            ? this.props.t("gatewayTable.collapse")
            : this.props.t("gatewayTable.expand")) +
            " (" +
            this.props.userData.length +
            ")"}
        </Button>

        <Button
          className={`${this.props.isAdminPage ? "d-none" : ""}`}
          color="primary"
          name="fwupdate"
          disabled={this.state.fwUpdateGWList.length === 0}
          onClick={this.onGWFWUpdateClick.bind(this)}
        >
          {this.props.t("gatewayTable.updateFw")}
        </Button>
        {this.props.isAdminPage ? (
          <>
            <Button
              color="primary"
              style={{ marginBottom: "1rem" }}
              className="ml-2 mt-3"
              onClick={this.onUpdatePerm.bind(this)}
              disabled={Object.keys(this.props.levels).length === 0}
            >
              {this.props.t("gatewayTable.updatePerm")}
            </Button>
            <Button
              color="primary"
              style={{ marginBottom: "1rem" }}
              className="ml-2 mt-3"
              onClick={this.onForgetChanges.bind(this)}
              disabled={Object.keys(this.props.levels).length === 0}
            >
              {this.props.t("gatewayTable.forget")}
            </Button>
          </>
        ) : (
          ""
        )}
        {!this.props.isAdminPage && (
          <Button
            color="primary"
            onClick={() =>
              this.setState({
                showNewGatewayModal: !this.state.showNewGatewayModal,
              })
            }
            style={{ marginBottom: "1rem" }}
            className="ml-2 mt-3 float-right"
          >
            {this.props.t("sidebar.registerNewGateway")}
          </Button>
        )}
        {this.props.site && this.props.site.siteGuid && (
          <RegisterNewGateway
            isOpen={this.state.showNewGatewayModal}
            toggle={this.closeModal}
            value={this.state.newMacAddress}
            onChange={(e) => {
              this.setState({ newMacAddress: e.target.value });
            }}
            siteGuid={this.props.site.siteGuid}
          />
        )}

        <Collapse isOpen={this.state.isOpen}>
          <div className="gateway-table">
            <Table className="align-items-center" responsive="sm" hover>
              <thead className="thead-light">
                <tr>
                  {this.props.isAdminPage ? (
                    <>
                      <th scope="col">
                        {this.props.t("gatewayTable.permission")}
                      </th>
                    </>
                  ) : null}
                  <th scope="col">{this.props.t("gatewayTable.name")}</th>
                  <th scope="col">
                    {this.props.t("gatewayProperties.lastStatus")}
                  </th>
                  <th
                    scope="col"
                    className={`${this.props.isAdminPage ? "d-none" : ""}`}
                  >
                    {this.props.t("gatewayTable.release")}
                  </th>
                  <th
                    scope="col"
                    className={`${this.props.isAdminPage ? "d-none" : ""}`}
                  >
                    {this.props.t("gatewayTable.pushInterval")}
                  </th>
                  <th scope="col">
                    {this.props.t("gatewayTable.controllers")}
                  </th>
                  <th scope="col">{this.props.t("gatewayTable.uniqueId")}</th>
                  <th
                    scope="col"
                    className={`${this.props.isAdminPage ? "d-none" : ""}`}
                  >
                    {this.props.t("gatewayTable.license")}
                  </th>
                  <th
                    scope="col"
                    className={`${this.props.isAdminPage ? "d-none" : ""}`}
                  >
                    {this.props.t("gatewayTable.updateFirmware")}
                  </th>
                  {this.props.isAdminPage ? (
                    <>
                      <th scope="col">{this.props.t("gatewayTable.site")}</th>
                      <th scope="col">
                        {this.props.t("gatewayTable.customer")}
                      </th>
                      <th scope="col"></th>
                    </>
                  ) : null}
                </tr>
              </thead>
              <tbody>{this.renderRows()}</tbody>
            </Table>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: {
      controllers: state.controllers,
      gateways: state.gateways,
      userRole: state.user.role,
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      apiAction,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(GatewayTable)
);
