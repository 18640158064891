import React, { Component } from "react";
import { Modal, ModalBody, Button, Input } from "reactstrap";
import Loader from "components/Loader";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import utils from "utils";
import api from "../../services/backendService";
import isBoolean from "validator/lib/isBoolean";
import "./style.css";

class LicenseModal extends Component {
  constructor(props) {
    super(props);

    let permissionsCustomer = JSON.parse(
      JSON.stringify(this.props.permissionsCustomer)
    );

    if (permissionsCustomer.allIds.length > 0) {
      let permissions = { byId: {}, allIds: [] };

      Object.keys(permissionsCustomer.byId).forEach((subGuid) => {
        if (permissionsCustomer.byId[subGuid].level === "Owner") {
          permissions.byId = {
            ...permissions.byId,
            [subGuid]: permissionsCustomer.byId[subGuid],
          };
          permissions.allIds.push(subGuid);
        }
      });

      permissionsCustomer = permissions;
    }

    this.state = {
      showModal: false,
      newActivationCode: null,
      isLoading: false,
      permissionsCustomer: permissionsCustomer,
    };
  }

  componentDidMount = async () => {
    const ownedSubscriptions = Object.keys(
      this.state.permissionsCustomer.byId
    ).filter(
      (subGuid) =>
        this.state.permissionsCustomer.byId[subGuid].level === "Owner"
    );

    if (ownedSubscriptions.length === 0) {
      let response = (await api.getPermissions()).customers;

      let permissions = { byId: {}, allIds: [] };

      Object.keys(response.byId).forEach((subGuid) => {
        if (response.byId[subGuid].level === "Owner") {
          permissions.byId = {
            ...permissions.byId,
            [subGuid]: response.byId[subGuid],
          };
          permissions.allIds.push(subGuid);
        }
      });

      this.setState({ permissionsCustomer: permissions });
    }
  };

  getSubscriptionsGuids = async (level = "Owner", all = false) => {
    if (utils.isUserAdmin()) {
      // Admin can see all subscriptions

      if (level !== "Owner") {
        // Request for viewed subscription is ignored to avoid duplication of licenses
        return [];
      }

      // Only admin can use this endpoint
      let customers = (await api.getCustomersFull(false)).data.data;
      if (all) return customers;
      return customers.map((c) => c.customerGuid);
    } else {
      const subscriptions = this.state.permissionsCustomer.byId;

      return Object.keys(subscriptions).filter(
        (x) => subscriptions[x].level === level
      );
    }
  };

  activateCode = async () => {
    const response = await api.disableActivationCode(
      this.state.newActivationCode
    );

    if (response === null || !response.status || response.status !== 200) {
      throw new Error(
        "Invalid response\nThe edit of the activation code failed"
      );
    }

    this.setState({ newActivationCode: null });

    return response;
  };

  addLicensesToSubscriptions = async () => {
    this.setState({ isLoading: true });
    let done = false;
    try {
      let ownedSubscriptionGuids = [];
      let licenseGuids = [];
      let editACResponse;

      if (!utils.isUserAdmin()) {
        // Get owned subscriptions
        ownedSubscriptionGuids = await this.getSubscriptionsGuids();
      } else {
        ownedSubscriptionGuids = this.state.allSubscriptions
          .filter((a) => a.checkboxStatus)
          .map((s) => s.customerGuid);
      }

      if (!ownedSubscriptionGuids || ownedSubscriptionGuids.length === 0)
        throw new Error("Invalid list of subscriptions");

      // Activate code and get licenses
      editACResponse = await this.activateCode();

      // Array of licenseGuids
      licenseGuids = editACResponse.data.licenses.map((x) => x.licenseGuid);

      // Create a bridge between each owned subscription and each license (NxM)
      await api.addLicenseToSubscription(ownedSubscriptionGuids, licenseGuids);
      done = true;
    } catch (e) {
      utils.debug(e.toString(), utils.MSG_TYPE_ERR);
    }

    // Reload licenses
    if (this.props.getLicenses && done) await this.props.getLicenses();

    this.setState({ isLoading: false, showModal: false });
  };

  toggleModal = (status = undefined) => {
    this.setState({
      newActivationCode: null,
      showModal:
        status !== undefined && isBoolean(status.toString())
          ? status
          : !this.state.modal,
    });
  };

  render() {
    return (
      <div>
        {!this.props.children ? (
          <Button
            color="primary"
            onClick={this.toggleModal}
            className="btn-add-license"
          >
            {this.props.t("license.addLicense")}
          </Button>
        ) : (
          <div onClick={this.toggleModal}>{this.props.children}</div>
        )}

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          centered
          className="license-add-modal"
        >
          {this.state.isLoading ? <Loader /> : null}
          <ModalBody>
            <div className="mt-1">
              <span className="h3">
                {this.props.t("license.enterActivationCode") + ":"}
              </span>
              <span
                className="license-modal-close"
                onClick={() => this.toggleModal(false)}
              >
                X
              </span>
            </div>
            <Input
              placeholder={this.props.t("license.activationCode")}
              value={this.state.newActivationCode || ""}
              onChange={(e) => {
                this.setState({ newActivationCode: e.target.value });
              }}
            />
            {Object.keys(this.state.permissionsCustomer.byId).filter(
              (subGuid) =>
                this.state.permissionsCustomer.byId[subGuid].level === "Owner"
            ).length === 0 && !utils.isUserAdmin() ? (
              <div className="mt-2 border border-warning rounded p-2">
                {this.props.t("license.noOwnedSubscriptionsWarning")}
              </div>
            ) : null}
            <Button
              color="primary"
              className="float-right mt-4"
              disabled={
                !this.state.newActivationCode ||
                Object.keys(this.state.permissionsCustomer.byId).filter(
                  (subGuid) =>
                    this.state.permissionsCustomer.byId[subGuid].level ===
                    "Owner"
                ).length === 0
              }
              onClick={this.addLicensesToSubscriptions}
            >
              {this.props.t("license.addLicense")}
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissionsCustomer: state.permissions.customers,
  };
};

export default withTranslation("common")(
  connect(mapStateToProps)(LicenseModal)
);
