import React, { Component } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  ButtonGroup,
  Button,
} from "reactstrap";
import { TooltipButton } from "../components/Tooltip";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPermissions } from "../redux/actions/index";
import Loader from "./Loader";
import PermissionsSettings from "./Forms/PermissionsSettings";
import api from "../services/backendService";
import keys from "../configs/constants";
import "./permissionCards.css";
import ListDropdown from "./ListDropdown";

import { withTranslation } from "react-i18next";

class PermissionCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: this.initData(),
      users: {},
      filterValue: "",
      searchBy: "all",
      isLoading: true,
      changes: 0,
      isChanging: false,
    };
  }

  initData = () => {
    let permissions = { ...this.props.permissions };

    for (let siteGuid of permissions.sites.allIds) {
      permissions.sites.byId[siteGuid].isOpen = false;
      permissions.sites.byId[siteGuid].activeTab = 0;
      permissions.sites.byId[siteGuid].remove = false;
      for (let userSite of permissions.sites.byId[siteGuid].permissionSites) {
        userSite.remove = false;
      }
      for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
        gw.remove = false;
        gw.isOpen = false;
        for (let userGateway of permissions.gateways.byId[gw.gatewayGuid]
          .permissionGateways) {
          userGateway.remove = false;
        }
      }
    }

    return permissions;
  };

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    api.getPermissionCustomer().then((response) => {
      let { users } = this.state;

      for (let customer of response.data.data) {
        users = {
          ...users,
          [customer.customer.customerGuid]: { customerUsers: [] },
        };
        for (let permission of customer.permissionCustomers) {
          let user = permission.user;
          user.username = permission.user.username;
          user.checkboxStatus = false;

          let { customerUsers } =
            users["" + customer.customer.customerGuid + ""];

          customerUsers.push(user);
        }
      }

      this.setState({ isLoading: false, users });
    });
  };

  changeSiteTab = (siteGuid, index) => {
    let { permissions } = this.state;

    permissions.sites.byId[siteGuid].activeTab = index;

    this.setState({ permissions });
  };

  toggleGatewayCard = (siteGuid, gatewayGuid) => {
    let { permissions } = this.state;

    for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
      if (gw.gatewayGuid === gatewayGuid) {
        gw.isOpen = !gw.isOpen;
        break;
      }
    }

    this.setState({ permissions });
  };

  changePermission = async (entityType, entityGuid, userGuid, newLevel) => {
    let { permissions } = this.state;

    let siteChanges = 0;
    let gatewayChanges = 0;

    switch (entityType) {
      case "Sites":
        for (let user of permissions.sites.byId[entityGuid].permissionSites) {
          if (user.user.userGuid === userGuid) {
            if (user.prevLevel === undefined) {
              user.prevLevel = user.level;
            }
            user.level = newLevel;
            break;
          }
        }
        break;
      case "Gateways":
        for (let user of permissions.gateways.byId[entityGuid]
          .permissionGateways) {
          if (user.user.userGuid === userGuid) {
            if (user.prevLevel === undefined) {
              user.prevLevel = user.level;
            }
            user.level = newLevel;
            break;
          }
        }
        break;
      default:
        return;
    }

    for (let siteGuid of permissions.sites.allIds) {
      const { permissionSites } = permissions.sites.byId[siteGuid];
      for (let user of permissionSites) {
        if (user.prevLevel !== undefined && user.prevLevel !== user.level)
          siteChanges++;
      }
    }

    for (let gatewayGuid of permissions.gateways.allIds) {
      const { permissionGateways } = permissions.gateways.byId[gatewayGuid];
      for (let user of permissionGateways) {
        if (user.prevLevel !== undefined && user.prevLevel !== user.level)
          gatewayChanges++;
      }
    }

    this.setState({ permissions, changes: siteChanges + gatewayChanges });
  };

  addUsersToCustomer = async (userList = [], newUsers = false) => {
    this.setState({ isChanging: true });

    if (userList.length > 0) {
      if (newUsers) await api.addUsers(userList);
      else await api.updatePermissionCustomer(userList);
    }
    this.setState({ isChanging: false });
  };

  removeUsersFromCustomer = async (userList = []) => {
    this.setState({ isChanging: true });
    for (let user of userList) {
      await api.deletePermissionCustomer(user.customerGuid, user.userGuid);
    }
    this.setState({ isChanging: false });
  };

  addUserToEntity = async (permArray, apiFunction, entity) => {
    this.setState({ isChanging: true });
    let response = await apiFunction(permArray);

    if (response.status !== 200) {
      let { permissions } = this.state;

      if (entity === "Gateways") {
        // Bad response (perhaps the limit of users per gateway has been reached). Reload anyway the list
        let newPermissions = await api.getPermissions();

        for (let i = 0; i < permArray.length; i++) {
          permissions.gateways.byId[
            permArray[i].gatewayGuid
          ].permissionGateways =
            newPermissions.gateways.byId[
              permArray[i].gatewayGuid
            ].permissionGateways;
        }

        this.setState({ permissions });
      }

      this.setState({ isChanging: false });
      return;
    }

    this.props.setPermissions(await api.getPermissions());

    let { permissions } = this.state;

    for (let perm of permArray) {
      if (entity === "Sites") {
        const { customerGuid } =
          permissions.sites.byId[perm.siteGuid].site.customer;

        let user = {};
        for (let permissionCustomer of permissions.customers.byId[customerGuid]
          .permissionCustomers) {
          if (permissionCustomer.user.userGuid === perm.userGuid) {
            user = permissionCustomer.user;
            break;
          }
        }

        let newPerm = {
          user: user,
          level: perm.level,
          permissionSites: [],
          remove: false,
          site: permissions.sites.byId[perm.siteGuid].site,
        };

        permissions.sites.byId[perm.siteGuid].permissionSites.push(newPerm);
      } else if (entity === "Gateways") {
        const { customerGuid } =
          permissions.gateways.byId[perm.gatewayGuid].gateway.customer;

        let user = {};
        for (let permissionCustomer of permissions.customers.byId[customerGuid]
          .permissionCustomers) {
          if (permissionCustomer.user.userGuid === perm.userGuid) {
            user = permissionCustomer.user;
          }
        }

        let newPerm = {
          user: user,
          level: perm.level,
          permissionGateways: [],
          remove: false,
          gateway: permissions.gateways.byId[perm.gatewayGuid].gateway,
        };
        permissions.gateways.byId[perm.gatewayGuid].permissionGateways.push(
          newPerm
        );
      }
    }

    this.setState({ permissions, isChanging: false });
  };

  removeUserFromEntity = async (entityGuid, userGuid, apiFunction, entity) => {
    this.setState({ isChanging: true });
    let response = await apiFunction(entityGuid, userGuid);
    if (response.status !== 200) {
      this.setState({ isChanging: false });
      return;
    }

    this.props.setPermissions(await api.getPermissions());

    let { permissions } = this.state;

    let i = 0;

    if (entity === "Sites") {
      for (let permissionSite of permissions.sites.byId[entityGuid]
        .permissionSites) {
        if (permissionSite.user.userGuid === userGuid) break;
        i++;
      }
      permissions.sites.byId[entityGuid].permissionSites.splice(i, 1);
    } else if (entity === "Gateways") {
      for (let permissionGateway of permissions.gateways.byId[entityGuid]
        .permissionGateways) {
        if (permissionGateway.user.userGuid === userGuid) break;
        i++;
      }

      permissions.gateways.byId[entityGuid].permissionGateways.splice(i, 1);
    }

    this.setState({ permissions, isChanging: false });
  };

  forgetChanges = () => {
    let { permissions } = this.state;

    for (let siteGuid of permissions.sites.allIds) {
      const { permissionSites } = permissions.sites.byId[siteGuid];
      for (let user of permissionSites) {
        if (user.prevLevel !== undefined) user.level = user.prevLevel;
      }
    }

    for (let gatewayGuid of permissions.gateways.allIds) {
      const { permissionGateways } = permissions.gateways.byId[gatewayGuid];
      for (let user of permissionGateways) {
        if (user.prevLevel !== undefined) user.level = user.prevLevel;
      }
    }

    this.setState({ permissions, changes: 0 });
  };

  saveChanges = async () => {
    this.setState({ isChanging: true });

    let { permissions } = this.state;
    let newPermissions = { sites: [], gateways: [] };

    for (let siteGuid of permissions.sites.allIds) {
      let { permissionSites } = permissions.sites.byId[siteGuid];
      for (let user of permissionSites) {
        if (user.prevLevel !== undefined && user.level !== user.prevLevel) {
          // Add user to list
          newPermissions.sites.push({
            siteGuid: siteGuid,
            userGuid: user.user.userGuid,
            level: user.level,
          });
        }
      }
    }

    for (let gatewayGuid of permissions.gateways.allIds) {
      let { permissionGateways } = permissions.gateways.byId[gatewayGuid];
      for (let user of permissionGateways) {
        if (user.prevLevel !== undefined && user.level !== user.prevLevel) {
          // Add user to list
          newPermissions.gateways.push({
            gatewayGuid: gatewayGuid,
            userGuid: user.user.userGuid,
            level: user.level,
          });
        }
      }
    }

    let response = await api.updatePermissionSite(newPermissions.sites);

    if (response.status !== 200) {
      this.setState({ isChanging: false });
      this.forgetChanges();
      return;
    }

    for (let siteGuid of permissions.sites.allIds) {
      let { permissionSites } = permissions.sites.byId[siteGuid];
      for (let user of permissionSites) {
        if (user.prevLevel !== undefined && user.level !== user.prevLevel) {
          delete user.prevLevel;
        }
      }
    }

    response = await api.updatePermissionGateway(newPermissions.gateways);

    if (response.status !== 200) {
      this.setState({ isChanging: false });
      this.forgetChanges();
      return;
    }

    for (let gatewayGuid of permissions.gateways.allIds) {
      let { permissionGateways } = permissions.gateways.byId[gatewayGuid];
      for (let user of permissionGateways) {
        if (user.prevLevel !== undefined && user.level !== user.prevLevel) {
          delete user.prevLevel;
        }
      }
    }

    this.props.setPermissions(await api.getPermissions());

    this.setState({ permissions, isChanging: false, changes: 0 });
  };

  render() {
    if (this.state.isLoading) return <Loader />;

    let { permissions } = this.state;

    let remove;

    switch (this.state.searchBy) {
      case "all":
        // All
        for (let siteGuid of permissions.sites.allIds) {
          let countGw = 0;
          let countStUsr = 0;
          for (let permSt of permissions.sites.byId[siteGuid].permissionSites) {
            if (
              permSt.user.email
                .toLowerCase()
                .includes(this.state.filterValue.toLowerCase())
            ) {
              permSt.remove = false;
              countStUsr++;
            } else {
              permSt.remove = true;
            }
          }
          for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
            // Check for users on gateways
            let countGwUsr = 0;
            for (let permGw of permissions.gateways.byId[gw.gatewayGuid]
              .permissionGateways) {
              if (
                permGw.user.email
                  .toLowerCase()
                  .includes(this.state.filterValue.toLowerCase())
              ) {
                permGw.remove = false;
                countGwUsr++;
              } else {
                permGw.remove = true;
              }
            }

            // Check for gateways
            remove =
              this.state.filterValue === "" || countGwUsr > 0
                ? false
                : !gw.name
                    .toLowerCase()
                    .includes(this.state.filterValue.toLowerCase());

            gw.remove = remove;

            if (!remove) countGw++;
          }
          remove =
            this.state.filterValue === "" || countStUsr > 0
              ? false
              : !(
                  permissions.sites.byId[siteGuid].site.name
                    .toLowerCase()
                    .includes(this.state.filterValue.toLowerCase()) ||
                  permissions.sites.byId[siteGuid].site.customer.name
                    .toLowerCase()
                    .includes(this.state.filterValue.toLowerCase()) ||
                  countGw > 0
                );
          permissions.sites.byId[siteGuid].remove = remove;
          if (!remove && countGw === 0) {
            for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
              gw.remove = false;
            }
          }
        }

        break;
      case "subscription":
        // Subscription (i.e. customer)
        for (let siteGuid of permissions.sites.allIds) {
          remove =
            this.state.filterValue === ""
              ? false
              : !permissions.sites.byId[siteGuid].site.customer.name
                  .toLowerCase()
                  .includes(this.state.filterValue.toLowerCase());
          permissions.sites.byId[siteGuid].remove = remove;
          for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
            gw.remove = false;
          }
        }

        break;
      case "site":
        // Site
        for (let siteGuid of permissions.sites.allIds) {
          remove =
            this.state.filterValue === ""
              ? false
              : !permissions.sites.byId[siteGuid].site.name
                  .toLowerCase()
                  .includes(this.state.filterValue.toLowerCase());
          permissions.sites.byId[siteGuid].remove = remove;
          for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
            gw.remove = false;
          }
        }
        break;
      case "gateway":
        // Gateway
        for (let siteGuid of permissions.sites.allIds) {
          permissions.sites.byId[siteGuid].remove = false;
          for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
            remove =
              this.state.filterValue === ""
                ? false
                : !gw.name
                    .toLowerCase()
                    .includes(this.state.filterValue.toLowerCase());
            gw.remove = remove;
          }
          let gwCount = permissions.sites.byId[siteGuid].site.gateways.filter(
            (gw) => !gw.remove
          );

          if (gwCount.length === 0)
            permissions.sites.byId[siteGuid].remove = true;
        }
        break;
      case "email":
        // Email
        let userGwCount = 0;
        let userStCount = 0;

        // Init to false
        for (let siteGuid of permissions.sites.allIds) {
          permissions.sites.byId[siteGuid].remove = false;
          for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
            gw.remove = false;
          }
        }

        for (let gatewayGuid of permissions.gateways.allIds) {
          userGwCount = 0;
          for (let user of permissions.gateways.byId[gatewayGuid]
            .permissionGateways) {
            remove =
              this.state.filterValue === ""
                ? false
                : !(
                    user.user.email
                      .toLowerCase()
                      .includes(this.state.filterValue.toLowerCase()) &&
                    user.level !== "Unauthorized"
                  );
            user.remove = remove;

            if (remove === false) userGwCount++;
          }

          if (this.state.filterValue !== "" && userGwCount === 0) {
            const { siteGuid } =
              permissions.gateways.byId[gatewayGuid].gateway.site;

            for (let gw of permissions.sites.byId[siteGuid].site.gateways) {
              if (gw.gatewayGuid === gatewayGuid) gw.remove = true;
            }
          }
        }

        for (let siteGuid of permissions.sites.allIds) {
          userStCount = 0;
          for (let user of permissions.sites.byId[siteGuid].permissionSites) {
            remove =
              this.state.filterValue === ""
                ? false
                : !(
                    user.user.email
                      .toLowerCase()
                      .includes(this.state.filterValue.toLowerCase()) &&
                    user.level !== "Unauthorized"
                  );
            user.remove = remove;

            if (remove === false) userStCount++;
          }

          if (
            this.state.filterValue !== "" &&
            userStCount === 0 &&
            permissions.sites.byId[siteGuid].site.gateways.filter(
              (gw) => !gw.remove
            ).length === 0
          )
            permissions.sites.byId[siteGuid].remove = true;
        }

        break;
      default:
        break;
    }

    let customersInfo = { allIds: [], byId: {} };

    for (let customerGuid of permissions.customers.allIds) {
      const customer = permissions.customers.byId[customerGuid];
      if (customer.level === "Owner") {
        customersInfo.allIds.push(customerGuid);
        customersInfo.byId = {
          ...customersInfo.byId,
          [customerGuid]: customer,
        };
      }
    }

    const countOwnedCustomers = customersInfo.allIds.length;

    return (
      <Container fluid>
        {this.state.isChanging ? <Loader /> : null}
        <Card className="sites-container mt--9">
          <PermissionsSettings
            value={this.state.filterValue}
            searchBy={this.state.searchBy}
            onValueEdit={(value) => this.setState({ filterValue: value })}
            onSearchChange={(value) => this.setState({ searchBy: value })}
            changes={this.state.changes}
            onForget={this.forgetChanges}
            onSave={this.saveChanges}
            customersInfo={customersInfo}
            addUsersToCustomer={this.addUsersToCustomer}
            removeUsersFromCustomer={this.removeUsersFromCustomer}
            t={this.props.t}
            getUsers={this.getUsers}
            setPermissions={async () => {
              this.props.setPermissions(await api.getPermissions());
              this.setState({ permissions: this.initData() });
            }}
          />
          {permissions.sites.allIds.length === 0 ? (
            <div className="m-4 p-2 bg-warning text-white rounded">
              {this.props.t("permissions.noPermission")}
            </div>
          ) : (
            permissions.sites.allIds.map((siteGuid) => {
              const site = permissions.sites.byId[siteGuid];

              if (site.remove) return null;

              const siteInfo = site.site;

              let gateways = [];

              for (let gw of siteInfo.gateways) {
                let gateway = permissions.gateways.byId[gw.gatewayGuid];
                gateway.isOpen = gw.isOpen;
                if (!gw.remove) gateways.push(gateway);
              }

              return (
                <Card key={siteGuid} className="site-card m-4">
                  <CardHeader
                    className="bg-primary text-white header-container"
                    onClick={() => {
                      permissions.sites.byId[siteGuid].isOpen =
                        !permissions.sites.byId[siteGuid].isOpen;
                      this.setState({ permissions });
                    }}
                  >
                    <div className="header-info text-white text-center">
                      <span>
                        <div className="h2 mb--1 text-white">
                          <span>{siteInfo.name}</span>
                          <span className="user-level-icon">
                            {site.level === "Owner"
                              ? keys.ICON_ENTITY_OWNER
                              : keys.ICON_ENTITY_VIEWER}
                          </span>
                        </div>
                        <div className="h5 mt-0 text-white">
                          {countOwnedCustomers > 1
                            ? siteInfo.customer.name
                            : ""}
                        </div>
                        <div>{siteInfo.city + ", " + siteInfo.country}</div>
                        <div className="h5 mt-2 text-white">
                          {siteInfo.gateways.length +
                            " gateway" +
                            (siteInfo.gateways.length !== 1 ? "s" : "")}
                        </div>
                      </span>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={site.isOpen}>
                    <CardBody>
                      {/*!site.isOpen ? "info" : null*/}
                      <SiteProperties
                        currentUser={this.props.user}
                        site={site}
                        gateways={gateways}
                        changeSiteTab={this.changeSiteTab}
                        siteGuid={siteInfo.siteGuid}
                        level={site.level}
                        users={this.state.users}
                        toggleGatewayCard={this.toggleGatewayCard}
                        changePermission={this.changePermission}
                        addUserToEntity={this.addUserToEntity}
                        removeUserFromEntity={this.removeUserFromEntity}
                        t={this.props.t}
                      />
                    </CardBody>
                  </Collapse>
                </Card>
              );
            })
          )}
        </Card>
      </Container>
    );
  }
}

class SiteProperties extends Component {
  changePermission = (entityType, entityGuid, userGuid, level) => {
    const newLevel =
      level.charAt(0).toUpperCase() + level.slice(1).toLowerCase();

    this.props.changePermission(entityType, entityGuid, userGuid, newLevel);
  };

  render() {
    const { site, siteGuid, gateways } = this.props;
    let { activeTab } = site;

    if (this.props.level !== "Owner") activeTab = 1;

    let customerUsers = {};

    for (let user of this.props.users[site.site.customer.customerGuid]
      .customerUsers) {
      customerUsers = {
        ...customerUsers,
        [user.userGuid]: user,
      };
    }

    return (
      <>
        <Nav tabs>
          <NavItem hidden={this.props.level === "Viewer"}>
            <NavLink
              className={`${
                activeTab === 0 ? "active" : "none"
              } site-nav-link `}
              onClick={() => this.props.changeSiteTab(siteGuid, 0)}
            >
              {this.props.t("permissions.siteUsers")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${
                activeTab === 1 ? "active" : "none"
              } site-nav-link `}
              onClick={() => this.props.changeSiteTab(siteGuid, 1)}
            >
              {this.props.t("permissions.gateway")}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
            <PermissionTable
              currentUser={this.props.currentUser}
              entityGuid={siteGuid}
              permissionEntity={site.permissionSites}
              entityType={"Sites"}
              changePermission={this.changePermission}
              users={customerUsers}
              addUserToEntity={this.props.addUserToEntity}
              removeUserFromEntity={this.props.removeUserFromEntity}
              t={this.props.t}
            />
          </TabPane>
          <TabPane tabId={1}>
            {gateways.map((gw, i) => {
              let usersForGw = {};

              // Don't include users of this customer which have not a permission on this site
              for (let userForCs of this.props.users[
                gw.gateway.customer.customerGuid
              ].customerUsers) {
                for (let permissionSite of this.props.site.permissionSites) {
                  if (
                    userForCs.userGuid === permissionSite.user.userGuid &&
                    permissionSite.level !== "Unauthorized"
                  ) {
                    usersForGw = {
                      ...usersForGw,
                      [userForCs.userGuid]: userForCs,
                    };
                    break;
                  }
                }
              }

              return (
                <div key={i}>
                  <Card>
                    <CardHeader
                      className={`${
                        gw.level === "Owner" ? "bg-success text-white" : ""
                      } header-container`}
                      onClick={() =>
                        this.props.toggleGatewayCard(
                          gw.gateway.site.siteGuid,
                          gw.gateway.gatewayGuid
                        )
                      }
                    >
                      <span>
                        {gw.gateway.name + " (" + gw.gateway.macAddress + ")"}
                      </span>
                      <span className="user-level-icon">
                        {gw.level === "Owner"
                          ? keys.ICON_ENTITY_OWNER
                          : keys.ICON_ENTITY_VIEWER}
                      </span>
                    </CardHeader>
                    {gw.level !== "Owner" ? null : (
                      <Collapse isOpen={gw.isOpen}>
                        <CardBody>
                          <div>
                            <span className="mr-2">
                              {this.props.t("permissions.gatewayUsers")}
                            </span>
                            <span
                              className="permission-info-icon"
                              id={"gw-" + gw.gateway.gatewayGuid}
                            >
                              {keys.ICON_INFO}
                            </span>
                            <TooltipButton
                              target={"gw-" + gw.gateway.gatewayGuid}
                              text={
                                <div>
                                  <h5>
                                    {gw.gateway.devices.length +
                                      " controller" +
                                      (gw.gateway.devices.length !== 1
                                        ? "s"
                                        : "")}
                                  </h5>
                                  {gw.gateway.devices.map((dev, i) => {
                                    return (
                                      <div key={i}>
                                        {dev.friendlyName !== undefined &&
                                        dev.friendlyName !== null &&
                                        dev.friendlyName !== ""
                                          ? dev.friendlyName
                                          : dev.model}
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                            />
                          </div>
                          <PermissionTable
                            currentUser={this.props.currentUser}
                            entityGuid={gw.gateway.gatewayGuid}
                            permissionEntity={gw.permissionGateways}
                            entityType={"Gateways"}
                            changePermission={this.changePermission}
                            users={usersForGw}
                            addUserToEntity={this.props.addUserToEntity}
                            removeUserFromEntity={
                              this.props.removeUserFromEntity
                            }
                            t={this.props.t}
                          />
                        </CardBody>
                      </Collapse>
                    )}
                  </Card>
                </div>
              );
            })}
          </TabPane>
        </TabContent>
      </>
    );
  }
}

class PermissionTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newUserPermission: "Viewer",
      userGuid: null,
      users: props.users,
    };
  }

  componentDidUpdate() {
    if (this.props.users !== this.state.users) {
      this.setState({ users: this.props.users });
    }
  }

  render() {
    const { entityGuid, permissionEntity, entityType, currentUser } = this.props;

    let userList = [];
    let checkedUsers = 0;

    for (let userGuid of Object.keys(this.state.users)) {
      const user = this.state.users[userGuid];
      const item = user.username + " (" + user.email + ")";
      let permissionFound = false;

      for (let permission of permissionEntity) {
        if (
          user.userGuid === permission.user.userGuid &&
          permission.level !== "Unauthorized"
        ) {
          permissionFound = true;
          break;
        }
      }

      if (!permissionFound && (currentUser.email !== user.email)) {
        userList.push({
          text: item,
          checkbox: true,
          checkboxStatus: user.checkboxStatus,
          command: () => {
            let { users } = this.state;
            users[userGuid].checkboxStatus = !users[userGuid].checkboxStatus;
            this.setState({ users });
          },
        });
      }

      if (user.checkboxStatus) checkedUsers++;
    }

    return (
      <>
        <Table size="sm" className="my-2 permission-table" responsive bordered>
          <thead className="bg-secondary">
            <tr>
              <th>{this.props.t("permissions.username")}</th>
              <th>{this.props.t("permissions.email")}</th>
              <th>{this.props.t("permissions.level")}</th>
            </tr>
          </thead>
          <tbody>
            {permissionEntity.map((p, i) => {
              if (p.level === "Unauthorized" || p.remove) return null;
              return (
                <tr key={i}>
                  <td>{p.user.username}</td>
                  <td>{p.user.email}</td>
                  <td className="permissions-edit-buttons">
                    <PermissionButtons
                      entityType={entityType}
                      level={p.level}
                      userGuid={p.user.userGuid}
                      entityGuid={entityGuid}
                      changePermission={this.props.changePermission}
                      t={this.props.t}
                    />
                    <span
                      className="icon-delete"
                      onClick={() => {
                        const { entityGuid, entityType } = this.props;

                        let apiFunction =
                          entityType === "Sites"
                            ? api.deletePermissionSite
                            : entityType === "Gateways"
                            ? api.deletePermissionGateway
                            : null;

                        this.props.removeUserFromEntity(
                          entityGuid,
                          p.user.userGuid,
                          apiFunction,
                          entityType
                        );
                      }}
                    >
                      {keys.ICON_TRASH}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <span className="new-user-permission-container">
          <ListDropdown
            title={
              this.props.t("permissions.selectUsers") +
              `...${checkedUsers > 0 ? "(" + checkedUsers + ")" : ""}`
            }
            listItems={userList}
            className="new-user-permission-dropdown"
            toggleMenu="new-user-permission-menu"
          />
          <ListDropdown
            title={this.props.t(
              "permissions." + this.state.newUserPermission.toLowerCase()
            )}
            listItems={[
              {
                text: this.props.t("permissions.viewer"),
                command: () => this.setState({ newUserPermission: "Viewer" }),
              },
              {
                text: this.props.t("permissions.owner"),
                command: () => this.setState({ newUserPermission: "Owner" }),
              },
            ]}
            className="new-user-permission-dropdown"
            toggleMenu="new-user-permission-menu"
          />
          <Button
            className="new-user-permission-button"
            color={"primary"}
            disabled={checkedUsers === 0}
            onClick={() => {
              const { entityGuid, entityType } = this.props;

              let permArray = [];
              let entityTypeGuid =
                (entityType === "Sites"
                  ? "site"
                  : entityType === "Gateways"
                  ? "gateway"
                  : "") + "Guid";

              let apiFunction =
                entityType === "Sites"
                  ? api.updatePermissionSite
                  : entityType === "Gateways"
                  ? api.updatePermissionGateway
                  : null;

              let { users } = this.state;

              for (let user of Object.values(users)) {
                if (user.checkboxStatus) {
                  permArray.push({
                    [entityTypeGuid]: entityGuid,
                    userGuid: user.userGuid,
                    level: this.state.newUserPermission,
                  });
                  user.checkboxStatus = false;
                }
              }

              this.setState({ users });

              this.props.addUserToEntity(permArray, apiFunction, entityType);
            }}
          >
            {this.props.t("permissions.addUsersButton")}
          </Button>
        </span>
      </>
    );
  }
}

class PermissionButtons extends Component {
  render() {
    const { entityType, level, userGuid, entityGuid } = this.props;

    return (
      <ButtonGroup>
        <Button
          size="sm"
          className={`btn-permission ${
            level === "Viewer" ? "btn-permission-on" : ""
          }`}
          name="viewer"
          level="Viewer"
          color={level === "Viewer" ? "success" : "secondary"}
          active={false}
          onClick={(e) => {
            if (level !== "Viewer")
              this.props.changePermission(
                entityType,
                entityGuid,
                userGuid,
                e.target.name
              );
          }}
        >
          {this.props.t("permissions.viewer")}
        </Button>
        <Button
          size="sm"
          className={`btn-permission ${
            level === "Owner" ? "btn-permission-on" : ""
          }`}
          name="owner"
          level="Owner"
          color={level === "Owner" ? "success" : "secondary"}
          active={false}
          onClick={(e) => {
            if (level !== "Owner")
              this.props.changePermission(
                entityType,
                entityGuid,
                userGuid,
                e.target.name
              );
          }}
        >
          {this.props.t("permissions.owner")}
        </Button>
      </ButtonGroup>
    );
  }
}

const mapStateToProps = (state) => ({
  permissions: state.permissions,
  user: state.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPermissions,
    },
    dispatch
  );

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(PermissionCards)
);
