import i18next from "i18next";

import en_GB from "./English_en-GB.json";

export const selectLanguage = (lang = { "en-GB": { common: en_GB } }) => {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: Object.keys(lang)[0],
    resources: lang,
  });
};
