import keys from "configs/constants";
import { getAPICallAuthHeader } from "./backendService";

const callCoreIAM = async (method, endpoint, body) => {
  const url = `${keys.CORE_API_URL}/${endpoint}`;
  const result = {};
  const request = {};

  request.method = method;
  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    headers: {
      ...config.headers,
      "Content-Type":
        method === "PATCH"
          ? "application/json-patch+json; charset=utf-8"
          : "application/json; charset=utf-8",
    },
  };
  request.headers = config.headers;
  request.body = body ? JSON.stringify(body) : null;

  const response = await fetch(url, request);
  result.status = response.status;
  result.data = null;
  result.responseString = "";

  if (!response.ok) {
    result.responseString = (
      response.statusText ||
      response.status ||
      "Unknown Error"
    ).toString();
    return Promise.reject(result);
  }
  if (response.status !== 204) {
    result.data = await response.json();
    result.responseString = (
      response.data ||
      response.statusText ||
      response.status ||
      "Unknown Error"
    ).toString();
  }
  return Promise.resolve(result.data);
};

export async function getSelfAsync() {
  return await callCoreIAM("GET", "users/me", undefined);
}

export async function getSelfGroups() {
  return await callCoreIAM("GET", "groups", undefined);
}

const mapKeyForPatchSettings = (key) => {
  switch (key.toString().toLowerCase()) {
    case "lang":
      return "settings/language";
    case "temp_unit":
      return "settings/temperatureUnit";
    case "press_unit":
      return "settings/pressureUnit";
    default:
      return key;
  }
};

export async function patchSelfAsync(props) {
  const body = Object.entries(props).map(([key, value]) => ({
    op: "replace",
    path: `/${mapKeyForPatchSettings(key)}`,
    value: value,
  }));

  return await callCoreIAM("PATCH", "users/me", body);
}
