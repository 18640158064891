import React, { Component } from "react";
import {
  Form,
  Label,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Loader from "components/Loader";
import ListDropdown from "components/ListDropdown";
import validator from "validator";
import utils from "utils";
import "./style.css";

class PermissionsSettings extends Component {
  constructor(props) {
    super(props);

    let customersInfo = { ...props.customersInfo };

    let defaultValueForRemove = customersInfo.allIds.length === 1;

    for (let customerGuid of customersInfo.allIds) {
      customersInfo.byId[customerGuid].checkboxStatus = defaultValueForRemove;
      for (let permission of customersInfo.byId[customerGuid]
        .permissionCustomers) {
        permission.checkboxStatus = false;
      }
    }

    this.state = {
      isDropDownOpen: false,
      isModalOpen: false,
      userTab: 0,
      user: "",
      userGuid: null,
      customersInfo: customersInfo,
      emails: "",
      newUserPermission: "Viewer",
      isLoading: false,
    };

    this.selections = ["All"];

    if (customersInfo.allIds.length > 1) this.selections.push("Subscription");

    this.selections.push("Site", "Gateway", "Email");
  }

  toggleModal = (modalState) => {
    let { customersInfo } = this.state;

    for (let customerGuid of customersInfo.allIds) {
      if (customersInfo.allIds.length > 1)
        customersInfo.byId[customerGuid].checkboxStatus = false;
      for (let permission of customersInfo.byId[customerGuid]
        .permissionCustomers) {
        permission.checkboxStatus = false;
      }
    }

    this.setState({
      isModalOpen: modalState,
      userTab: 0,
      userGuid: null,
      customersInfo,
      emails: "",
      newUserPermission: "Viewer",
      isLoading: false,
    });
  };

  buttonAction = async (e) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    let userList = [];
    let newUserList = [];

    if (e.target.id === "add") {
      let emails = utils.getAlertReceiversArray(this.state.emails);

      for (let customerGuid of this.state.customersInfo.allIds) {
        const customer = this.state.customersInfo.byId[customerGuid];
        if (customer.checkboxStatus) {
          for (let email of emails) {
            let found = false;
            for (let permission of customer.permissionCustomers) {
              if (permission.user.email === email) {
                userList.push({
                  userGuid: permission.user.userGuid,
                  customerGuid: customerGuid,
                  level: this.state.newUserPermission,
                });
                found = true;
                break;
              }
            }

            if (!found) {
              // Add user to subscription
              newUserList.push({
                email: email,
                customerGuid: customerGuid,
                level: this.state.newUserPermission,
              });
            }
          }
        }
      }

      if (newUserList.length > 0)
        await this.props.addUsersToCustomer(newUserList, true);
      if (userList.length > 0)
        await this.props.addUsersToCustomer(userList, false);
    } else if (e.target.id === "edit") {
      await this.props.addUsersToCustomer(this.getSelectedUsers(false), false);
    } else if (e.target.id === "remove") {
      await this.props.removeUsersFromCustomer(this.getSelectedUsers(true));
    }

    await this.props.setPermissions();

    this.props.getUsers();

    this.updateCustomerInfo();

    this.toggleModal(false);
  };

  updateCustomerInfo = () => {
    let customersInfo = { ...this.props.customersInfo };

    let defaultValueForRemove = customersInfo.allIds.length === 1;

    for (let customerGuid of customersInfo.allIds) {
      customersInfo.byId[customerGuid].checkboxStatus = defaultValueForRemove;
      for (let permission of customersInfo.byId[customerGuid]
        .permissionCustomers) {
        permission.checkboxStatus = false;
      }
    }

    this.setState({ customersInfo });
  };

  getSelectedUsers = (remove = false) => {
    let userList = [];
    let { customersInfo } = this.state;
    for (let customerGuid of customersInfo.allIds) {
      if (customersInfo.byId[customerGuid].checkboxStatus) {
        let removedUsers = [];
        let i = 0;
        for (let permission of customersInfo.byId[customerGuid]
          .permissionCustomers) {
          if (permission.checkboxStatus) {
            // Remove user from subscription
            let newUser = {
              customerGuid:
                customersInfo.byId[customerGuid].customer.customerGuid,
              userGuid: permission.user.userGuid,
            };
            if (remove) removedUsers.push(i);
            else newUser.level = this.state.newUserPermission;

            userList.push(newUser);
          }

          i++;
        }
        if (remove) {
          for (let i = removedUsers.length - 1; i >= 0; i--) {
            customersInfo.byId[customerGuid].permissionCustomers.splice(
              removedUsers[i],
              1
            );
          }
          this.setState({ customersInfo });
        }

        return userList;
      }
    }
  };

  renderSubscriptionsList = () => {
    if (this.state.customersInfo.allIds.length <= 1) return null;

    let customerList = [];

    let countSelectedCustomers = 0;

    for (let customerGuid of this.state.customersInfo.allIds) {
      const customer = this.state.customersInfo.byId[customerGuid];
      customerList.push({
        text: customer.customer.name,
        checkbox: true,
        checkboxStatus: customer.checkboxStatus,
        command: () => {
          let { customersInfo } = this.state;
          customersInfo.byId[customer.customer.customerGuid].checkboxStatus =
            !customersInfo.byId[customer.customer.customerGuid].checkboxStatus;

          this.setState({ customersInfo });
        },
      });

      if (
        this.state.customersInfo.byId[customer.customer.customerGuid]
          .checkboxStatus
      )
        countSelectedCustomers++;
    }

    return (
      <ListDropdown
        toggleClass="permission-toggle-list mt-4"
        title={
          this.props.t("permissions.selectSubscriptions") +
          `...${countSelectedCustomers > 0
            ? " (" + countSelectedCustomers + ")"
            : ""
          }`
        }
        listItems={customerList}
      />
    );
  };

  isEmailInvalid = () => {
    let { emails } = this.state;

    for (const email of utils.getAlertReceiversArray(emails)) {
      if (!validator.isEmail(email)) return true;
    }
    return false;
  };

  render() {
    let userList = [];
    let countSelectedCustomers = 0;
    let countSelectedUsers = 0;
    let { customersInfo } = this.state;
    let users = {};

    for (let customerGuid of customersInfo.allIds) {
      if (customersInfo.byId[customerGuid].checkboxStatus) {
        for (let permission of customersInfo.byId[customerGuid]
          .permissionCustomers) {
          users = {
            ...users,
            [permission.user.userGuid]: permission,
          };
        }
        countSelectedCustomers++;
      }
    }

    const groupList = countSelectedCustomers > 1 ? ["multipleSubscriptions"] : ["viewer", "owner"];

    for (let group of groupList) {
      userList.push({
        text: this.props.t(`permissions.${group}`),
        header: true,
        disabled: true
      })
      for (let user of Object.values(users)) {
        if (countSelectedCustomers > 1 || (countSelectedCustomers === 1 && user.level.toLowerCase() === group)) {
          userList.push({
            text: user.user.username + " (" + user.user.email + ")",
            checkbox: true,
            checkboxStatus: user.checkboxStatus,
            command: () => {
              let { customersInfo } = this.state;

              for (let customerGuid of customersInfo.allIds) {
                for (let permission of customersInfo.byId[customerGuid]
                  .permissionCustomers) {
                  if (user.user.userGuid === permission.user.userGuid)
                    permission.checkboxStatus = !permission.checkboxStatus;
                }
              }

              this.setState(customersInfo);
            },
          });
        }
      }
    }

    for (let user of Object.values(users)) {
      if (user.checkboxStatus) countSelectedUsers++;
    }

    return (
      <>
        <Modal
          centered
          isOpen={this.state.isModalOpen}
          toggle={() => this.toggleModal(!this.state.isModalOpen)}
          className="permission-modal"
        >
          <ModalBody className="p-4 m-2 permission-modal-body">
            {this.state.isLoading ? <Loader /> : null}
            <Nav tabs justified fill>
              <NavItem>
                <NavLink
                  className={`${this.state.userTab === 0 ? "active" : "none"
                    } permission-user-nav-link`}
                  onClick={() => this.setState({ userTab: 0 })}
                >
                  {this.props.t("permissions.addUsers")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${this.state.userTab === 1 ? "active" : "none"
                    } permission-user-nav-link`}
                  onClick={() => this.setState({ userTab: 1 })}
                >
                  {this.props.t("permissions.editUsers")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${this.state.userTab === 2 ? "active" : "none"
                    } permission-user-nav-link `}
                  onClick={() => this.setState({ userTab: 2 })}
                >
                  {this.props.t("permissions.removeUsers")}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.userTab}>
              <TabPane tabId={0} className="mt-2">
                <Form>
                  <div className="mb-4">{this.renderSubscriptionsList()}</div>
                  <Label>
                    {this.props.t("deviceThresholds.multipleReceivers") +
                      ' "' +
                      this.props.t("permissions.addUsersButton") +
                      '"'}
                  </Label>
                  <Input
                    className={`${this.isEmailInvalid() ? "border-warning" : ""
                      }`}
                    placeholder="Email..."
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.preventDefault();
                    }}
                    value={this.state.emails}
                    onChange={(e) => this.setState({ emails: e.target.value })}
                  />

                  <ListDropdown
                    toggleClass="permission-toggle-list"
                    title={this.props.t(
                      "permissions." +
                      this.state.newUserPermission.toLowerCase()
                    )}
                    listItems={[
                      {
                        text: this.props.t("permissions.viewer"),
                        command: () =>
                          this.setState({ newUserPermission: "Viewer" }),
                      },
                      {
                        text: this.props.t("permissions.owner"),
                        command: () =>
                          this.setState({ newUserPermission: "Owner" }),
                      },
                    ]}
                  />
                  <Button
                    className="float-right mt-4"
                    id="add"
                    color="success"
                    onClick={(e) => this.buttonAction(e)}
                    disabled={
                      this.state.emails === "" ||
                      this.isEmailInvalid() ||
                      countSelectedCustomers === 0
                    }
                  >
                    {this.props.t("permissions.addUsersButton")}
                  </Button>
                </Form>
              </TabPane>
              <TabPane tabId={1} className="mt-2">
                <Form>
                  <div className="mb-4">{this.renderSubscriptionsList()}</div>
                  <Label>{this.props.t("permissions.selectUsers")}</Label>
                  <ListDropdown
                    className="permission-remove-email-list"
                    toggleClass="m-0"
                    title={
                      this.props.t("permissions.selectUsers") +
                      `...${countSelectedUsers > 0
                        ? " (" + countSelectedUsers + ")"
                        : ""
                      }`
                    }
                    listItems={userList}
                  />
                  <ListDropdown
                    toggleClass="permission-toggle-list"
                    title={this.props.t(
                      "permissions." +
                      this.state.newUserPermission.toLowerCase()
                    )}
                    listItems={[
                      {
                        text: this.props.t("permissions.viewer"),
                        command: () =>
                          this.setState({ newUserPermission: "Viewer" }),
                      },
                      {
                        text: this.props.t("permissions.owner"),
                        command: () =>
                          this.setState({ newUserPermission: "Owner" }),
                      },
                    ]}
                  />
                  <Button
                    className="float-right mt-4"
                    id="edit"
                    color="primary"
                    onClick={(e) => this.buttonAction(e)}
                    disabled={countSelectedUsers === 0}
                  >
                    {this.props.t("permissions.editUsersButton")}
                  </Button>
                </Form>
              </TabPane>
              <TabPane tabId={2} className="mt-2">
                <Form>
                  <div className="mb-4">{this.renderSubscriptionsList()}</div>
                  <Label>{this.props.t("permissions.selectUsers")}</Label>
                  <ListDropdown
                    className="permission-remove-email-list"
                    toggleClass="m-0"
                    toggleMenu="permission-user-list"
                    title={
                      this.props.t("permissions.selectUsers") +
                      `...${countSelectedUsers > 0
                        ? " (" + countSelectedUsers + ")"
                        : ""
                      }`
                    }
                    listItems={userList}
                  />

                  <Button
                    className="float-right mt-4"
                    id="remove"
                    color="warning"
                    onClick={(e) => this.buttonAction(e)}
                    disabled={countSelectedUsers === 0}
                  >
                    {this.props.t("permissions.removeUsersButton")}
                  </Button>
                </Form>
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
        <Button
          className="permission-btn-add-user"
          color="warning"
          onClick={() => this.setState({ isModalOpen: true })}
          disabled={this.state.customersInfo.allIds.length === 0}
        >
          {this.props.t("permissions.manageSubscriptions") + "..."}
        </Button>
        <Form className="m-4 permission-form">
          <span className="permission-search">
            <Input
              className="permission-search-bar"
              placeholder={this.props.t("permissions.search") + "..."}
              value={this.props.value}
              onChange={(e) => this.props.onValueEdit(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") e.preventDefault();
              }}
            />
            <Dropdown
              className="permission-search-dropdown"
              isOpen={this.state.isDropDownOpen}
              toggle={() =>
                this.setState({ isDropDownOpen: !this.state.isDropDownOpen })
              }
            >
              <DropdownToggle
                caret
                color="success"
                className="permission-search-btn"
              >
                {this.props.t("permissions.by") +
                  " " +
                  this.props.t("permissions." + this.props.searchBy)}
              </DropdownToggle>
              <DropdownMenu>
                {this.selections.map((sel, i) => {
                  return (
                    <DropdownItem
                      key={i}
                      onClick={() =>
                        this.props.onSearchChange(sel.toLowerCase())
                      }
                    >
                      {this.props.t("permissions." + sel.toLowerCase())}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </span>
          <span className="permission-buttons">
            <Button
              className="permission-btn"
              color="info"
              onClick={(e) => {
                e.preventDefault();
                this.props.onForget();
              }}
              disabled={this.props.changes === 0}
            >
              {this.props.t("permissions.forget")}
            </Button>
            <Button
              className="permission-btn"
              color="info"
              onClick={(e) => {
                e.preventDefault();
                this.props.onSave();
              }}
              disabled={this.props.changes === 0}
            >
              {this.props.t("permissions.save") +
                (this.props.changes > 0 ? " (" + this.props.changes + ")" : "")}
            </Button>
          </span>
        </Form>
      </>
    );
  }
}

export default PermissionsSettings;
