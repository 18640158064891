import React, { createContext, useState, useContext } from "react";

export const AuthorizationContext = createContext(null);

export const AuthorizationContextProvider = ({ children }) => {
  const [groups, setGroups] = useState([]);

  return (
    <AuthorizationContext.Provider value={{ groups, setGroups }}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export function useAuthorizationContext() {
  const context = useContext(AuthorizationContext);
  if (!context) {
    throw new Error(
      "useAuthorizationContext must be used within a AuthorizationContextProvider"
    );
  }
  return context;
}
