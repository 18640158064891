/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import Loader from "components/Loader";

import { Redirect } from "react-router-dom";

import { connect } from "react-redux";

import utils from "../../utils";
import key from "configs/constants";

import { withTranslation } from "react-i18next";

class CardHeader extends React.Component {
  state = { redirect: false };

  lastDate = 0;
  lastPercentage = 0;

  renderlogAlarmButton = () => {
    return (
      <button
        className="btn btn-danger btn-sm mx-0 my-0 p-0 px-3"
        onClick={() => this.setState({ redirect: true })}
      >
        <h5 className="text-white p-0 m-0">
          {this.props.t("cardHeader.viewAlarms")}
        </h5>
      </button>
    );
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/std/alrlg",
          }}
        />
      );
    }

    const { store, t } = this.props;
    const gateways = store.gateways;
    let totalDevices = 0;
    let onlineCount = 0;
    let percentageDelta = 0;

    let totalUnknownDevices = 0;
    for (const g of Object.values(gateways.byId)) {
      if (g.devices !== undefined) {
        totalDevices += g.devices.length;
        if (g.status === "ON") onlineCount++;
        if (this.lastDate < g.currentDateTime)
          this.lastDate = g.currentDateTime;
        for (const d of g.devices) {
          if (utils.isModelUnknown(d.model)) {
            totalUnknownDevices++;
          }
        }
      }
    }

    let online =
      gateways.allIds.length > 0
        ? (onlineCount / gateways.allIds.length) * 100.0
        : 0;
    if (this.lastPercentage === 0 || this.lastPercentage === online) {
      percentageDelta = 0;
    } else if (this.lastPercentage > online) {
      percentageDelta = -1;
    } else {
      percentageDelta = 1;
    }
    this.lastPercentage = online;

    const sitesInfo = `${store.sites.allIds.length} ${t("cardHeader.sites")}`;

    const gatewaysInfo = `${gateways.allIds.length} ${t(
      "cardHeader.gateways"
    )}`;

    const devicesInfo = `${totalDevices} ${t("cardHeader.controllers")}`;

    const unknownDevicesInfo = `${totalUnknownDevices} ${t(
      "generic.unknowns"
    )}`;

    const { total } = this.props.store.alerts;

    return (
      <>
        <Container fluid>
          <div className="header-body pb-4">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 min-vh-100">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {this.props.t("cardHeader.connectivityStatus")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {online >= 99.9
                            ? this.props.t("cardHeader.noIssues")
                            : "Online: " + online.toFixed(1) + " %  "}
                          {percentageDelta === -1 ? (
                            <span className="text-danger mr-2">
                              <i className="fa fa-arrow-down" />
                            </span>
                          ) : null}
                          {percentageDelta === 1 ? (
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up" />
                            </span>
                          ) : null}
                        </span>
                        <br />
                        <span className="h5 font-weight-bold mb-0">
                          {this.props.t("cardHeader.lastUpdate")}
                          {" " +
                            utils.formatDay(this.lastDate) +
                            " " +
                            new Date(this.lastDate).toLocaleTimeString()}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i className="fas fa-chart-bar" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {this.props.t("cardHeader.yourInstallations")}
                        </CardTitle>

                        <span className="h2 font-weight-bold mb-0">
                          {sitesInfo} - {gatewaysInfo}
                        </span>
                        <br />
                        <span className="h5 font-weight-bold mb-0">
                          {devicesInfo}
                          {totalUnknownDevices > 0 &&
                            ` (${unknownDevicesInfo})`}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i className="fas fa-chart-pie" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {this.props.t("cardHeader.activeAlarms")}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {total === -1 ? (
                            <Loader
                              custom
                              className="position-absolute"
                              size={54}
                            />
                          ) : (
                            total || this.props.t("cardHeader.allGood")
                          )}
                        </span>
                      </div>
                      <Col className="col-auto">
                        {total === 0 ? (
                          <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                            {key.ICON_DASHBOARD_ALARM}
                          </div>
                        ) : (
                          <div className="icon icon-shape bg-red text-white rounded-circle shadow">
                            {key.ICON_DASHBOARD_ALARM}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {total > 0 ? (
                          this.renderlogAlarmButton()
                        ) : (
                          <h5
                            className={`m-0 p-0 mt-1 text-green font-weight-bold ${
                              total !== 0 ? "invisible" : ""
                            }`}
                          >
                            {key.ICON_SMILE}
                          </h5>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, null)(CardHeader)
);
