import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "components/Loader";

import PermissionCards from "components/PermissionCards";

class Permissions extends Component {

  render() {
    const { isLoading } = this.props;
    if (isLoading) return <Loader />
    return <PermissionCards />;
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.permissions.isLoading
})

export default connect(mapStateToProps)(Permissions)