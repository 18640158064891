import React, { useEffect, useState } from "react";
import { Modal, ModalBody} from "reactstrap";
import "./banner.css";

const Banner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const bannerDismissed = sessionStorage.getItem("bannerDismissed");

        if (!bannerDismissed) {
            const domain = window.location.hostname;
            if (domain.includes("emersonconnected")) {
                setShowBanner(true);
            }
        }
    }, []);

    const dismissBanner = () => {
        setShowBanner(false);
        sessionStorage.setItem("bannerDismissed", "true");
    };

    return (
            <Modal  isOpen={showBanner} toggle={dismissBanner} className="modal-banner">
                <ModalBody  className="modal-banner-body">
                    Please transition to using the domain <strong>copelandconnected.com</strong>. The domain <strong>emersonconnected.com</strong> will be decommissioned after <strong>September
                    29th, 2024</strong>. Make sure to update your bookmarks and links. Thank you!
                </ModalBody>
            </Modal>
    );
};

export default Banner;
