import React, { Component } from "react";
import { connect } from "react-redux";
import utils from "../../utils";
import api, { sources } from "../../services/backendService";
import ChartProperties from "../Forms/ChartProperties";
import Loader from "../../components/Loader";
import "./style.css";
import createPlotlyComponent from "react-plotly.js/factory";
import { createSelector } from "reselect";

const lineType = "scatter";
const lineMode = "lines";

const LINE_OFFSET = 3;

const chartDomain = {
  yAxisMin: 0.55,
  yAxisMax: 1,
  yAxis2Min: 0.3,
  yAxis2Max: 0.5,
};

const traceColor = [
  "#1f77b4", // muted blue
  "#ff7f0e", // safety orange
  "#2ca02c", // cooked asparagus green
  "#d62728", // brick red
  "#9467bd", // muted purple
  "#8c564b", // chestnut brown
  "#e377c2", // raspberry yogurt pink
  "#7f7f7f", // middle gray
  "#bcbd22", // curry yellow-green
  "#17becf", // blue-teal
];

class Plotly extends Component {
  constructor(props) {
    super(props);

    let dateFrom = new Date();
    let dateTo = new Date();

    dateFrom.setDate(dateFrom.getDate() - 1);

    this.state = {
      realtime: false,
      dateFrom: dateFrom,
      dateTo: dateTo,
      isLoading: true,
      noData: false,
      updateChartPeriod: false,
      showTip: false,
      loadingPlotly: true,
    };

    this.onChartList = {};
    this.lines = [];
    this.layout = {};
    this.lastUpdate = new Date().toLocaleString();
    this.dataTypeList = { bit: "", integer: {} };

    this.subplots = {};

    this.skipUpdate = false;

    this.id = null;

    this.initVariables();

    import("plotly.js-basic-dist-min").then((PlotlyMin) => {
      this.Plot = createPlotlyComponent(PlotlyMin.default);
      this.setState({ loadingPlotly: false });
    });
  }

  componentWillUnmount() {
    if (sources[this.id]) sources[this.id].source.cancel();
  }

  getMeasuresTags = async () => {
    let deviceInfo = (await api.getDevice(this.props.deviceGuid)).data;

    let measuresTags = {};

    for (let point of deviceInfo.points) {
      if (point.tags !== undefined && point.tags.haccp !== undefined) {
        measuresTags = {
          ...measuresTags,
          [point.tags.haccp]: { code: point.code, vtype: point.vtype },
        };
      }
    }

    return measuresTags;
  };

  setYAxis = (vtype) => {
    return this.dataTypeList.integer[vtype];
  };

  initVariables = () => {
    this.lines = [];
    let pointsCodes = Object.getOwnPropertyNames(
      this.props.controller.pointsById
    );

    let points = [];

    let typeCounter = 0;
    for (let p of pointsCodes) {
      const point = this.props.controller.pointsById[p];

      points.push(point);

      if (point.type === "bit") this.dataTypeList.bit = "y2";
      else {
        if (!(point.vtype in this.dataTypeList.integer)) {
          let axisValue =
            point.vtype === "temperature" ? "y" : "y" + typeCounter;
          this.dataTypeList.integer = {
            ...this.dataTypeList.integer,
            [point.vtype]: axisValue,
            [axisValue]: point.vtype,
          };
          if (typeCounter === 0) typeCounter = 3;
          else typeCounter++;
        }
      }
    }

    try {
      if (Object.keys(this.onChartList).length === 0) {
        for (let point of points) {
          this.onChartList = {
            ...this.onChartList,
            [utils.getLangText(point.code)]:
              point.tags !== undefined
                ? point.tags.chart
                  ? true
                  : false
                : false,
          };
        }
      }
    } catch (e) {
      utils.debug("No data available", utils.MSG_TYPE_LOG);
    }

    for (let point of points) {
      if (point.type === "integer") {
        let line = {
          x: [],
          y: [],
          hoverinfo: "none",
          xaxis: "x",
          yaxis: this.setYAxis(point.vtype),
          name: utils.getLangText(point.code),
          type: lineType,
          mode: lineMode,
          line: { shape: "linear" },
          visible:
            this.onChartList[utils.getLangText(point.code)] !== undefined
              ? this.onChartList[utils.getLangText(point.code)]
                ? true
                : "legendonly"
              : "legendonly",
        };

        // Check for new subplots
        let axis = "x" + line.yaxis;
        if (this.subplots[axis] === undefined) {
          this.subplots = {
            ...this.subplots,
            [axis]: true,
          };
        }

        this.lines.push(line);
      } else if (point.type === "bit") {
        let line = {
          x: [],
          y: [],
          hoverinfo: "none",
          xaxis: "x",
          yaxis: "y2",
          name: utils.getLangText(point.code),
          type: lineType,
          mode: lineMode,
          line: { shape: "hvh" },
          visible:
            this.onChartList[utils.getLangText(point.code)] !== undefined
              ? this.onChartList[utils.getLangText(point.code)]
                ? true
                : "legendonly"
              : "legendonly",
        };

        this.lines.push(line);

        // Check for new subplots
        let axis = "x" + line.yaxis;
        if (this.subplots[axis] === undefined) {
          this.subplots = {
            ...this.subplots,
            [axis]: true,
          };
        }
      }
    }

    let layoutSubplots = [];
    for (let subplot of Object.keys(this.subplots)) {
      layoutSubplots.push([subplot]);
    }

    this.layout = {
      datarevision: 0,
      autosize: true,
      //xaxis: this field is set in render
      grid: {
        rows: 2,
        columns: 1,
        subplots: layoutSubplots,
      },
      margin: {
        l: 50,
        r: 35,
        t: 30,
        b: 0,
        pad: 0,
      },
      showlegend: false,
      shapes: [],
      annotations: [],
    };

    for (let subplotCode of Object.keys(this.subplots)) {
      this.layout["yaxis" + subplotCode.split("xy")[1]] =
        this.getSubplots(subplotCode);
    }
  };

  getXDomain = (nSubplots) => {
    switch (nSubplots) {
      case 2:
        return [0.04, 1];
      case 3:
        return [0.08, 1];
      case 0:
      case 1:
      default:
        return [0, 1];
    }
  };

  getSubplots = (subplotCode) => {
    let digitalValues = [];
    let digitalLabels = [];
    for (let i = 0; i < 100; i++) {
      digitalValues.push(i);
      if (i % LINE_OFFSET === 0) digitalLabels.push("OFF");
      else if (i % LINE_OFFSET === 1) digitalLabels.push("ON         ");
      else digitalLabels.push("");
    }

    let domain = [];

    switch (subplotCode) {
      case "xy":
        domain.push(chartDomain.yAxisMin);
        domain.push(chartDomain.yAxisMax);
        return {
          domain: domain,
          ticksuffix: utils.getUnitByType(0, this.dataTypeList.integer.y),
        };
      case "xy2":
        domain.push(chartDomain.yAxis2Min);
        domain.push(chartDomain.yAxis2Max);
        return {
          tickmode: "array",
          tickvals: digitalValues,
          ticktext: digitalLabels,
          domain: domain,
          type: "category",
          categoryorder: "array",
          autorange: false,
          // Add categoryarray to yaxis2 with ordered values traces
        };
      default:
        let yAxis = subplotCode.split("x")[1];

        domain.push(chartDomain.yAxisMin);
        domain.push(chartDomain.yAxisMax);

        let subPlotInfo = {
          domain: domain,
          ticksuffix: utils.getUnitByType(0, this.dataTypeList.integer[yAxis]),
          overlaying: "y",
          showgrid: false,
          anchor: "free",
          side: "left",
          //position: this field is set in render
          linecolor: "#EEEEEE",
          linewidth: 1,
        };

        if (this.dataTypeList.integer[yAxis] === "percentage")
          subPlotInfo.range = [0, 100];

        return subPlotInfo;
    }
  };

  componentDidMount() {
    if (this.state.realtime) this.setState({ isLoading: false });
    else this.getDeviceMeasures();

    this.checkVariableTypeNumber();

    this.checkEnabledLinesForAnnotations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.updateChartPeriod === false &&
      this.state.updateChartPeriod === true
    ) {
      this.getDeviceMeasures();
      this.layout.datarevision += 1;
      this.setState({ updateChartPeriod: false });
    }

    if (this.state.realtime && !this.skipUpdate) {
      let pointsCodes = Object.getOwnPropertyNames(
        this.props.controller.pointsById
      );

      let points = [];

      for (let p of pointsCodes) {
        const point = this.props.controller.pointsById[p];

        points.push(point);
      }

      let lineFactor = 0;
      for (let point of points) {
        for (let i = 0; i < this.lines.length; i++) {
          if (utils.getLangText(point.code) === this.lines[i].name) {
            if (
              point.value !== undefined &&
              utils.pointValue(point.value, point.vtype) !== undefined
            ) {
              if (point.type === "bit") {
                let digitalValue = utils.pointValue(point.value, point.type);
                if (digitalValue !== "NA" && digitalValue !== "ERROR") {
                  this.lines[i].y.push(point.value + lineFactor * LINE_OFFSET);
                }
              } else {
                let analogValue = utils.pointValue(
                  point.value,
                  point.type,
                  point.vtype
                );
                if (
                  analogValue !== "NA" &&
                  analogValue !== "ERROR" &&
                  analogValue !== "NO PROBE"
                )
                  this.lines[i].y.push(analogValue);
              }

              this.lines[i].x.push(new Date());
            }
          }
        }
        if (point.type === "bit") lineFactor++;
      }

      this.layout.datarevision += 1;

      this.lastUpdate = new Date().toLocaleString();
    }
    this.skipUpdate = false;
  }

  getDeviceMeasures = async () => {
    this.setState({ isLoading: true });
    let response = await api.getAggregatedMeasuresByDate(
      [this.props.deviceGuid],
      utils.formatDate(this.state.dateFrom),
      utils.formatDate(this.state.dateTo)
    );

    this.id = response.id;

    response.execute
      .then((data) => {
        let measures = data.data[0].aggregatedMeasures;

        this.lines = [];

        if (measures.length === 0) {
          this.setState({ isLoading: false, noData: true });
          return;
        }
        this.setState({ noData: false });

        let lines = [];

        let temp = {};
        for (let measure of measures) {
          let line = Object.getOwnPropertyNames(measure);
          for (let i = 0; i < line.length; i++) {
            if (temp[line[i]] === undefined) {
              temp = {
                ...temp,
                [line[i]]: 1,
              };
            }
          }
        }

        lines = Object.getOwnPropertyNames(temp);

        lines = lines.filter(
          (line) => line !== "dateFrom" && line !== "dateTo"
        );

        let lineFactor = 0;

        for (let line of lines) {
          const point = this.props.controller.pointsById[line];

          try {
            let newLine = {
              x: [],
              y: [],
              xaxis: "x",
              type: lineType,
              mode: lineMode,
              hoverinfo: "none",
            };
            newLine["name"] = utils.getLangText(point.code);
            if (point.type === "integer") {
              newLine["line"] = { shape: "linear" };

              newLine["yaxis"] = this.setYAxis(point.vtype);
            } else if (point.type === "bit") {
              newLine["line"] = { shape: "hvh" };
              newLine["yaxis"] = "y2";
            }

            newLine["visible"] = this.onChartList[utils.getLangText(line)]
              ? true
              : "legendonly";
            for (let measure of measures) {
              newLine.x.push(new Date(measure.dateFrom + "Z"));
              if (point.type === "bit") {
                let digitalValue = utils.pointValue(measure[line], point.type);
                if (digitalValue !== "NA" && digitalValue !== "ERROR") {
                  newLine.y.push(measure[line] + lineFactor * LINE_OFFSET);
                }
              } else {
                let analogValue = utils.pointValue(
                  measure[line],
                  point.type,
                  point.vtype
                );
                if (
                  analogValue !== "NA" &&
                  analogValue !== "ERROR" &&
                  analogValue !== "NO PROBE"
                ) {
                  newLine.y.push(parseFloat(analogValue));
                }
              }
            }

            if (point.type === "bit") lineFactor++;

            this.lines = [...this.lines, newLine];
          } catch (err) {
            utils.debug(err + " - " + line, utils.MSG_TYPE_WARN);
          }
        }

        this.setCategoryAndRange(this.lines);

        this.layout.datarevision += 1;

        this.forceUpdate();
        this.setState({ isLoading: false });
      })
      .catch(() => {});
  };

  setCategoryAndRange = (lines) => {
    let categoryArray = [];
    let categoryArrayON = [];
    let categoryArrayOFF = [];

    let lineFactor = 0;
    let nLines = 0;

    for (let line of lines) {
      if (line.yaxis === "y2") {
        if (line.visible === true) {
          categoryArrayON.push(lineFactor * LINE_OFFSET);
          categoryArrayON.push(lineFactor * LINE_OFFSET + 1);
          nLines++;
        } else {
          categoryArrayOFF.push(lineFactor * LINE_OFFSET);
          categoryArrayOFF.push(lineFactor * LINE_OFFSET + 1);
        }
        lineFactor++;
      }
    }

    // Reset yaxis range
    this.layout.yaxis2.range = [-0.5, nLines * 2 - 0.5];

    categoryArray = categoryArrayON.concat(categoryArrayOFF);
    this.layout.yaxis2.categoryarray = categoryArray;

    this.layout.datarevision += 1;
  };

  setRealTime = (status) => {
    if (this.state.realtime !== status) {
      this.layout.datarevision = 0;
      this.setState({ realtime: status });
      this.initVariables();

      for (let line of this.lines) {
        line.x = [];
        line.y = [];
      }

      this.setCategoryAndRange(this.lines);
    }
  };

  onClick = (click) => {
    let { annotations } = this.layout;
    for (var i = 0; i < annotations.length; ++i) {
      if (annotations[i].type === "user") {
        annotations.splice(i, 1);
        i--;
      }
    }

    click.points.forEach((item, i) => {
      let y = item.y;

      if (item.data.yaxis === "y2") {
        if (item.data.value === "OFF") y = 0 + i * 2;
        else y = 1 + i * 2;
      }

      annotations.push({
        type: "user",
        deviceID: item.data.deviceID,
        pointID: item.data.pointID,
        x: item.x,
        y: y,
        text: item.data.name + ": " + item.data.value,
        xref: "x",
        yref: item.data.yaxis,
        showarrow: true,
        arrowcolor: "#000000",
        arrowhead: 9,
        arrowwidth: 1,
        ax: 0,
        ay: -30,
        bgcolor: "#E0E0E0",
        bordercolor: "#404040",
        borderwidth: 1,
        borderpad: 1,
        font: {
          color: "#000000",
          size: 14,
        },
      });
    });

    this.layout.annotations = annotations;
    this.layout.datarevision += 1;
    this.forceUpdate();
  };

  onHover = (hover) => {
    const { pointNumber } = hover.points[0];

    let cursor = {
      xid: 1,
      type: "line",
      xref: "x",
      yref: "paper",
      x0: hover.points[0].x,
      y0: chartDomain.yAxis2Min,
      x1: hover.points[0].x,
      y1: chartDomain.yAxisMax,
      fillcolor: "#454545",
      opacity: 0.4,
      line: {
        width: 1,
      },
    };

    for (let i = 0; i < this.layout.shapes.length; i++) {
      if (this.layout.shapes[i].xid === 1) {
        this.layout.shapes.splice(i, 1);
        break;
      }
    }

    this.layout.shapes.push(cursor);

    for (let line of this.lines) {
      if (line.y[pointNumber] !== undefined) {
        line.value =
          line.yaxis === "y2"
            ? line.y[pointNumber] % LINE_OFFSET === 0
              ? "OFF"
              : "ON"
            : utils.pointValue(line.y[pointNumber], "integer") +
              utils.getUnitByType(
                line.y[pointNumber],
                this.dataTypeList.integer[line.yaxis]
              );
      } else line.value = undefined;

      line.date = new Date(
        hover.points[0].x.replace(/-/g, "/")
      ).toLocaleString();
    }

    this.layout.datarevision += 1;
    this.forceUpdate();
    this.skipUpdate = true;
  };

  checkVariableTypeNumber = () => {
    let activeLines = this.lines.filter(
      (item) => item.visible === true && item.yaxis !== "y2"
    );

    let activeSubplots = { y: true }; // temperature always visible

    for (let line of activeLines) {
      activeSubplots = {
        ...activeSubplots,
        [line.yaxis]: true,
      };
    }

    this.setState({ showTip: Object.keys(activeSubplots).length >= 3 });
  };

  checkEnabledLinesForAnnotations = () => {
    const removeAnnotation = (text) => {
      for (let i = 0; i < this.layout.annotations.length; i++) {
        if (this.layout.annotations[i].text === text) {
          this.layout.annotations.splice(i, 1);
          break;
        }
      }
    };

    // Check if there's no selected variables
    if (this.lines.length > 0) {
      if (
        this.lines.filter(
          (item) => item.yaxis !== "y2" && item.visible === true
        ).length === 0
      ) {
        this.layout.annotations.push({
          text: this.props.t("chart.noAnalogVariables"),
          showarrow: false,
          xref: "paper",
          yref: "y",
          yanchor: "top",
          font: { size: 16 },
        });
      } else {
        removeAnnotation(this.props.t("chart.noAnalogVariables"));
      }

      if (
        this.lines.filter(
          (item) => item.yaxis === "y2" && item.visible === true
        ).length === 0
      ) {
        this.layout.annotations.push({
          text: this.props.t("chart.noDigitalVariables"),
          showarrow: false,
          xref: "paper",
          yref: "paper",
          font: { size: 16 },
          y: "0.36",
        });
      } else {
        removeAnnotation(this.props.t("chart.noDigitalVariables"));
      }
    }
  };

  toggleLine = (i) => {
    this.layout.annotations = [];
    this.onChartList[this.lines[i].name] =
      !this.onChartList[this.lines[i].name];
    this.lines[i].visible =
      this.lines[i].visible === true ? "legendonly" : true;
    this.setCategoryAndRange(this.lines);
    this.forceUpdate();

    this.checkVariableTypeNumber();

    this.checkEnabledLinesForAnnotations();
  };

  onPeriodChange = () => {
    this.setState({ updateChartPeriod: true });
  };

  render() {
    if (this.state.loadingPlotly) return null;
    let legendLines = [];
    let activeSubplots = {};
    let chartLines = [];

    let i = 0;
    for (let line of this.lines) {
      legendLines.push({
        name: line.name,
        visible: line.visible,
        value: line.value,
        date: line.date,
        type: line.yaxis === "y2" ? "bit" : "integer",
        index: i,
      });

      let cLine = Object.assign({}, line);

      if (cLine.visible !== true && cLine.yaxis !== "y" && cLine.yaxis !== "y2")
        cLine.yaxis = "y";

      chartLines.push(cLine);

      if (
        (line.visible === true || line.yaxis === "y") &&
        line.yaxis !== "y2"
      ) {
        let axisName = "yaxis" + (line.yaxis.split("")[1] || "");
        activeSubplots = {
          ...activeSubplots,
          [axisName]: this.layout[axisName],
        };
      }

      i++;
    }

    this.layout.xaxis = {
      domain: this.getXDomain(Object.keys(activeSubplots).length),
    };

    i = 0;
    for (let axes of Object.keys(activeSubplots)) {
      if (axes !== "yaxis") {
        this.layout[axes].position = 0.04 * i;
        i++;
      }
    }

    let activeLicense = this.props.gatewayInfo.licenses.filter(
      (l) => l.isActive === true
    );

    let realtimeEnabled = false;
    if (activeLicense.length > 0) {
      realtimeEnabled =
        JSON.parse(activeLicense[0].content).data.realtimeCharting.frontend ===
        1;
    }

    return (
      <div className="chart-container">
        <ChartProperties
          realtime={this.state.realtime}
          dateFrom={this.state.dateFrom}
          dateTo={this.state.dateTo}
          setDateFrom={(date) => this.setState({ dateFrom: date })}
          setDateTo={(date) => this.setState({ dateTo: date })}
          setRealTime={this.setRealTime}
          getDeviceMeasures={this.getDeviceMeasures}
          onPeriodChange={this.onPeriodChange}
          realtimeEnabled={realtimeEnabled}
        />
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={`last-update${this.state.realtime ? "" : "-none"}`}>
              {this.props.t("chart.lastUpdate") + ": " + this.lastUpdate}
            </div>
            <this.Plot
              divId="plot"
              onHover={(hover) => {
                this.onHover(hover);
              }}
              onClick={(click) => this.onClick(click)}
              className="plot"
              graphDiv="graph"
              revision={this.layout.datarevision}
              data={chartLines}
              layout={this.layout}
              config={{ displayModeBar: false }}
              useResizeHandler={true}
            />
            <PlotlyLegend
              values={legendLines}
              toggle={this.toggleLine}
              showTip={this.state.showTip}
              t={this.props.t}
            />
          </>
        )}
      </div>
    );
  }
}

class PlotlyLegend extends Component {
  renderVarList = (item) => {
    const className = `legend-item${
      item.visible === true ? "" : "-not-visible"
    }`;
    if (item.visible !== false)
      return (
        <div
          key={item.name}
          className={className}
          onClick={() => this.props.toggle(item.index)}
        >
          <svg width="10" height="15" style={{ margin: "10px" }}>
            <rect
              className=""
              width="10"
              height="15"
              style={{
                fill:
                  traceColor[item.index % 10] +
                  (item.visible === "legendonly" ? "7F" : ""),
                strokeWidth: 3,
              }}
            />
          </svg>
          {item.name}
          <span className="legend-value">
            {item.value !== undefined ? ": " + item.value : ""}
          </span>
        </div>
      );
    else return null;
  };

  render() {
    if (this.props.values.length === 0)
      return (
        <div className="text-uppercase">
          {this.props.t("chart.noDataAvailable")}
        </div>
      );
    const date = this.props.values[0].date;

    return (
      <div className="legend-container">
        <div className="legend-info">
          <div className="legend-date float-left">
            {`${
              date === undefined
                ? ""
                : this.props.t("chart.sampleDateTime") + ": " + date
            }`}
          </div>
          <div className="legend-tip float-right badge badge-warning">
            {!this.props.showTip
              ? null
              : this.props.t("chart.addingTooManyVariables")}
          </div>
        </div>
        <div className="legend">
          {this.props.values.map((item) => {
            return this.renderVarList(item);
          })}
        </div>
      </div>
    );
  }
}

const getControllers = createSelector(
  (state, deviceGuid) =>
    state.controllers.byId[deviceGuid ?? state.wna.usedProps.site.deviceGuid],
  (controllers) => {
    return controllers;
  }
);

const mapStateToProps = (state, ownProps) => {
  return {
    controller: getControllers(state, ownProps.deviceGuid),
  };
};

export default connect(mapStateToProps, null)(Plotly);
