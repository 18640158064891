import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from "reactstrap";
import "./listDropdown.css";

const ListDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  let { listItems } = props;

  for (let i = 0; i < listItems.length; i++) {
    listItems[i].key = i;
  }

  const className = `${props.className !== undefined ? props.className : ""}`;

  const toggleClassName = `${
    props.toggleClass !== undefined ? props.toggleClass : ""
  }`;
  const menuClassName = `${
    props.toggleMenu !== undefined ? props.toggleMenu : ""
  }`;

  return (
    <Dropdown
      className={className}
      style={props.style}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle caret className={toggleClassName} color={props.color}>
        {props.title}
      </DropdownToggle>
      <DropdownMenu className={menuClassName}>
        {listItems.map((item) => {
          return item.hidden ? null : !item.header && !item.divider ? (
            <DropdownItem
              key={item.key}
              onClick={item.command}
              toggle={!item.checkbox}
              disabled={item.disabled}
            >
              {item.checkbox === true ? (
                <Input
                  type="checkbox"
                  className="ml-1"
                  checked={item.checkboxStatus}
                  onChange={() => {}}
                />
              ) : null}
              <span className={item.checkbox === true ? "ml-4" : ""}>
                {item.text}
              </span>
            </DropdownItem>
          ) : item.divider ? (
            <DropdownItem divider key={item.key} />
          ) : (
            <div key={item.key}>
              {/* <DropdownItem divider /> */}
              <DropdownItem
                className="dropdown-header"
                onClick={item.command}
                toggle={!item.checkbox}
                disabled={item.disabled}
              >
                {item.checkbox === true ? (
                  <Input
                    type="checkbox"
                    className="ml-1"
                    checked={item.checkboxStatus}
                    onChange={() => {}}
                  />
                ) : null}{" "}
                <span className={item.checkbox === true ? "ml-4" : ""}>
                  {item.text.toUpperCase()}
                </span>
              </DropdownItem>
              {/* <DropdownItem divider /> */}
            </div>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ListDropdown;
