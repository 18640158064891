import React from "react";
import { Collapse, Table, Button } from "reactstrap";
import XVOButtonGroup from "components/admin/XVOButtonGroup";
import keys from "../../configs/constants";
import api from "../../services/backendService";
import { withTranslation } from "react-i18next";

class AdminCustomerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  onPermLevelChange(level) {
    this.props.onPermLevelChange(level);
  }

  onUpdateCustomerPerm() {
    this.props.onUpdatePerm();
  }

  onForgetChanges() {
    this.props.onForgetChanges();
  }

  renderRows() {
    const t = this;
    if (this.props.userData.length > 0) {
      return this.props.userData.map((c, idx) => {
        const customer = c.customer;
        const cGuid = customer.customerGuid;
        return (
          <tr key={idx}>
            <td>
              <XVOButtonGroup
                level={
                  t.props.levels[cGuid]
                    ? t.props.levels[cGuid]
                    : c.user !== undefined
                    ? c.level
                    : "None"
                }
                guid={cGuid}
                onPermLevelChange={this.onPermLevelChange.bind(this)}
              />
            </td>
            <td>{customer.name}</td>
            <td>
              {customer.sites.length}
              <span
                className={`${
                  c.user !== undefined ? "icon-delete" : "icon-delete-disabled"
                } float-right`}
                onClick={() => {
                  if (c.user !== undefined) {
                    api
                      .deletePermissionCustomer(cGuid, c.user.userGuid)
                      .then(() => {
                        this.props.onUpdatePerm();
                      });
                  }
                }}
              >
                {keys.ICON_TRASH}
              </span>
            </td>
          </tr>
        );
      });
    } else return;
  }

  toggle() {
    const newOpenState = !this.state.isOpen;
    this.setState({ isOpen: newOpenState });
  }

  render() {
    return (
      <div className="border" color="primary">
        <Button
          color="primary"
          onClick={this.toggle.bind(this)}
          style={{ marginBottom: "1rem" }}
          className="ml-2 mt-3"
        >
          {(this.state.isOpen
            ? this.props.t("adminCustomerTable.collapse")
            : this.props.t("adminCustomerTable.expand")) +
            " (" +
            this.props.userData.length +
            ")"}
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: "1rem" }}
          className="ml-2 mt-3"
          onClick={this.onUpdateCustomerPerm.bind(this)}
          disabled={Object.keys(this.props.levels).length === 0}
        >
          {this.props.t("adminCustomerTable.updateCustomerPermission")}
        </Button>
        <Button
          color="primary"
          style={{ marginBottom: "1rem" }}
          className="ml-2 mt-3"
          onClick={this.onForgetChanges.bind(this)}
          disabled={Object.keys(this.props.levels).length === 0}
        >
          {this.props.t("adminCustomerTable.forgetChanges")}
        </Button>

        <Collapse isOpen={this.state.isOpen}>
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">
                  {this.props.t("adminCustomerTable.permission")}
                </th>
                <th scope="col">{this.props.t("adminCustomerTable.name")}</th>
                <th scope="col">
                  {this.props.t("adminCustomerTable.sitesNumber")}
                </th>
              </tr>
            </thead>
            <tbody>{this.renderRows()}</tbody>
          </Table>
        </Collapse>
      </div>
    );
  }
}

export default withTranslation("common")(AdminCustomerTable);
